import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { GrAttachment, GrSend } from "react-icons/gr";
import { useSelector } from "react-redux";

import { readFileAsync } from "../../../utils";
import { timeSince } from "../../../utils";

import DotsLoadingGif from "../../../assets/dots-loading.gif";

import { ChatContext } from "../../../chat";
import api from "../../../services/api";
import styleMention from "./style-mention";

export default ({ ticket }) => {
  const [text, setText] = useState();
  const [messages, setMessages] = useState([]);
  const chatView = useRef(null);
  const user = useSelector((state) => state.Auth.user);
  const inputRef = useRef(null);
  const [files, setFiles] = useState([]); // files to upload
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState(false);

  const chat = useContext(ChatContext);
  const { setNotification } = useContext(ChatContext);

  useEffect(() => {
    setNotification(false);
    return () => {
      setNotification(true);
    };
  }, []);

  useEffect(() => {
    setMessages(ticket.messages || []);

    const id = chat.onNewMessage(async (value) => {
      if (value.ticket._id !== ticket._id) return; // dont receve message from other tickets
      setMessages((m) => [...m, value.message]);
      setNewMessage(false);
      await api.put(`/ticket/read/${ticket._id}`);
    });

    // asyncrone
    api.put(`/ticket/read/${ticket._id}`);

    return () => {
      chat.clearNewMessage(id);
    };
  }, [ticket._id]);

  const onSend = async (tmp = "") => {
    const str = tmp || text;
    if (!str) return;

    // if the message contains alice or gpt
    if (str.includes("/alice") || str.includes("/gpt")) {
      setNewMessage(true);
    }

    const message = { text: str, sent_at: new Date(), user_name: user.name, user_id: user._id, user_avatar: user.avatar };
    setMessages([...messages, message]);
    setText("");

    // handle file upload if any
    if (files.length) {
      const { data } = await api.post(`/file/multiple`, { files, folder: `/ticket/${ticket._id}` });
      if (!data) return toast.error("Something went wrong!");

      const urls = data.map((f) => {
        console.log("f", f);
        return { url: f, name: f.split("/").pop() };
      });
      message.files = urls;
      setFiles([]);
    }

    const { data, ok } = await api.post(`/ticket/message/${ticket._id}`, message);
    if (!ok) return toast.error("Something went wrong!");
  };

  useEffect(() => {
    chatView.current.scroll({ top: chatView.current.scrollHeight });
  }, [messages]);

  return (
    <div className="w-full" ref={chatView}>
      <div className="overflow-auto bg-gray-50 h-[calc(100vh-224px)] p-4">
        {messages.map((m) => {
          const itsMe = user._id === m.user_id;
          return (
            <div key={m.sent_at} className={`flex items-start space-x-3 ${itsMe ? "flex-row-reverse" : "justify-start"}`}>
              <img className={`object-cover h-9 w-9 min-w-[36px] min-h-[36px] rounded-full mt-1 ${itsMe && "ml-3"}`} src={m.user_avatar} />
              <div>
                <div className={`${itsMe ? "bg-primary text-white" : "bg-white text-black"} rounded-lg py-3 px-4 text-sm shadow-sm inline-block`}>
                  <div className="chat-message">
                    <Message message={m} />
                    <Attachments files={m.files} />
                    <Actions actions={m.actions} onClick={(action) => onSend(action)} />
                  </div>
                </div>
                <div className={`text-[10px] text-gray-400 mt-1 ${itsMe && "text-right"}`} title={m.sent_at}>
                  {!itsMe ? `${m.user_name}, ` : ""}
                  {timeSince(m.sent_at)}
                </div>
              </div>
            </div>
          );
        })}
        {newMessage && <Message message={"new"} />}
      </div>

      <form className="bg-white rounded-md border-[1px] border-gray-200 flex items-center h-[84px] w-full">
        <select
          value=""
          defaultValue=""
          className="border-none w-16 bg-transparent text-gray-500 text-sm font-medium"
          onChange={(e) => {
            e.persist();
            setText((t) => `${t || ""}${e.target.value}`);
            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }}>
          <option disabled value="">
            /
          </option>
          <option value="/alice ">/alice | Turn on assistant</option>
          <option value="/remind ">/remind | Add information permanently to assistant context</option>
          <option value="/imagine ">/imagine | Get an image midjourney style</option>
          <option value="/gpt ">/gpt | Use gpt 4</option>
          <option value="/raph ">/raph (NOT WORKING YET) | ask code qustion about the White Papper</option>
        </select>

        <div className="h-8 border-r border-gray-300 mx-4" />

        <textarea
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault(); // Prevents the addition of a new line
              onSend();
            }
          }}
          ref={inputRef}
          value={text}
          placeholder="Write your message..."
          onChange={(e) => setText(e.target.value)}
          style={styleMention}></textarea>

        <div className="h-8 border-r border-gray-300 mx-4" />
        {files.map((f) => (
          <div className="flex items-center space-x-2 underline" target="_blank">
            <GrAttachment className="text-sm" /> <span className={"text-xs"}>{f.name}</span>
          </div>
        ))}

        <label className="flex items-center space-x-2 cursor-pointer">
          <GrAttachment className="text-lg text-light-grey" />
          <input
            type="file"
            className="hidden"
            multiple
            onChange={async (e) => {
              setLoading(true);

              const files = [];

              const f = e.target.files;
              for (let i = 0; i < f.length; i++) {
                const file = f[i];
                const rawBody = await readFileAsync(file);
                files.push({ rawBody, name: file.name });

                setLoading(false);
                setFiles(files);
              }
            }}
          />
        </label>
        <button
          onClick={(e) => {
            e.preventDefault();
            onSend();
          }}
          className="p-4 cursor-pointer">
          <GrSend className="text-lg text-light-grey" />
        </button>
      </form>
    </div>
  );
};

const Actions = ({ actions, onClick }) => {
  if (!(actions || []).length) return <div />;
  return (
    <>
      <div className="font-bold mb-2 mt-6">Actions:</div>
      <div className="space-y-2">
        {actions.map((a) => {
          return (
            <button
              className="flex items-center space-x-2 underline"
              target="_blank"
              onClick={(e) => {
                onClick(a.value);
              }}>
              <span>{a.name}</span>
            </button>
          );
        })}
      </div>
    </>
  );
};

const Attachments = ({ files }) => {
  if (!(files || []).length) return <div />;

  function render(file) {
    console.log("file", file);
    if (file.url?.includes(".png") || file.url?.includes(".jpg") || file.url?.includes(".jpeg") || file.url?.includes(".gif")) {
      return <img src={file.url} className="object-contain w-3/6	" />;
    }
    return (
      <a href={file.url} className="flex items-center space-x-2 underline text-white" target="_blank">
        <GrAttachment className="text-lg" />
        <span className="text-black underline">{file.name}</span>
      </a>
    );
  }

  return (
    <>
      <div className="space-y-2">{files.map((f) => render(f))}</div>
    </>
  );
};

const Message = ({ message }) => {
  if (message === "new")
    return (
      <div className={`flex items-start space-x-3 justify-start`}>
        <div className={`rounded-lg p-4 text-sm w-max inline-block`}>
          <img src={DotsLoadingGif} className="h-10" />
        </div>
      </div>
    );
  if (message.html) {
    let html = message.html;
    if (message?.files?.length) html = message.html.replace(/cid:[^"]+/g, message?.files[0]?.url || "");
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
  let text = message.text || "";
  if ((message.tagged || []).length) {
    message.tagged.forEach((tag) => {
      text = text.replace(`@${tag.name}`, `<span className="text-primary underline bg-gray-300"> @${tag.name} </span>`);
    });
  }

  //if urls in the message, display it though a href
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" className="text-primary underline bg-gray-300">${url}</a>`;
  });

  return (text || "").split("\n").map((m) => <div dangerouslySetInnerHTML={{ __html: m }} />);
};
