import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import TablePagination from "../../../../components/TablePagination";
import api from "../../../../services/api";

export default ({ project, filters }) => {
  const [banks, setBanks] = useState([]);

  const HEADER_BANKS = [
    { title: "Name", position: "left", colSpan: 4 },
    { title: "Amount", position: "right", colSpan: 2 },
    { title: "%", position: "right", colSpan: 2 },
  ];
  useEffect(() => {
    getBanks();
  }, [filters]);

  const getBanks = async () => {
    try {
      const { ok, data } = await api.post(`/bank/groupBy`, {
        projectId: project._id,
        groupBy: "name",
        category: "GENERAL_COST",
        date: filters.date,
        budgetId: filters?.budget?._id,
      });
      if (!ok) return toast.error("Error while fetching banks");
      setBanks(data);
    } catch (error) {
      toast.error("Error while fetching banks");
    }
  };

  if (!banks.length)
    return (
      <div className="">
        <h2 className="text-lg font-semibold mb-3">Expenses</h2>
        <p className="text-sm text-gray-500 mb-3">These are the expenses on hosting / services used</p>
        <p className="text-md mt-10">No expenses found</p>
      </div>
    );

  const totalCost = banks.reduce((acc, a) => acc + a.amount, 0).toFixed(2);
  return (
    <div className="">
      <h2 className="text-lg font-semibold mb-3">Expenses</h2>
      {/* info paragraph saying these are the expenses on hosting / services used */}
      <p className="text-sm text-gray-500 mb-3">These are the expenses on hosting / services used</p>

      <TablePagination sticky header={HEADER_BANKS} total={banks.length} pageSize={200}>
        <>
          <tr className="h-10 bg-gray-100">
            <td colSpan={4} className="text-left p-1 text-xs font-semibold"></td>
            <td colSpan={2} className="text-right p-1 text-xs font-semibold">
              {totalCost.toLocaleString("fr", { style: "currency", currency: "EUR" })}
            </td>
            <td colSpan={2} className="text-right p-1 text-xs font-semibold"></td>
          </tr>
          {banks.map((item, index) => (
            <BankRow key={index} item={item} totalCost={totalCost} />
          ))}
        </>
      </TablePagination>
    </div>
  );
};

const BankRow = ({ item, totalCost }) => {
  const cost = item.amount || 0;
  const percentage = totalCost ? ((cost / totalCost) * 100).toFixed(2) : 0;

  return (
    <tr className="h-10 hover:bg-gray-50">
      <td colSpan={4} className="text-left p-1 text-xs">
        {item.name}
      </td>
      <td colSpan={2} className="text-right p-1 text-xs">
        {item.amount.toLocaleString("fr", { style: "currency", currency: "EUR" })}
      </td>
      <td colSpan={2} className="text-right p-1 text-xs">
        {percentage}%
      </td>
    </tr>
  );
};
