import React, { useState } from "react";
import { useSelector } from "react-redux";

import SelectUser from "../selectUser";
import SelectJob from "../selectJob";

import Send from "./send";
import moment from "moment";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoMailOutline } from "react-icons/io5";

import api from "../../services/api";
import toast from "react-hot-toast";

const APPLICANT_STATUS_COLOR = {
  NEW: "#8B5CF6",
  REJECT: "#EF4444",
  SHORTLIST: "#3B82F6",
  CONTACTED: "#10B981",
  INTERVIEW: "#F59E0B",
  TECHNICAL_TEST: "#2563EB",
  FINAL_LIST: "#F59E0B",
  HIRE: "#10B981",
};

export default ({ user, setUser }) => {
  const [values, setValues] = useState(user);
  const loggedInUser = useSelector((state) => state.Auth.user);

  const onSave = async () => {
    const { data, ok } = await api.put(`/user/${user._id}`, values);
    if (!ok) return toast.error("Some Error!");
    toast.success("Updated !");
    setUser(data);
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-6 grid grid-cols-2 gap-4 bg-white border-[1px] rounded-lg border-gray-100 p-4">
        <div className="flex flex-col col-span-1 gap-4">
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">Project name</div>
            <UserInput disabled={true} value={user.project_name} />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">Channel</div>
            <UserInput disabled={true} value={user.channel_name} />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">Assigned to</div>
            <SelectUser
              className="mb-3"
              label="Assigned to"
              userName={values.user_name}
              value={{ user_avatar: values.user_avatar, user_name: values.user_name, user_id: values.user_id }}
              onChange={(e) => setValues({ ...values, user_id: e._id, user_name: e.name, user_avatar: e.avatar })}
            />
          </div>
          <div className="w-full">
            <div className="px-1 text-sm text-gray-600 font-medium w-16">More</div>
            <div>
              {(values.followers || []).map((e) => (
                <img key={e.user_avatar} className="h-8 w-8 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
              ))}
            </div>
            <SelectUser
              name="followers"
              className="projectsInput"
              placeholder="@somebody"
              onChange={async (u) => {
                if (!u._id) return;
                const followers = values.followers || [];
                if (followers.find((f) => f._id === u._id)) return;
                followers.push({ user_id: u._id, user_name: u.name, user_avatar: u.avatar });
                //followers
                const { data, ok } = await api.put(`/user/${user._id}`, { followers });
                if (!ok) return toast.error("Something went wrong!");
                toast.success(`Added ${u.name}`);
              }}
            />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">Job post</div>
            <SelectJob label="Job" value={{ _id: values.job_id }} onChange={(job) => setValues({ ...values, job_id: job._id, job_name: job.name })} />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">Job locations</div>
            {values.job_location?.map((location) => (
              <div key={location} className="flex items-center gap-1">
                <div className="h-2 w-2 ml-2 rounded-full bg-gray-300" />
                <span className="text-xs">{location}</span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="col-span-2">
            <Send user={user} callback={setUser} />
          </div>
          <div className="mt-2 flex col-span-2 items-end justify-between">
            <div>
              <div className="px-1 text-sm text-gray-600 font-medium">Status</div>
              <SelectStatus
                value={user.applicant_status}
                onChange={async (e) => {
                  const { ok, data } = await api.put(`/user/${user._id}`, { applicant_status: e.target.value });
                  toast.success("Status updated");
                  setUser(data);
                }}
              />
            </div>
            {user.applicant_status === "NEW" && (
              <div className="w-[calc(45%)]">
                <button
                  onClick={async () => {
                    const { ok, data } = await api.put(`/user/${values._id}`, {
                      applicant_status: "SHORTLIST",
                      user_id: loggedInUser._id,
                      user_avatar: loggedInUser.avatar,
                      user_name: loggedInUser.name,
                    });
                    if (!ok) return toast.error("Some Error!");
                    toast.success("User shortlisted");
                    setUser(data);
                  }}
                  className="w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 text-sm border-[1px] border-blue-500 hover:border-transparent rounded">
                  Shortlist
                </button>
              </div>
            )}
          </div>

          {user.applicant_status === "HIRE" ? (
            <div className="border border-red-400 p-2 rounded-xl">
              <div className="px-1 text-sm text-red-600 font-medium mb-2">Please fill-in the following infos!!</div>
              <div>
                <div className="px-1 text-sm text-gray-600 font-medium">Office</div>
                <SelectCountry value={values.office} onChange={(e) => setValues({ ...values, office: e.target.value })} />
              </div>
              <div className="mt-4">
                <div className="px-1 text-sm text-gray-600 font-medium">Starting date</div>
                <input
                  type="date"
                  label="Starting date"
                  name="starting_date"
                  defaultValue={values.starting_date ? new Date(values.starting_date).toISOString().split("T")[0] : null}
                  onChange={(e) => setValues({ ...values, starting_date: e.target.value })}
                  className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          ) : (
            <div className="col-span-2 mt-4">
              <Reject user={user} />
            </div>
          )}
          <div className="w-full flex mt-auto justify-end col-span-2">
            <button className="btn btn-primary" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="col-span-4 bg-white border-[1px] rounded-lg border-gray-100 p-4">
        <p className="mb-4">History</p>
        <div>
          {(user.logs || [])
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((log) => {
              const renderValue = () => {
                if (log?.type === "update_field") {
                  if (log?.field === "applicant_status")
                    return (
                      <div className="flex items-center gap-1 flex-wrap">
                        <div className="flex items-center gap-1">
                          <div className="h-2 w-2 rounded-full" style={{ backgroundColor: APPLICANT_STATUS_COLOR[log.old_value] }} />
                          <span className="text-xs">{log.old_value}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <MdOutlineKeyboardArrowRight />
                          <div className="h-2 w-2 rounded-full" style={{ backgroundColor: APPLICANT_STATUS_COLOR[log.new_value] }} />
                          <span className="text-xs">{log.new_value}</span>
                        </div>
                      </div>
                    );
                  else
                    return (
                      <p className="text-sm flex items-center gap-x-2 flex-wrap">
                        {log.old_value}
                        <MdOutlineKeyboardArrowRight />
                        {log.new_value}
                      </p>
                    );
                }
                if (log?.type === "mail_sent")
                  return (
                    <div className="flex items-center gap-1 whitespace-normal">
                      <IoMailOutline /> <span className="flex-1 text-sm">{log.subject}</span>
                    </div>
                  );
              };
              return (
                <div className="w-full grid grid-cols-4">
                  <div className="relative pr-3 border-r-[2px] border-gray-300">
                    <div className="absolute h-2 w-2 bg-gray-300 rounded-full -right-[5px] top-1 ring-2 ring-white" />
                    <p className="text-xs font-semibold text-right">{moment(log.created_at).format("DD MMM")}</p>
                    <p className="text-[9px] text-gray-600 text-right">{moment(log.created_at).fromNow()}</p>
                  </div>
                  <div className="col-span-3 pl-3 pb-8">
                    {renderValue()}
                    <p className="text-xs flex items-center gap-2 flex-wrap mt-1">
                      <img src={log.from_user_avatar} className="w-4 h-4 rounded-full" /> {log.from_username}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);

export const SelectStatus = ({ value, onChange }) => {
  return (
    <div>
      <select
        className={`w-full text-[12px] text-black border-2 font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm`}
        style={{ borderColor: APPLICANT_STATUS_COLOR[value] }}
        value={value || ""}
        name="status"
        onChange={onChange}>
        <option value="" selected>
          Select a status
        </option>
        <option key="NEW" value="NEW">
          NEW
        </option>
        <option key="REJECT" value="REJECT">
          REJECT
        </option>
        <option key="SHORTLIST" value="SHORTLIST">
          SHORTLIST
        </option>
        <option key="CONTACTED" value="CONTACTED">
          CONTACTED
        </option>
        <option key="INTERVIEW" value="INTERVIEW">
          INTERVIEW
        </option>
        <option key="FINAL_LIST" value="FINAL_LIST">
          FINAL_LIST
        </option>
        <option key="HIRE" value="HIRE">
          HIRE
        </option>
        <option key="TECHNICAL_TEST" value="TECHNICAL_TEST">
          TECHNICAL_TEST
        </option>
      </select>
    </div>
  );
};

const SelectCountry = ({ value, onChange }) => {
  return (
    <div>
      <select
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
        value={value || ""}
        name="country"
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}>
        <option value="" selected>
          Select a country
        </option>
        <option key="France" value="France">
          France
        </option>
        <option key="Spain" value="Spain">
          Spain
        </option>
        <option key="Netherland" value="Netherland">
          Netherland
        </option>
        <option key="Other" value="Other">
          Other
        </option>
      </select>
    </div>
  );
};

const Reject = ({ user }) => {
  const [open, setOpen] = useState(false);

  async function onSubmit() {
    const rejected_reason = Array.from(document.querySelectorAll('input[name="reason"]:checked')).map((input) => input.value);
    await api.put(`/user/${user._id}`, { status: "REJECT", rejected_reason });
    await api.post(`/user/comment/${user._id}`, { text: `Rejected: ${rejected_reason.join(", ")}` });
    toast.success("Applicant rejected");
    setOpen(false);
  }

  return (
    <div className="">
      <button
        className="hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 text-sm border-[1px] border-blue-500 hover:border-transparent rounded block"
        onClick={() => setOpen(!open)}>
        Toggle reject
      </button>
      {open && (
        <div className="border border-gray-400 p-2 rounded-xl">
          <React.Fragment>
            <div className="w-full text-left">
              <form>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-mindset" />
                  <div className="ml-2">Not the right mindset</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-skills" />
                  <div className="ml-2">Not the right skills</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-experience" />
                  <div className="ml-2">Not the right experience</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-localisation-language" />
                  <div className="ml-2">Not the right localisation/language</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="salary-expectations" />
                  <div className="ml-2">Salary expectations</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="poor-performance" />
                  <div className="ml-2">Poor performance in the interview process</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="position-already-filled" />
                  <div className="ml-2"> Position already filled</div>
                </label>
                <br />
              </form>
              <button className=" bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
                Reject
              </button>
            </div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};
