import { useState, useEffect } from 'react';
import api from '../../../services/api';

export function useHolidayActivities(){
  const [hollidayActivities, setHollidayActivities] = useState(null);
  const [peopleCurrentlyOff, setPeopleCurrentlyOff] = useState(null);
  
  let d = new Date();
  d.setHours(0, 0, 0, 0);

  useEffect(() => {
    (async ()=>{
      const dateFrom = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1));
      const dateTo = new Date(dateFrom);
      dateTo.setMonth(dateTo.getMonth() + 3);
      dateTo.setDate(0);    
      const { data } = await api.get(`/activity?projectName=${encodeURIComponent("Leave paid")},Unavailable,RTT&dateFrom=${dateFrom.getTime()}&dateTo=${dateTo.getTime()}`);
      const hollidays = await getDaysOff(data);  
      setHollidayActivities(hollidays);
    })()
  }, []);

  useEffect(() => {
    if (!hollidayActivities) return;
    setPeopleCurrentlyOff((hollidayActivities || []).filter((e) => differenceDays(new Date(), e.ranges[0].start) === 0));
  }, [hollidayActivities]);

  return { hollidayActivities, peopleCurrentlyOff };
}

async function getDaysOff(leavePaidActivities) {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    const hollidays = [];

    for (let i = 0; i < leavePaidActivities.length; i++) {
      const slots = [];
      for (let j = 0; j < leavePaidActivities[i].detail.length; j++) {
        if (leavePaidActivities[i].detail[j].value === 0) continue;
        const slotDate = new Date(leavePaidActivities[i].date);
        slotDate.setDate(j + 1);
        if (slotDate < d) continue; // skip past days
        slots.push(slotDate);
      }
      if (slots.length == 0) continue;

      const findIndex = hollidays.findIndex((e) => e.userName === leavePaidActivities[i].userName);
      if (findIndex > -1) {
        hollidays[findIndex].slots = hollidays[findIndex].slots.concat(slots);
      } else {
        hollidays.push({ userName: leavePaidActivities[i].userName, slots, userAvatar: leavePaidActivities[i].userAvatar, userId: leavePaidActivities[i].userId });
      }
    }

    for (let i = 0; i < hollidays.length; i++) {
      hollidays[i].ranges = createRangesFromDays(hollidays[i].slots.sort((a, b) => a - b));
    }

    return hollidays;
  }

function createRangesFromDays(days) {
    const ranges = [];
    let start = days[0];
  
    const ONEDAY = 1 * 24 * 60 * 60 * 1000;
    for (let i = 1; i < days.length; i++) {
      const _from = days[i - 1];
      const _to = days[i];
  
      // if the difference between the two days is more than one day -> we have a range
      // if the 2 days are monday and friday and the difference is less than 4 days (monday and friday around the weekend) -> we stay in the same range
      if (_to.getTime() - _from.getTime() > ONEDAY && !(_to.getDay() === 1 && _from.getDay() === 5 && _to.getTime() - _from.getTime() < ONEDAY * 4)) {
        ranges.push({ start, end: _from });
        start = _to;
      }
    }
    ranges.push({ start, end: days[days.length - 1] });
    return ranges;
  }  

export const differenceDays = (date1, date2) => {
    let difference = date2.getTime() - date1.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  };