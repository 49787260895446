import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import moment from "moment";
import SelectUsers from "../selectUser";
import SelectS_Contact from "../selectS_Contact";
import TextEditor from "../TextEditor";

import api from "../../services/api";

export default ({ action, setAction }) => {
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const { ok, data } = await api.put(`/s_action/${action._id}`, action);
      if (!ok) return toast.error("Error updating action");
      toast.success("Action updated!");
      setAction(data);
    } catch (e) {
      console.log(e);
      toast.error("Error updating action!");
    }
  }

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this action?") === false) return;
    try {
      await api.remove(`/s_action/${id}`);
      toast.success("Action deleted!");
      refetch();
      setAction(null);
    } catch (e) {
      console.log(e);
      toast.error("Error deleting action!");
    }
  }

  function handleChange(e) {
    setAction({ ...action, [e?.target?.name]: e?.target?.value });
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-wrap gap-2 p-4 text-sm my-4 border shadow-sm">
      <div className=" font-medium w-full md:w-[48%] flex gap-2 flex-col">
        Owner :<SelectUsers userName={action.userName} onChange={(v) => setAction({ ...action, userId: v?._id, userName: v.name, userAvatar: v.avatar })} />
      </div>
      <div className=" font-medium w-full sm:w-[48%] flex gap-2 flex-col">
        Contact Name :
        <SelectS_Contact
          userName={action?.contact_name}
          value={action?.contact_id}
          onChange={(item) => {
            if (!item?._id) return setAction({ ...action, contact_name: "", contact_id: "" });
            setAction({ ...action, contact_name: `${item?.first_name || item?.firstName} ${item?.last_name || item?.lastName}`, contact_id: item?._id });
          }}
        />
      </div>

      <div className="w-full sm:w-[48%]">
        <UserSelect label="Category" name="category" value={action.category} onChange={handleChange}>
          {!action.category && <option value="">Select</option>}
          <option value="CALL_BACK">Call Back</option>
          <option value="CONTACT_SOMEONE">Contact Someone</option>
          <option value="SEND_PROPOSAL">Send Proposal</option>
          <option value="OTHER">Other</option>
          <option value="SORTLIST">Sortlist</option>
          <option value="TRUSTPILOT">Trustpilot</option>
        </UserSelect>
      </div>

      <div className="w-full sm:w-[48%]">
        <DeadlineInput label="Deadline" value={action.deadline?.split("T")[0]} onChange={handleChange} action={action} setAction={setAction} />
      </div>

      <div className="mt-3  w-full">
        <div className="font-medium mb-2">Description</div>
        <TextEditor value={action?.description || ""} onChange={(e) => setAction({ ...action, description: e.target.value })} defaultPrompt={``} />
      </div>

      <button type="submit" className="mt-3 bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]">
        Update Action
      </button>
      <button
        className="mt-3 ml-3 bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]"
        onClick={(e) => {
          e.preventDefault();
          onDelete(action._id);
        }}>
        Delete
      </button>
    </form>
  );
};

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm font-medium mb-2">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);

const DeadlineInput = ({ value, onChange, label, className, action, setAction }) => {
  const [selectedType, setSelectedType] = useState("CUSTOM");
  const disabled = selectedType !== "CUSTOM";
  return (
    <div className={`${className} w-full`}>
      <div className="px-1 text-sm font-medium mb-2">{label}</div>
      <div className="flex gap-2">
        <button
          className={`rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2 ${
            selectedType === "NEXT_WEEK" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={(e) => {
            e?.preventDefault();
            setSelectedType("NEXT_WEEK");
            setAction({ ...action, deadline: moment().add(1, "week").toISOString() });
          }}>
          Next week
        </button>
        <button
          className={`rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2 ${
            selectedType === "NEXT_MONTH" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={(e) => {
            e?.preventDefault();
            setSelectedType("NEXT_MONTH");
            setAction({ ...action, deadline: moment().add(1, "month").toISOString() });
          }}>
          Next month
        </button>
        <button
          className={`rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2 ${
            selectedType === "CUSTOM" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={(e) => {
            e?.preventDefault();
            setSelectedType("CUSTOM");
            setAction({ ...action, deadline: moment().toISOString() });
          }}>
          Custom
        </button>
      </div>
      <input
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2 mt-2"
        name={"deadline"}
        value={value}
        onChange={onChange}
        type={"date"}
        disabled={disabled}
      />
    </div>
  );
};

const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full flex flex-col gap-2">
    <div className="font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);
