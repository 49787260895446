import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import Campaigns from "./campaigns";
import Contacts from "./contacts";
import Companies from "./companies";
import Sources from "./sources";
import Queue from "./queue";
import Actions from "./action";

export default () => {
  const navigate = useNavigate();

  console.log("COUCOU acquiz");


  return (
    <div>
      <div className="m-2">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px list-none space-x-2">
            <li
              onClick={(e) => navigate(`/acquiz/campaign`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>
              Campaigns
            </li>
            <li
              onClick={(e) => navigate(`/acquiz/contacts`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>
              Contacts
            </li>
            <li
              onClick={(e) => navigate(`/acquiz/companies`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>
              Companies
            </li>
            <li
              onClick={(e) => navigate(`/acquiz/sources`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>
              Sources
            </li>
            <li
              onClick={(e) => navigate(`/acquiz/queue`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>
              Queue
            </li>
            <li
              onClick={(e) => navigate(`/acquiz/actions`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>
              Actions
            </li>
          </ul>
        </div>
        <Routes>
          <Route path="/" element={<Navigate to="/acquiz/campaign" replace />} />
          <Route path="/campaign/*" element={<Campaigns />} />
          <Route path="/contacts/*" element={<Contacts />} />
          <Route path="/companies/*" element={<Companies />} />
          <Route path="/sources/*" element={<Sources />} />
          <Route path="/queue/*" element={<Queue />} />
          <Route path="/actions/*" element={<Actions />} />
        </Routes>
      </div>
    </div>
  );
};
