import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../../services/api";

export default ({ contact }) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({ message: "", explanation: "", messageBertrand: "", explanationBertrand: "" });

  const [activity, setActivity] = useState(null);

  useEffect(() => {
    load();
  }, [contact]);

  async function load() {
    setLoading(true);
    const { data } = await api.post(`/s_activity/search`, { contact_id: contact._id, status: "draft" });
    if (data.length > 0) {
      setActivity(data[0]);
      setValues(data[0].raw);
    }
    setLoading(false);
  }

  async function updateActivity() {
    try {
      const res = await api.put(`/s_activity/${activity._id}`, { ...values });
      toast.success("Activity updated!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating activity");
    }
  }

  async function deleteActivity() {
    try {
      const res = await api.remove(`/s_activity/${activity._id}`);
      setActivity(null);
      setValues({ message: "", explanation: "", messageBertrand: "", explanationBertrand: "" });
      toast.success("Activity deleted!");
    } catch (error) {
      console.error(error);
      toast.error("Error deleting activity");
    }
  }

  async function sendMessage() {
    try {
      // const toastId = toast.loading("Sending...");
      // const res = await api.post(`/s_activity/send`, { activity_id: activity._id });
      // toast.dismiss(toastId);
      toast.success("TODO!");
    } catch (error) {
      console.error(error);
      toast.error("Error sending activity");
    }
  }

  const generateMessage = async () => {
    try {
      const toastId = toast.loading("Generation...");
      let { data, ok } = await api.post(`/s_contact/generate`, { contact: contact });
      if (!ok) return toast.error("Error generating message");
      toast.dismiss(toastId);
      toast.success("successfully generated!");

      const v = { ...values, messageBertrand: data.content, explanationBertrand: data.explanation };

      ({ data } = await api.post(`/s_activity`, {
        type: "linkedinSent",
        tool: "accounting",
        status: "draft",
        raw: v,
        contact_id: contact._id,
        contact_name: contact.first_name + " " + contact.last_name,
        company_name: contact.company_name,
      }));
      setActivity(data);
      setValues(v);
      toast.success("Activity created!");
    } catch (error) {
      console.error(error);
      toast.error("Error generating message");
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="w-full">
      <label className="block text-m font-medium text-gray-700" htmlFor="description">
        Message généré
      </label>
      <div className="flex flex-row my-2 gap-2">
        <textarea disabled className="w-1/2 border border-gray-400 rounded-md text-sm" value={values.messageBertrand} />
        <p className="w-1/2 text-sm">Why : {"\n" + values.explanationBertrand}</p>
      </div>
      <label className="block text-m font-medium text-gray-700" htmlFor="description">
        Message à envoyer
      </label>
      <div className="flex flex-row my-2 gap-2">
        <textarea className="w-1/2 border border-gray-400 rounded-md text-sm" value={values.message} onChange={(e) => setValues({ ...values, message: e.target.value })} />
        <div className="flex flex-col w-1/2">
          <p className="w-full ">Why :</p>{" "}
          <textarea
            className="w-full border border-gray-400 rounded-md text-sm"
            value={values.explanation}
            onChange={(e) => setValues({ ...values, explanation: e.target.value })}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {!activity && (
          <button className="btn btn-primary mb-2" onClick={generateMessage}>
            Generate
          </button>
        )}
        {activity && (
          <button className="btn btn-primary mb-2" onClick={updateActivity}>
            Save
          </button>
        )}
        {activity && (
          <button className="btn btn-primary mb-2" onClick={deleteActivity}>
            Delete
          </button>
        )}
        <button className="btn btn-primary" onClick={sendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};
