import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";

import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState();
  const [filter, setFilter] = useState({
    search: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const handleNextPage = () => setCurrentPage((prev) => prev + 1);
  const handlePreviousPage = () => setCurrentPage((prev) => prev - 1);

  useEffect(() => {
    get();
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    get();
  }, [currentPage]);

  const get = async () => {
    try {
      const { data, ok, total, code } = await api.post("/ai-review/search", {
        search: filter.search,
        offset: (currentPage - 1) * 10,
        sort: { created_at: -1 },
      });
      if (!ok) return toast.error("Error!" || code);
      setReviews(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching reviews!");
    }
  };

  if (!reviews) return <Loader />;

  return (
    <div className="space-y-6 p-5">
      <div className="flex items-center justify-between">
        <div className="flex gap-6 w-full justify-between">
          <div className="relative rounded-md shadow-sm w-1/3">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="search"
              value={filter.search}
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
              className="block w-full h-10 rounded-md border border-gray-300 !pl-10 focus:border-sky-700 md:text-sm"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border shadow-md rounded-lg bg-gray-50">
              <table className="min-w-[900px] w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden">
                <thead>
                  <tr>
                    <th className="p-3 text-left font-medium text-xs lg:text-sm">Author</th>
                    <th className="p-3 text-left font-medium text-xs lg:text-sm">AI Review</th>
                    <th className="p-3 text-left font-medium text-xs lg:text-sm px-7">Date</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-gray-50">
                  {reviews.map((review, i) => (
                    <tr key={i} className="cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/review/${review._id}`)}>
                      <td className="p-3 text-xs lg:text-sm text-primary-black-90">{review.author} on {review.repo_name}</td>
                      <td className="p-3 text-xs lg:text-sm text-primary-black-90 overflow-hidden">{truncate_text(review.review_content)}</td>
                      <td className="p-3 text-xs lg:text-sm text-primary-black-90 whitespace-nowrap px-7">
                        {new Date(review.created_at).toLocaleDateString() + " " + new Date(review.created_at).toLocaleTimeString()}
                      </td>
                      <td  className="p-3 text-xs lg:text-sm text-primary-black-90 whitespace-nowrap px-7">
                        <a href={review.comment_link} target="_blank" className="hover:bg-gray-100 px-2 py-1 text-sm border rounded-md h-fit">
                         PR  #{review.pr_number}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination total={total} currentPage={currentPage} onNext={handleNextPage} onPrevious={handlePreviousPage} />
    </div>
  );
};

const truncate_text = (text, limit = 250) => (text?.length > limit ? text?.slice(0, limit) + "..." : text);
