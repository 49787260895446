import React, { useEffect, useState } from "react";

import api from "../services/api";

export default ({ value, userName, onChange, disabled = false, placeholder = "contact", name = new Date().getTime() }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(userName ?? "");

  useEffect(() => {
    searchUser();
  }, [search]);

  useEffect(() => {
    if (!userName) return;
    setSearch(userName);
  }, []);

  async function searchUser() {
    const { ok, data } = await api.post("/s_contact/search", { search });
    if (!ok) return;
    setUsers(data);
  }

  return (
    <div>
      <div>
        <input
          list={name}
          value={search}
          disabled={disabled}
          className="input"
          placeholder={placeholder}
          onChange={(e) => {
            setSearch(e.target.value || "");
            if (!e.target.value) return onChange(null);
            const f = users.find((f) => e.target.value === f?.first_name + " " + f?.last_name);
            if (f) onChange(f);
          }}
        />
        <datalist
          id={name}
          name={name}
          className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
          value={(value && value.first_name && value.last_name) || ""}
          defaultValue="">
          <option value="" disabled>
            Select user
          </option>
          {users
            ?.sort(function (a, b) {
              if (a?.first_name?.toLowerCase() < b?.first_name?.toLowerCase()) return -1;
              if (a?.last_name?.toLowerCase() > b?.last_name?.toLowerCase()) return 1;
              return 0;
            })
            .map((e) => (
              <option key={e._id} value={`${e.first_name} ${e?.last_name}`} />
            ))}
        </datalist>
      </div>
    </div>
  );
};
