import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import SelectMonth from "../../components/selectMonth";
import SelectProject from "../../components/selectProject";
import SelectUser from "../../components/selectUser";
import Loader from "../../components/loader";

import api from "../../services/api";
import SearchSelect from "../../components/SearchSelect";
import TablePagination from "../../components/TablePagination";
import { MONTHS, YEARS } from "../../constants/date";

const HEADER = [{ title: "Name" }, { title: "Status" }, { title: "Informations", colSpan: 2 }];

const InvoiceList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    month: searchParams.get("month") || "",
    year: searchParams.get("year") || new Date().getFullYear(),
    project: searchParams.get("project") || "",
    sent: searchParams.get("sent") || "",
    received: searchParams.get("received") || "no",
    organisation: searchParams.get("organisation") || "",
    page: searchParams.get("page") || 1,
    per_page: searchParams.get("per_page") || 50,
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const organisation = useSelector((state) => state.Auth.organisation);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok: okP, data: dataP } = await api.post("/project/search", { status: "active", per_page: 1000 });
        if (okP) setProjects(dataP.projects.map((e) => ({ label: e.name, value: e._id })));
        const { ok: okO, data: dataO } = await api.post("/organisation/search");
        if (okO) setOrganisations(dataO.map((e) => ({ label: e.name, value: e._id })));
      } catch (error) {
        toast.error("An error occurred while fetching projects");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const obj = { limit: 1000 };
        if (filters.year && filters.month) {
          obj.dateFrom = new Date(Date.UTC(filters.year, filters.month, 1));
          obj.dateTo = new Date(Date.UTC(filters.year, filters.month + 1, 1, 0, 0, -1));
        } else if (filters.year && !filters.month) {
          obj.dateFrom = new Date(Date.UTC(filters.year, 0, 1));
          obj.dateTo = new Date(Date.UTC(filters.year + 1, 0, 1, 0, 0, -1));
        } else if (filters.month) {
          obj.dateFrom = new Date(Date.UTC(new Date().getFullYear(), filters.month, 1));
          obj.dateTo = new Date(Date.UTC(new Date().getFullYear(), filters.month + 1, 1, 0, 0, -1));
        }
        if (filters.project) obj.projectId = filters.project;
        if (filters.received) obj.received = filters.received;
        if (filters.projectLeadId) obj.projectLeadId = filters.projectLeadId;
        if (filters.sent) obj.sent = filters.sent;
        if (filters.organisation) obj.organisation_id = filters.organisation;

        const newSearchParams = new URLSearchParams();
        if (filters.year) newSearchParams.set("year", filters.year);
        if (filters.month) newSearchParams.set("month", filters.month);
        if (filters.project) newSearchParams.set("project", filters.project);
        if (filters.received) newSearchParams.set("received", filters.received);
        if (filters.organisation) newSearchParams.set("organisation", filters.organisation);
        setSearchParams(newSearchParams);

        const res = await api.post("/invoice/search", obj);
        if (!res.ok) throw new Error("An error occurred while fetching invoices");
        setData(res.data);
        console.log(res);
        setTotal(res.total);
      } catch (error) {
        toast.error("An error occurred while fetching invoices");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters, organisation]);

  return (
    <div className="m-10 flex flex-col gap-6">
      <div className="w-full flex-col md:flex-row flex items-center gap-4">
        <div className="w-full">
          <label className="text-xs text-gray-500">Year</label>
          <select className="select" value={filters.year.toString()} onChange={(e) => setFilters({ ...filters, year: e.target.value ? parseInt(e.target.value) : "" })}>
            <option value="">All</option>
            {YEARS.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Month</label>
          <select className="select" value={filters.month.toString()} onChange={(e) => setFilters({ ...filters, month: e.target.value ? parseInt(e.target.value) : "" })}>
            <option value="">All</option>
            {MONTHS.map((e, i) => (
              <option key={i} value={i}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Project</label>
          <SearchSelect
            placeholder="Projects"
            options={projects}
            value={projects.find((e) => e.value === filters.project)}
            onSelect={(e) => setFilters({ ...filters, project: e ? e.value : "" })}
            className="w-80"
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Organisation</label>
          <SearchSelect
            placeholder="Organisations"
            options={organisations}
            value={organisations.find((e) => e.value === filters.organisation)}
            onSelect={(e) => setFilters({ ...filters, organisation: e ? e.value : "" })}
            className="w-80"
          />
        </div>

        <div className="w-full">
          <label className="text-xs text-gray-500">Leader</label>
          <SelectUser
            className="mb-3"
            label="Assigned to"
            userName={filters.projectLeadName}
            value={{ user_name: filters.projectLeadName, user_id: filters.projectLeadId }}
            onChange={(e) => {
              setFilters({ ...filters, projectLeadId: e._id, projectLeadName: e.name });
            }}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Received</label>
          <select className="select" value={filters.received} onChange={(e) => setFilters({ ...filters, received: e.target.value })}>
            <option value="all">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Sent</label>
          <select className="select" value={filters.sent} onChange={(e) => setFilters({ ...filters, sent: e.target.value })}>
            <option value="all">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-3 justify-between">
        <h3 className="text-lg font-semibold">{total.toLocaleString("fr")} invoives</h3>
        <div className="flex items-center gap-3 justify-end">
          <CreateModal />
          <ExportModal data={data} />
        </div>
      </div>
      <section>
        {loading ? (
          <Loader />
        ) : (
          <TablePagination
            header={HEADER}
            total={total}
            loading={loading}
            page={filters.page}
            pageSize={filters.per_page}
            onPageChange={(page) => setFilters({ ...filters, page })}>
            {data.map((e, i) => {
              const date = new Date(e.date);
              const isNewMonth = i === 0 || date.getMonth() !== new Date(data[i - 1].date).getMonth();
              return (
                <Fragment key={i}>
                  {isNewMonth && (
                    <tr>
                      <td colSpan={4} className="bg-blue-100 py-1 text-sm text-gray-700 text-center font-semibold uppercase">
                        {MONTHS[date.getMonth()]} {date.getFullYear()}
                      </td>
                    </tr>
                  )}
                  <Row data={e} />
                </Fragment>
              );
            })}
          </TablePagination>
        )}
      </section>
    </div>
  );
};

const BILLING = {
  MONTHLY: <span className="p-1 rounded bg-blue-100">Monthly</span>,
  ONE_TIME: <span className="p-1 rounded bg-green-100">One time</span>,
};

const Row = ({ data }) => {
  const navigate = useNavigate();

  return (
    <tr className="h-16 hover:bg-gray-100 border-t border-gray-200 cursor-pointer" onClick={() => navigate(`/invoice/${data._id}`)}>
      <td className="text-left p-2 text-sm">
        <h3 className="text-sm font-semibold">{data.name}</h3>
        <div>{data.projectLeadName ? <span className="px-1 rounded bg-gray-100 text-gray-600 font-semibold text-xs">{data.projectLeadName}</span> : null}</div>
      </td>
      <td className="text-left p-2 text-sm">
        <div className="space-y-2">
          {data.sent === "yes" ? (
            <>
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded bg-green-600 mr-2" />
                <span className="text-xs text-gray-700">Sent the {new Date(data.sentAt).toLocaleDateString("fr")}</span>
              </div>
              {data.received === "yes" ? (
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded bg-green-600 mr-2" />
                  <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Received the {new Date(data.receivedAt).toDateString()}</p>
                </div>
              ) : data.late > 0 ? (
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded bg-red-600 mr-2" />
                  <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">{data.late} days late</p>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded bg-orange-400 mr-2" />
                  <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Not received {-data.late} days remaining</p>
                </div>
              )}
            </>
          ) : new Date() > new Date(data.dueAt) ? (
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded bg-red-600 mr-2" />
              <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Not sent - should have been sent on {new Date(data.dueAt).toLocaleDateString("fr")}</p>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded bg-orange-400 mr-2" />
              <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Not sent</p>
            </div>
          )}
        </div>
      </td>

      <td colSpan={2} className="text-left p-2 text-sm">
        <div className="flex-1 grid-cols-4 gap-2 hidden md:grid">
          <div className="flex items-center w-1/6">
            <label className="text-xs text-gray-500">Type</label>
            <span className="ml-2 text-xs text-gray-700">{data.type}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Budget</label>
            <span className="ml-2 text-xs text-gray-700">{data.budget ? `${data.budget.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Cost</label>
            <span className="ml-2 text-xs text-gray-700">{data.cost ? `${data.cost.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Margin</label>
            <span className="ml-2 text-xs text-gray-700">{data.margin != null ? `${data.margin} %` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Billing</label>
            <span className="ml-2 text-xs text-gray-700">{BILLING[data.billing] || data.billing}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Index</label>
            <span className="ml-2 text-xs text-gray-700">{data.index || " -- "}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Total</label>
            <span className="ml-2 text-xs text-gray-700">{data.total ? `${data.total.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Profit</label>
            <p className="flex-1 ml-2 text-xs text-gray-700">{data.profit != null ? `${data.profit.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</p>
          </div>
        </div>
      </td>
    </tr>
  );
};

const CreateModal = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onCreate() {
    if (!values.project) return toast.error("Missing Project !");
    const res = await api.post("/invoice", { project_id: values.project._id, date: values.date });
    navigate(`/invoice/${res.data._id}`);
  }

  return (
    <>
      <div className="text-right">
        <button className="blue-btn h-8" onClick={() => setOpen(true)}>
          Create an invoice
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div>
                <div className="flex flex-wrap justify-between mt-3">
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium">Project (*)</div>
                    <SelectProject indexDefaultValue={0} value={values?.project?.name} onChange={(e) => setValues({ ...values, project: e })} />
                  </div>
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium">Mois (*)</div>
                    <SelectMonth
                      placeholder="Select month"
                      indexDefaultValue={0}
                      start={-3}
                      value={values.date}
                      onChange={(e) => setValues({ ...values, date: e.target.value })}
                      name="date"
                    />
                  </div>
                </div>
              </div>
              <br />
              <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};

const ExportModal = ({ data }) => {
  const onClick = async () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Id;Name;Index;Sent at;Due At;Link to invoice;Total;Net Total;Days late;Penality; With Penality\n`;
    csvContent += data
      .map(
        (e) =>
          `${e.name};${e.index};${(e.sentAt || "").substring(0, 10)};${(e.dueAt || "").substring(0, 10)}; ${e.file ? e.file : "not generated"};${e.total}€;${e.netTotal}€;${
            e.late
          } days late;${e.penality}€; ${(e.total + e.penality).toLocaleString("fr", { style: "currency", currency: "EUR" })}`,
      )
      .join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="blue-btn h-8" onClick={onClick}>
      Export
    </button>
  );
};

export default InvoiceList;
