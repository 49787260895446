import React, { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { FaLink, FaLinkSlash } from "react-icons/fa6";
import DOMPurify from "dompurify";

const FeatureInput = ({ feature, index, values, setValues }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [displayValue, setDisplayValue] = useState(feature.replace(/<[^>]+>/g, ""));

  useEffect(() => {
    const urlMatch = feature.match(/href="([^"]*)"/);
    if (urlMatch) {
      setLinkUrl(urlMatch[1]);
    }
  }, [feature]);

  const handleFeatureChange = (e) => {
    setDisplayValue(e.target.value);

    const newFeatures = [...values.features];
    if (linkUrl) newFeatures[index] = `<a href="${linkUrl}" class="text-blue-500 hover:text-blue-700 break-all">${DOMPurify.sanitize(e.target.value)}</a>`;
    else newFeatures[index] = e.target.value;
    setValues((prev) => ({ ...prev, features: newFeatures }));
  };

  const handleDeleteFeature = () => {
    if (values.features.length === 1) {
      return setValues((prev) => ({ ...prev, features: [""] }));
    }
    const newFeatures = values.features.filter((_, i) => i !== index);
    setValues((prev) => ({ ...prev, features: newFeatures }));
  };

  const handleAddFeature = () => {
    const newFeatures = [...values.features.slice(0, index + 1), "", ...values.features.slice(index + 1)];
    setValues((prev) => ({ ...prev, features: newFeatures }));
  };

  const handleAddLink = () => {
    const newFeatures = [...values.features];
    newFeatures[index] = `<a href="${linkUrl}" class="text-blue-500 hover:text-blue-700 break-all">${DOMPurify.sanitize(displayValue)}</a>`;
    setValues((prev) => ({ ...prev, features: newFeatures }));
    setIsModalOpen(false);
  };

  const handleUnlink = () => {
    const newFeatures = [...values.features];
    newFeatures[index] = displayValue;
    setValues((prev) => ({ ...prev, features: newFeatures }));
    setLinkUrl("");
    setIsModalOpen(false);
  };

  return (
    <div className="flex items-center mb-2 !mt-2">
      <input
        type="text"
        className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
        placeholder="Add a feature shortly ..."
        value={displayValue}
        onChange={handleFeatureChange}
      />
      <button className="ml-2 p-2 border rounded-md hover:bg-blue-300" onClick={() => setIsModalOpen(true)}>
        {linkUrl ? <FaLinkSlash /> : <FaLink />}
      </button>
      <button className="ml-2 p-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleDeleteFeature}>
        <FaTrash />
      </button>
      <button className="ml-2 p-2 bg-green-500 text-white rounded-md hover:bg-green-600" onClick={handleAddFeature}>
        <FaPlus />
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-lg font-bold mb-2">Connect URL</h2>
            <input type="text" className="form-input w-full p-2 border rounded-md mb-2" placeholder="Enter URL" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />
            <div className="flex justify-end">
              <button className="mr-2 p-2 bg-gray-300 rounded-md hover:bg-gray-400" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
              <button className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleAddLink}>
                Add Link
              </button>
              <button className="ml-2 p-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleUnlink}>
                Unlink
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureInput;
