import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Loader from "../../../components/loader";

const DATES = [];
var date = new Date(Date.UTC(2023, 4, 1, 0, 0, 0));
for (let i = -5; i < 10; i++) {
  DATES.push(new Date(date.setMonth(date.getMonth() + 1)));
}


export default ({ }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [percentOccupation, setPercentOccupation] = useState(0.8);
  const [fabMax, setFabMax] = useState(true);
  const [annualGoal, setAnnualGoal] = useState(5000000);
  const [bonus, setBonus] = useState(true);


  useEffect(() => {
    get()
  }, [percentOccupation, fabMax, annualGoal, bonus]);

  async function get() {

    setLoading(true)

    const dateFrom = new Date(DATES[0])
    dateFrom.setDate(dateFrom.getDate() - 1)

    const query2 = { type: "invoice", sent: "yes" }
    const res1 = await api.post(`/invoice/search`, query2);
    const invoices = res1.data

    const query3 = { startDate: dateFrom, per_page: 20000 }
    const res2 = await api.post(`/bank/search`, query3);
    const banks = res2.data.banks

    const query5 = { projectType: { $nin: ["timeoff"] } };
    const res5 = await api.post(`/activity/search`, query5);
    const activities = res5.data

    let transactions = []
    if (bonus) {
      const res6 = await api.post(`/wallet_transaction/search`);
      transactions = res6.data
    }

    const res7 = await api.post(`/user/search`)
    const users = res7.data.users

    for (let i=0; i < activities.length; i++) {
      const activity = activities[i]
      const user = users?.find(e => e._id === activity.userId)
      activity.tjm_expense = user?.tjm_expense * activity.total / 8
      activity.maximumPotentialRevenue = activity.value
      activity.potentialCost = activity.cost

      if (user?.position === "FULLSTACK" && user?.division === "LE_COLLECTIF" && user?.contract != "FREELANCE") {
        activity.subventionFrench = 0.3 * activity.cost
      }
      if (user?.position === "FULLSTACK" && user?.division === "SELEGO_NL" && user?.contract != "FREELANCE") {
        activity.subventionDutch = 0.3 * activity.cost
      }

      if (!fabMax) {
        if (activity.userName === "Fabien Bouhier" || activity.userName === "Maxime Perdu") {
          activity.value = 0
          activity.potentialCost = 0
          activity.maximumPotentialRevenue = 0
          activity.total = 0
          activity.cost = 0
        }
      }
      if (activity.projectType === "admin" || activity.projectType === "prospection") {
        activity.value = 0
      }
      if ((activity.projectType === "admin" || activity.projectType === "prospection") && activity.projectName !== "le stud") {
        activity.maximumPotentialRevenue = 0    
      }
      activity.goal  = activity.maximumPotentialRevenue * percentOccupation
    } 

    const obj = {}
    for (let i = 0; i < DATES.length; i++) {
      const date = DATES[i]

      const filteredActivities = (activities.filter(e => e.projectType != "timeoff")).filter(((f) => areSameMonth(f.date, date)))
      const fixedCostsFromActivities= parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.tjm_expense) || 0), 0))

      const filteredCostsClient = (banks.filter(e => e.category === "GENERAL_COST" && (e.projectName != "selego" && e.projectName != "le collectif" && e.projectName != "")).filter((f) => areSameMonth(f.month, date)))
      const costsClient = parseInt(filteredCostsClient.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      // Concerning revenue
      const filteredInvoice = (invoices.filter((f) => areSameMonth(f.date, date)))
      const revenue = parseInt(filteredInvoice.reduce((acc, f) => acc + (parseInt(f.netTotal) || 0), 0))

      const potentialRevenue = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.value) || 0), 0)) + (-costsClient)
      const potentialMaximalRevenue = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.maximumPotentialRevenue) || 0), 0)) + (-costsClient)

      const potentialGoalRevenue = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.goal) || 0), 0))

      const filteredSubventions = (banks.filter(e => e.sub_category === "SUBVENTION").filter((f) => areSameMonth(f.month, date)))
      const subventions = parseInt(filteredSubventions.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      //people
      const etp = Math.round(parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.total) || 0), 0))/8/22)
      const revenuePerPersonPerMonth = Math.round(revenue / etp)

      // Concerning variable costs
      const filteredCostsCompany = banks
      .filter((e) => e.category === "GENERAL_COST" && (e.projectName == "selego" || e.projectName == "le collectif" || e.projectName == "le stud" || e.projectName === ""))
      .filter((f) => areSameMonth(f.month, date));
      const fixedCostsCompany = parseInt(filteredCostsCompany.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const filteredActivitiesEmployee = filteredActivities.filter(((f) => f.userContract != "FREELANCE")).filter(e => (e.projectType != "admin" && e.projectType != "prospection"))
      const variableCostActivitiesEmployee = -parseInt(filteredActivitiesEmployee.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) + fixedCostsFromActivities

      const filteredActivitiesFreelance = filteredActivities.filter(((f) => f.userContract === "FREELANCE")).filter(e => e.projectType != "admin" && e.projectType != "prospection")
      const costActivitiesFreelance = -parseInt(filteredActivitiesFreelance.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0))

      const filteredBanksBusinessIntroducer = banks.filter((e) => e.sub_category === "BUSINESS_INTRODUCER_COST" && !e.walletUserId).filter((f) => areSameMonth(f.month, date));
      const costBusinessIntroducerFromBanks = parseInt(filteredBanksBusinessIntroducer.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const filteredTransactionsBusinessIntroducer = transactions.filter((e) => e.role === "business introducer" && e.amount > 0).filter((f) => areSameMonth(f.date, date));
      const costBusinessIntroducer = -parseInt(filteredTransactionsBusinessIntroducer.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0)) + costBusinessIntroducerFromBanks;

      const inoccupationCosts = -parseInt(filteredActivities.filter(((f) => f.projectName === "le stud")).reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0))

      const filteredTransactions = transactions.filter((e) => e.amount >= 0 && e.role == "lead" && e.status != "not valid").filter((f) => areSameMonth(f.date, date));
      const bonus = -parseInt(filteredTransactions.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      const totalVariableCosts = costBusinessIntroducer + costActivitiesFreelance + costsClient + variableCostActivitiesEmployee + inoccupationCosts + bonus

      const brutMargin = ((revenue + totalVariableCosts) / revenue).toFixed(2) * 100

      // what if
      const filteredActivitiesEmployeePotential = filteredActivities.filter(((f) => f.userContract != "FREELANCE")).filter(e => (e.projectType != "admin" && e.projectType != "prospection"))
      const variableCostActivitiesEmployeePotential = -parseInt(filteredActivitiesEmployeePotential.reduce((acc, f) => acc + (parseInt(f.potentialCost) || 0), 0)) + fixedCostsFromActivities + inoccupationCosts

      const totalPotentialVariableCosts = costBusinessIntroducer + costActivitiesFreelance + costsClient + variableCostActivitiesEmployeePotential + bonus
      const potentialBrutMargin = ((potentialRevenue + totalPotentialVariableCosts) / potentialRevenue).toFixed(2) * 100

      //Concerning general costs
      const filteredAdminCostsFromActivities = filteredActivities.filter((e) => e.projectType === "admin");
      const fixedAdminCostsFromActivities = -parseInt(filteredAdminCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) - inoccupationCosts;

      const filteredProspectionCostsFromActivities = filteredActivities.filter((e) => e.projectType === "prospection");
      const fixedProspectionCostsFromActivities = -parseInt(filteredProspectionCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) ;

      const totalGeneralCosts =  fixedCostsCompany + fixedAdminCostsFromActivities + fixedProspectionCostsFromActivities

      const ebitda = revenue + subventions + totalGeneralCosts + totalVariableCosts

      const margin = (ebitda / (revenue + subventions)).toFixed(2) * 100

      // what if
      const filteredAdminFromActivitiesPotential = filteredActivities.filter(e => (e.projectType === "admin" || e.projectType === "prospection"))
      const generalAdminFromActivitiesPotential = -(parseInt(filteredAdminFromActivitiesPotential.reduce((acc, f) => acc + (parseInt(f.potentialCost) || 0), 0)) + inoccupationCosts)

      const totalGeneralCostsPotential = fixedCostsCompany + generalAdminFromActivitiesPotential

      const potentialEbitda = potentialRevenue + subventions + totalPotentialVariableCosts + totalGeneralCostsPotential
      const potentialMargin = (potentialRevenue + subventions + totalGeneralCostsPotential + totalPotentialVariableCosts)/(potentialRevenue).toFixed(2) * 100

      //going net
      const subventionsFrench = parseInt(0.5 * filteredActivities.reduce((acc, f) => acc + (parseInt(f.subventionFrench) || 0), 0))
      const subventionsDutch = parseInt(0.3 * filteredActivities.reduce((acc, f) => acc + (parseInt(f.subventionDutch) || 0), 0))

      let impotsEstimation = 0
      if (ebitda > 0) impotsEstimation = - Math.round(ebitda * 0.2)

      const profits = ebitda + impotsEstimation + subventionsFrench + subventionsDutch

      const netMargin = (profits / revenue).toFixed(2) * 100

      const potentialProfits = Math.round(potentialEbitda * 0.8 + subventionsFrench + subventionsDutch)
      const potentialNetMargin = (potentialProfits / potentialRevenue).toFixed(2) * 100



      obj[date] = { revenue, 
        potentialRevenue, potentialMaximalRevenue, potentialGoalRevenue, 
        subventions, etp, revenuePerPersonPerMonth,
        costActivitiesFreelance, costBusinessIntroducer, variableCostActivitiesEmployee, costsClient, inoccupationCosts, bonus, totalVariableCosts, brutMargin, 
        variableCostActivitiesEmployeePotential, totalPotentialVariableCosts, potentialBrutMargin, 
        fixedAdminCostsFromActivities, fixedProspectionCostsFromActivities, fixedCostsCompany, totalGeneralCosts, 
        ebitda, margin, potentialEbitda,
        generalAdminFromActivitiesPotential, potentialMargin, 
        subventionsFrench, subventionsDutch, potentialProfits, profits,
        netMargin, potentialNetMargin
      }
    }

    setStats(obj)
    setLoading(false);

  }

  if (loading) return <Loader />;


  return (
    <div className="p-4">
      <div className="flex flex-row justify-between py-1">
          <div className="px-1 text-sm text-gray-600 font-medium">% d'occupation</div>
          <input
            className="rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={percentOccupation}
            onChange={(e) => setPercentOccupation(e.target.value)}
            type="number"
          />
          <div className="px-1 text-sm text-gray-600 font-medium">Annual goal</div>
          <input
            className="rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={annualGoal}
            onChange={(e) => setAnnualGoal(e.target.value)}
            type="number"
          />
          <div className="px-1 text-sm text-gray-600 font-medium">Fab / Max included ?</div>
          <input
            checked={fabMax}
            onChange={(e) => setFabMax(e.target.checked)}
            type="checkbox"
          />
          <div className="px-1 text-sm text-gray-600 font-medium">Bonus</div>
          <input
            checked={bonus}
            onChange={(e) => setBonus(e.target.checked)}
            type="checkbox"
          />
        </div>
      <div className="flex justify-between h-12">
        <table>
          <thead className="bg-gray-200">
            <tr>
              <th></th>
              {DATES.map((e) => {
                const options = { month: 'short', year: 'numeric', };
                const str = e.toLocaleDateString('en-US', options) || ""
                return (
                  <th key={e} className="w-[100px]">{str}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                Revenue
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Revenue</div>
                <div className="text-xs text-gray-500">Sum of all net total invoices</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.revenue || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center text">
                <div>Potential revenue</div>
                <div className="text-xs text-gray-500">Sum of value of all activities that could be charged to a client & linked to that month</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.potentialRevenue || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center text italic">
                <div>Maximal potential revenue</div>
                <div className="text-xs text-gray-500">if everyone was billed except admin & prospection (so Le Stud billed as well)</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.potentialMaximalRevenue || 0
                  return (<td className="border border-gray-200 text-center text italic" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center text italic">
                <div>MaximalPotentialRevenue should be</div>
                <div className="text-xs text-gray-500">Annual Goal / 12 / percentOccupation</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = Math.round(annualGoal/12/percentOccupation) || 0
                  return (<td className="border border-gray-200 text-center text italic" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Total revenue</div>
                <div className="text-xs text-gray-500">Sum of all net total invoices + subventions</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = (stats[e]?.revenue || 0) + (stats[e]?.subventions || 0)
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                Occupation and number of people
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Current occupation %</div>
                <div className="text-xs text-gray-500">potential charged activities / total of activities (including admin & prospection)</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = (stats[e]?.potentialRevenue || 0) / (stats[e]?.potentialMaximalRevenue || 0 - stats[e]?.fixedAdminCostsFromActivities || 0 ) * 100
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>ETP</div>
                <div className="text-xs text-gray-500">from activities, including support functions + freelancers</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.etp || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Revenue per person</div>
                <div className="text-xs text-gray-500">Revenue / ETP</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.revenuePerPersonPerMonth || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Revenue per month we should do if we want to reach the annual Goal</div>
                <div className="text-xs text-gray-500">Annual Goal / 12</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = Math.round(annualGoal/12) || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>How many ETP to reach the annual goal</div>
                <div className="text-xs text-gray-500">GoalMaximalPotentialRevenue / 8000 (revenue per person)</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = Math.round(annualGoal/12/percentOccupation/8000) || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                Variable Costs
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Variable Employee Costs</div>
                <div className="text-xs text-gray-500 text-center">Sum of all costs linked to project activities of employees</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.variableCostActivitiesEmployee || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Inoccupation costs / internal projects</div>
                <div className="text-xs text-gray-500 text-center">le stud costs</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.inoccupationCosts || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Variable Freelance Costs</div>
                <div className="text-xs text-gray-500">Sum of all costs linked to freelance activities, except timeoff activities</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.costActivitiesFreelance || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Bonus</div>
                <div className="text-xs text-gray-500">Bonus</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.bonus || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Total Variable Costs</div>
                <div className="text-xs text-gray-500">Free + employees + business introducer + hosting costs + bonus</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.totalVariableCosts || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
            <td className="border border-gray-200 text-center font-bold">
                <div>Brut margin</div>
                <div className="text-xs text-gray-500"></div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.brutMargin || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
            <td className="border border-gray-200 text-center font-bold text italic">
                <div>Potential Brut margin</div>
                <div className="text-xs text-gray-500">Brut Margin if potentialRevenue</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.potentialBrutMargin || 0
                  return (<td className="border border-gray-200 text-center font-bold text italic" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                Structure Costs
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center text-sm">
                <div>General Costs Admin</div>
                <div className="text-xs text-gray-500">Salaries of employees/freelancers - on time spent on admin</div>
              </td>
              {DATES.map((e, i) => (
                <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                  {(stats[e]?.fixedAdminCostsFromActivities || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center text-sm">
                <div>General Costs Prospection</div>
                <div className="text-xs text-gray-500">Salaries of employees/freelancers - on time spent on prospection</div>
              </td>
              {DATES.map((e, i) => (
                <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                  {(stats[e]?.fixedProspectionCostsFromActivities || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Fixed costs</div>
                <div className="text-xs text-gray-500">Sum of all banks with category GENERAL_COST associated to the company</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.fixedCostsCompany || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Total general costs</div>
                <div className="text-xs text-gray-500">Fixed costs + Activities Admin/prospection costs</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.totalGeneralCosts || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                Results & Key numbers
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Sufficiency</div>
                <div className="text-xs text-gray-500">Revenue we should do every month to cover all of our costs</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = -((stats[e]?.totalGeneralCosts || 0) + (stats[e]?.totalVariableCosts || 0))
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
            <td className="border border-gray-200 text-center font-bold">
                <div>EBITDA</div>
                <div className="text-xs text-gray-500">Profits before corporation taxes and invests (total revenue - general costs - variable costs)</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.ebitda || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >   
            <tr>
            <td className="border border-gray-200 text-center text italic">
                <div>Potential Ebitda</div>
                <div className="text-xs text-gray-500">What if we charged our potentialRevenue</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.potentialEbitda || 0
                  return (<td className="border border-gray-200 text-center text italic" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >   
            <tr>
            <td className="border border-gray-200 text-center font-bold">
                <div>Margin</div>
                <div className="text-xs text-gray-500">EBITDA / Revenue</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.margin || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
            <td className="border border-gray-200 text-center font-bold text italic">
                <div>Potential margin</div>
                <div className="text-xs text-gray-500">What if we charged our potentialRevenue</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.potentialMargin || 0
                  return (<td className="border border-gray-200 text-center font-bold text italic" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>CII/CIR First Estimation</div>
                <div className="text-xs text-gray-500">30% of 50% of the dev activities from Le Collectif</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.subventionsFrench || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>WBSO First Estimation</div>
                <div className="text-xs text-gray-500">30% of 30% of the dev activities from Selego NL</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.subventionsDutch || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
              <div>Net profits</div>
              <div className="text-xs text-gray-500">revenue - costs + subventions - estimated taxes</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.profits || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
              <div>Net Margin</div>
              <div className="text-xs text-gray-500">net profits / revenue</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.netMargin || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
          </tbody>
        </table>
      </div>
    </div >
  );
};

function addSpaceBetweenNumbers(str) {
  return str.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function areSameMonth(a, b) {
  let aDate = new Date(a)
  let bDate = new Date(b)
  return aDate.getMonth() === bDate.getMonth() && aDate.getFullYear() === bDate.getFullYear()
}