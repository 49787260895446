import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import Loader from "../../../components/loader";
import Comments from "../../../components/Comments";

import api from "../../../services/api";

export default ({ cashout, setCashout }) => {
  if (!cashout) return <Loader />;

  const user = useSelector((state) => state.Auth.user);

  const navigate = useNavigate();

  async function onDelete() {
    if (!window.confirm("Are you sure you want to delete this cashout?")) return;
    await api.remove(`/cashout/${cashout._id}`);
    toast.success("Cashout deleted!");
    navigate("/cashout");
  }

  return (
    <div className="flex flex-col gap-4 p-2">
      <div className="flex-1 flex flex-col gap-4">
        <div className="max-w-lg flex w-full items-center gap-x-4">
          <div className="my-4">
            <label className="block text-sm font-medium text-gray-700">Next action Date</label>
            <input
              type="date"
              value={cashout.date ? cashout.date.split("T")[0] : ""}
              onChange={async (e) => {
                const { data } = await api.put(`/cashout/${cashout._id}`, { date: e.target.value });
                setCashout(data);
                toast.success("Collection date updated!");
              }}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="my-4">
            <label className="block text-sm font-medium text-gray-700">Collection Status</label>
            <select
              value={cashout.status || "Late payment"}
              onChange={async (e) => {
                const { data } = await api.put(`/cashout/${cashout._id}`, { status: e.target.value });
                setCashout(data);
                toast.success("Collection status updated!");
              }}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="Timetable sent">Timetable sent</option>
              <option value="Signed timetable">Signed timetable</option>
              <option value="Payment in progress">Payment in progress</option>
              <option value="Late payment">Late payment</option>
              <option value="Late payment in progress">Late payment in progress</option>
              <option value="In recovery">In recovery</option>
            </select>
          </div>
        </div>
        <Comments
          value={cashout.comments}
          onAdd={async (text) => {
            const comments = [...cashout.comments, { text, user_id: user._id, user_name: user.name, user_avatar: user.avatar }];
            const { data } = await api.put(`/cashout/${cashout._id}`, { comments });
            setCashout(data);
            toast.success("Comment added!");
          }}
          onDelete={async (comment_id) => {
            const comments = cashout.comments.filter((c) => c._id !== comment_id);
            const { data } = await api.put(`/cashout/${cashout._id}`, { comments });
            setCashout(data);
            toast.success("Comment deleted!");
          }}
        />
      </div>
      <div className="space-y-4">
        <Invoices cashout={cashout} />
        <Payments cashout={cashout} />
      </div>
      <div>
        <button className="bg-[#0560FD] text-[#fff] py-[12px] px-[22px] h-[48px]	rounded-[10px] text-sm font-medium" onClick={onDelete}>
          delete
        </button>
      </div>
    </div>
  );
};

const Invoices = ({ cashout }) => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post(`/invoice/search`, { projectId: cashout.projectId });
    console.log("data", data);
    setInvoices(data);
  }

  function calculateAction(invoice) {
    if (invoice.received === 'yes') return "-";

    if (invoice.sent !== 'yes') return ("Send Invoice")

    const currentDate = new Date();
    const dueDate = new Date(invoice.dueAt);
    const daysPastDue = (currentDate - dueDate) / (1000 * 60 * 60 * 24);


    if (daysPastDue > 45) return (`If no news at D+45, Mathieu starts (${new Date(dueDate.getTime() + 45 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')})`);

    if (daysPastDue > 30) return (`D+30, message from Seb (${new Date(dueDate.getTime() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')})`);

    if (daysPastDue > 27) return (`D+27, message + échéancier to be negotiated/signed (${new Date(dueDate.getTime() + 27 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')})`);

    if (daysPastDue > 20) return (`D+20, last reminder + message late penalties to apply and negotiate (${new Date(dueDate.getTime() + 20 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')})`);

    if (daysPastDue > 12) return (`D+12, second reminder (${new Date(dueDate.getTime() + 12 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')})`);

    if (daysPastDue > 2) return (`D+2, first reminder (${new Date(dueDate.getTime() + 2 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA')})`);

    return "Waiting for Payment"
  }

  if (invoices.length === 0) return <div>Invoices</div>;

  return (
    <div className="border border-gray-200 p-1 rounded text-sm">
      <div className="flex items-center gap-4">
        <h1 className="">Invoices</h1>
        <h5 className="text-red-950 border border-red-950 rounded py-1 px-2 w-max">
          Total Remaining of all invoices not received: {
            invoices
              .filter((e) => e.received === "no" && e.sent === "yes")
              .reduce((a, b) => a + b.totalRemaining, 0).toFixed(2)} €
        </h5>
      </div>
      <table className="table-auto w-full mt-4 text-left">
        <thead>
          <tr>
            <th>Name</th>
            <th>Sent date</th>
            <th>Due date</th>
            <th>Amount</th>
            <th>Reference</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((e) => (
            <tr key={e._id} className="border-b border-gray-200 hover:bg-gray-100">
              <td className=" max-w-56">
                <a className="underline" href={`/invoice/${e._id}`} target="_blank">
                  {e.index && `#${e.index}- `}
                  {e.name}
                </a>
              </td>
              <td>{(e.sentAt || "").split("T")[0]}</td>
              <td>{(e.dueAt || "").split("T")[0]}</td>
              <td>{e.total.toFixed(2)}</td>
              <td>
                {e.sent === "yes" ? (
                  <span className="border border-green-500 rounded text-green-500 px-1 text-xs truncate">Sent</span>
                ) : (
                  <span className="border border-gray-500 rounded text-gray-500 px-1 text-xs truncate">Not sent</span>
                )}
                {e.received === "yes" ? (
                  <span className="border border-green-500 rounded text-green-500 px-1 text-xs truncate">Received</span>
                ) : e.sent === "yes" ? (
                  <span className="border border-red-500 rounded text-red-500 px-1 text-xs truncate">Not received</span>
                ) : (
                  ""
                )}
              </td>
              <td className="p-1 max-w-72 font-medium">{calculateAction(e)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Payments = ({ cashout }) => {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post(`/bank/search`, { projectId: cashout.projectId, category: "INCOME" });
    setBanks(data.banks);
  }

  if (banks.length === 0) return <div>not payments</div>;

  return (
    <div className="border border-gray-200 p-1 rounded text-sm">
      <h1 className="ms-4">Payments</h1>
      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            <th className="text-left">Name</th>
            <th className="text-left">Date</th>
            <th className="text-left">Amount</th>
          </tr>
        </thead>
        <tbody>
          {banks.map((e) => (
            <tr key={e._id} className="border-b border-gray-200 hover:bg-gray-100">
              <td className="text-left">{e.name}</td>
              <td className="text-left">{e.date}</td>
              <td className="text-left">{e.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
