import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Doughnut } from "react-chartjs-2";
import { Switch } from "@headlessui/react";

export default function NotionStats({ data }) {
  const [includeDone, setIncludeDone] = useState(true);
  const [includeArchived, setIncludeArchived] = useState(false);
  let categoryData = [];
  let costData = [];
  let assigneeData = [];
  let priorityData = [];

  const formatData = async (tickets) => {
    // Repartition by category
    const categoryCount = tickets.reduce((acc, ticket) => {
      if (!ticket.category) {
        acc["No Category"] = (acc["No Category"] || 0) + 1;
      } else {
        acc[ticket.category] = (acc[ticket.category] || 0) + 1;
      }
      return acc;
    }, {});

    // Costs by category, a cost is estimated_price
    const costSum = tickets.reduce((acc, ticket) => {
      acc[ticket.category] = (acc[ticket.category] || 0) + (ticket.estimated_price || 0);
      return acc;
    }, {});

    // Number of bugs by assignee : a ticket has users[] field that contains the assignees
    const assigneeCount = tickets.reduce((acc, ticket) => {
      if (ticket.category === "BUG") {
        ticket.users.forEach((user) => {
          acc[user.user_name] = (acc[user.user_name] || 0) + 1;
        });
      }
      return acc;
    }, {});

    // Repartition by priority
    const priorityCount = tickets.reduce((acc, ticket) => {
      if (!ticket.priority) {
        acc["No Priority"] = (acc["No Priority"] || 0) + 1;
      } else {
        acc[ticket.priority] = (acc[ticket.priority] || 0) + 1;
      }
      return acc;
    }, {});

    categoryData = {
      labels: Object.keys(categoryCount),
      values: Object.values(categoryCount),
    };
    priorityData = {
      labels: Object.keys(priorityCount),
      values: Object.values(priorityCount),
    };
    costData = Object.entries(costSum).map(([key, value]) => ({ category: key, count: value }));
    assigneeData = Object.entries(assigneeCount).map(([key, value]) => ({ assignee: key, count: value }));
  };
  const tickets = data.filter((ticket) => {
    if (!includeDone && ticket.status === "DONE") return false;
    if (!includeArchived && ticket.status === "ARCHIVED") return false;
    return true;
  });
  formatData(tickets);

  console.log("priorityData.labels", priorityData.labels);
  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="flex items-center self-end gap-2">
        <div className="flex items-center gap-2">
          <label className="text-sm font-semibold" htmlFor="includeDone">
            Include Done Tickets
          </label>
          <Switch
            checked={includeDone}
            onChange={() => setIncludeDone(!includeDone)}
            className={`${
              includeDone ? "bg-sky-900" : "bg-gray-300"
            } relative inline-flex h-5 w-9 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
            <span
              aria-hidden="true"
              className={`${
                includeDone ? "translate-x-4" : "translate-x-0"
              } pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
        <div className="flex items-center gap-2">
          <label className="text-sm font-semibold" htmlFor="includeArchived">
            Include Archived Tickets
          </label>
          <Switch
            checked={includeArchived}
            onChange={() => setIncludeArchived(!includeArchived)}
            className={`${
              includeArchived ? "bg-sky-900" : "bg-gray-300"
            } relative inline-flex h-5 w-9 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
            <span
              aria-hidden="true"
              className={`${
                includeArchived ? "translate-x-4" : "translate-x-0"
              } pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      </div>
      <div className=" grid grid-cols-2 gap-4">
        <DoughnutChart title="Ticket Distribution by Category" labels={categoryData.labels} dataValues={categoryData.values} index={1} />
        <DoughnutChart title="Ticket Distribution by Priority" labels={["P0", "P1", "P2", "P3", "No Priority"]} dataValues={priorityData.values} index={2} />
        <BarChart title="Ticket Cost by Category" data={costData} indexBy="category" axisLeftLegend="Cost (€)" colors={{ scheme: "paired" }} />
        <BarChart title="Number of Bugs by Assignee" data={assigneeData} indexBy="assignee" axisLeftLegend="Count" colors={{ scheme: "pastel1" }} />
      </div>
    </div>
  );
}

function BarChart({ title, data, indexBy, axisLeftLegend, colors }) {
  console.log(data);
  if (!data.length)
    return (
      <div>
        <h3 className="text-lg font-semibold mb-4">{title}</h3>
        <div className="p-4 flex items-center justify-center h-96 bg-white rounded-md">No data available</div>
      </div>
    );
  return (
    <div className="my-4">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <div style={{ height: 300 }}>
        <ResponsiveBar
          data={data}
          keys={["count"]}
          indexBy={indexBy}
          margin={{ top: 50, right: 30, bottom: 80, left: 50 }}
          padding={0.3}
          colors={colors}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 35,
            legend: indexBy.charAt(0).toUpperCase() + indexBy.slice(1),
            legendPosition: "middle",
            legendOffset: 70,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: axisLeftLegend,
            legendPosition: "middle",
            legendOffset: -45,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltip={({ value, indexValue }) => <div className={`p-2 rounded-md border bg-white text-xs`}>{`${indexValue}: ${value}`}</div>}
        />
      </div>
    </div>
  );
}

function DoughnutChart({ title, labels, dataValues, index }) {
  const total = dataValues.reduce((acc, value) => acc + value, 0);
  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF9F40", "#4BC0C0", "#9966FF", "#FF9F40", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "#ffffff",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          generateLabels: function (chart) {
            const legend = createLegendLabels(chart);
            const query = `.chart-legend-${index}`;
            const legendContainer = document.querySelector(query);
            if (legendContainer) {
              legendContainer.innerHTML = legend;
            }
            return [];
          },
        },
      },
    },
    cutout: "60%",
  };

  const createLegendLabels = (chart) => {
    const { data } = chart;
    return data.labels
      .map(
        (label, index) =>
          `<span class="${label}" >
            <span style="background-color:${data.datasets[0].backgroundColor[index]};width:12px;height:12px;display:inline-block;margin-right:8px;"></span>
            ${label}: ${data.datasets[0].data[index]} (${((data.datasets[0].data[index] / total) * 100).toFixed(2)}%)
          </span>`,
      )
      .join("<br/>");
  };

  return (
    <div className="my-4">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <div className="flex">
        <div className={`chart-legend-${index} text-xs flex flex-col items-start my-auto`}></div>
        <div className="chart-container w-60 mx-auto">
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  );
}
