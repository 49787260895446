import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { RiRobot2Fill } from "react-icons/ri";
import { FiGithub } from "react-icons/fi";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import Loader from "../../components/loader";
import { classNames } from "../../utils";
import API from "../../services/api";

const View = () => {
  const { id } = useParams();
  const [review, setReview] = useState();
  const [tab, setTab] = useState("INFORMATION");

  useEffect(() => {
    get();
  }, [id]);

  const get = async () => {
    try {
      const { data, ok } = await API.get(`/ai-review/${id}`);
      if (!ok) return toast.error("Error fetching review's data.");
      setReview(data);
    } catch (e) {
      toast.error("Error fetching review's data.");
    }
  };

  if (!review) return <Loader />;

  return (
    <div className="p-5">
      <nav className="bg-white rounded-md flex gap-1 w-full border border-neutral-300 p-1 justify-between items-center">
        <div className="flex items-center gap-1">
          <TabItem title="Information" tab="INFORMATION" onClick={() => setTab("INFORMATION")} active={tab === "INFORMATION"} />
          <TabItem title="Raw Data" tab="RAW_DATA" onClick={() => setTab("RAW_DATA")} active={tab === "RAW_DATA"} />
        </div>
        <a
          href={`https://github.com/${review.owner}/${review.repo_name}/pull/${review.pr_number}`}
          className="w-8 h-8 border rounded-md border-gray-700 flex justify-center items-center hover:bg-gray-200 mr-3"
          target="_blank">
          <FiGithub className="text-gray-700 text-lg" />
        </a>
      </nav>

      <div className="mt-5">
        {tab == "INFORMATION" && <Information data={review} setData={setReview} />}
        {tab == "RAW_DATA" && <Rawdata data={review} />}
      </div>
    </div>
  );
};

export default View;

const Information = ({ data, setData }) => {
  return (
    <div className="">
      <div className="flex justify-between">
        <a href="https://github.com/apps/selego-code-review" target="_blank" className="flex items-center gap-3 mb-2 w-fit">
          <div className="w-14 h-14 rounded-full flex justify-center items-center border bg-primary-50">
            <RiRobot2Fill className="text-2xl text-primary-800" />
          </div>
          <div>
            <p className="text-xl font-medium">sele-spresso ☕️</p>
            <p className="text-xs text-gray-600">selego code reviewer</p>
          </div>
        </a>
        <a href={data.comment_link} target="_blank" className="hover:bg-gray-100 px-3 py-1 text-sm border rounded-md h-fit">
          View comment in Github
        </a>
      </div>

      <SyntaxHighlighter language="markdown" style={vscDarkPlus}>
        {data?.review_content}
      </SyntaxHighlighter>

      <div className="mt-8">
        <div className="flex justify-between my-5 items-center">
          <div>
            <p className="font-medium flex gap-3">Reviewed Code :</p>
            <p className="text-xs text-gray-600">
              author :{" "}
              <a href={`https://github.com/${data?.author}`} target="_blank" className="hover:underline">
                {data?.author}
              </a>
            </p>
          </div>

          <a href={data.file_link} target="_blank" className="hover:bg-gray-100 px-3 py-1 text-sm border rounded-md h-fit">
            View code in Github
          </a>
        </div>

        <SyntaxHighlighter language="markdown" style={vscDarkPlus}>
          {data?.code_content}
        </SyntaxHighlighter>
      </div>

      <div className="mt-8">
        <p className="font-medium flex gap-3">What do you think about the bot's review?</p>

        <div className="flex justify-between my-5 items-center w-full">
          <textarea
            type="text"
            className="border rounded-md p-2 w-full"
            placeholder="What do you think about the bot's review?"
            value={data.human_touch}
            onChange={(e) => setData({ ...data, human_touch: e.target.value })}
          />
        </div>

        <button
          onClick={async () => {
            const { ok } = await API.put(`/ai-review/${data._id}`, { human_touch: data.human_touch });
            if (!ok) return toast.error("Error while saving data");
            toast.success("Data saved successfully");
          }}
          className="bg-primary-500 text-white rounded-md px-4 py-2">
          Save
        </button>
      </div>
    </div>
  );
};

const Rawdata = ({ data }) => {
  return (
    <div className="p-8 text-sm bg-slate-800 text-white rounded-lg mt-4 border shadow-sm">
      <pre className="break-all whitespace-pre-wrap"> {JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button onClick={onClick} className={classNames("relative rounded py-2 px-4 flex items-center gap-2", active ? "blue-btn" : "transparent-btn")}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
