import React from "react";
import Modal from "../../../../../components/modal";
import { useState } from "react";
import api from "../../../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import RichTextEditorSun from "../../../../../components/RichTextEditor";

export default function ({ isOpen, onClose, projectId, setNotions }) {
  const [choice, setChoice] = useState("init");
  const [value, setValue] = useState({ project_id: projectId });

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const handleCreate = async () => {
    try {
      toast.loading("Catégorisation du ticket...");
      // Changer le message au bout d'une seconde
      setTimeout(() => {
        toast.dismiss();
        toast.loading("Priorisation du ticket...");
      }, 1000);
      // Changer le message après 2 secondes
      setTimeout(() => {
        toast.dismiss();
        toast.loading("Traduction du titre...");
      }, 2000);
      const { data } = await api.post("/notion", value);
      setNotions((prev) => [data, ...prev]);
      setValue({ project_id: projectId });
      onClose();
      query.set("task_modal_id", data._id);
      navigate({ search: query.toString() });
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {choice === "init" && <FormChoice setChoice={setChoice} onCreate={handleCreate} onChange={(e) => setChoice(e.target.value)} value={value} setValue={setValue} />}
    </Modal>
  );
}

const FormChoice = ({ value, setValue, onCreate }) => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center max-w-lg mx-auto p-3">
      <h3 className="mb-3">Create new task : Describe the task</h3>
      <RichTextEditorSun
        buttonList={[
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor"],
          ["align", "list", "lineHeight"],
          ["outdent", "indent"],
          ["table", "horizontalRule", "link", "image", "video"],
          ["removeFormat"],
        ]}
        values={value.description}
        options={{ minHeight: "180px" }}
        onChange={(e) => setValue({ ...value, description: e })}
      />
      <div className="my-2" />
      <button disabled={!value.description} onClick={onCreate} className="blue-btn p-6 w-full">
        Create task
      </button>
    </div>
  );
};

// const BugForm = ({ notion, onChange, goBack, onCreate }) => {
//   const [context, setContext] = useState({
//     web: false,
//     mobile: false,
//     iOS: false,
//     android: false,
//     firefox: false,
//     safari: false,
//     chrome: false,
//     other: false,
//   });

//   const handleContextChange = (e) => {
//     const { name, checked } = e.target;
//     setContext((prevContext) => ({
//       ...prevContext,
//       [name]: checked,
//     }));
//   };

//   const handleCreate = () => {
//     onCreate(notion);
//   };

//   return (
//     <div className="flex flex-col gap-4 p-2">
//       <div className="flex flex-row gap-4 items-center p-2">
//         <IoIosArrowBack onClick={goBack} className="cursor-pointer" />
//         <h2 className="font-bold text-xl">Bug</h2>
//       </div>

//       {/* Contexte */}
//       <div>
//         <label className="font-semibold">Contexte</label>
//         <div className="flex flex-col gap-2 mt-2">
//           <div>
//             <input type="checkbox" name="web" checked={context.web} onChange={handleContextChange} /> Web
//             <div className="flex flex-row ml-4 gap-2 items-center">
//               <input type="checkbox" name="firefox" checked={context.firefox} onChange={handleContextChange} /> Firefox
//               <input type="checkbox" name="safari" checked={context.safari} onChange={handleContextChange} /> Safari
//               <input type="checkbox" name="chrome" checked={context.chrome} onChange={handleContextChange} /> Chrome
//               <input type="checkbox" name="other" checked={context.other} onChange={handleContextChange} /> Autre
//             </div>
//           </div>
//           <div>
//             <input type="checkbox" name="mobile" checked={context.mobile} onChange={handleContextChange} /> Application mobile
//             <div className="flex flex-row ml-4 gap-2 items-center">
//               <input type="checkbox" name="iOS" checked={context.iOS} onChange={handleContextChange} /> iOS
//               <input type="checkbox" name="android" checked={context.android} onChange={handleContextChange} /> Android
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Que s’est-il passé ? */}
//       <div>
//         <label className="font-semibold">Que s’est-il passé ?</label>
//         <textarea
//           rows="4"
//           className="border w-full p-2 rounded-lg border-blue-600"
//           placeholder="Décrivez en détail les étapes que vous ont mené au problème."
//           onChange={onChange}
//           name="issueDescription"
//         />
//       </div>

//       {/* Comportement attendu */}
//       <div>
//         <label className="font-semibold">Quel était le comportement attendu ?</label>
//         <textarea
//           rows="4"
//           className="border w-full p-2 rounded-lg border-blue-600"
//           placeholder="Expliquez ce qui aurait dû être le résultat attendu de votre action."
//           onChange={onChange}
//           name="expectedBehavior"
//         />
//       </div>

//       {/* Capture d’écran ou vidéo */}
//       <div>
//         <label className="font-semibold">Capture d’écran ou vidéo</label>
//         <input type="file" className="w-full border p-2 rounded-lg border-blue-600" accept="image/*,video/*" onChange={onChange} name="screenshotOrVideo" />
//         <div>Hello</div>
//       </div>
//       <div className="flex justify-end mr-6">
//         <button onClick={handleCreate} className="blue-btn p-6">
//           Créer
//         </button>
//       </div>
//     </div>
//   );
// };

// const NewFeatureForm = ({ notion, onChange, goBack, onCreate }) => {
//   const handleCreate = () => {
//     onCreate(notion);
//   };

//   return (
//     <div className="flex flex-col gap-4 p-2">
//       <div className="flex flex-row gap-4 items-center p-2">
//         <IoIosArrowBack onClick={goBack} className="cursor-pointer" />
//         <h2 className="font-bold text-xl">Iteration</h2>
//       </div>
//       <label className="font-semibold">Dites-nous en plus !</label>
//       <textarea
//         placeholder="Décrivez la fonctionnalité que vous souhaiteriez ajouter à votre produit."
//         className="w-full border p-2 rounded-lg border-blue-600"
//         onChange={onChange}
//         name="featureDescription"></textarea>

//       <label className="font-semibold">Captures d’écran / Vidéos</label>
//       <input type="file" className="w-full border p-2 rounded-lg border-blue-600" accept="image/*,video/*" onChange={onChange} name="featureMedia" />
//       <div className="flex justify-end mr-6">
//         <button onClick={handleCreate} className="blue-btn p-6">
//           Créer
//         </button>
//       </div>
//     </div>
//   );
// };

// const CreateIterationForm = ({ notion, onChange, goBack, onCreate }) => {
//   const handleCreate = () => {
//     onCreate(notion);
//   };
//   return (
//     <div className="flex flex-col gap-4 p-2 ">
//       <div className="flex flex-row gap-4 items-center p-2">
//         <IoIosArrowBack onClick={goBack} className="cursor-pointer" />
//         <h2 className="font-bold text-xl">New feature</h2>
//       </div>
//       <label className="font-semibold">Contexte</label>
//       <input
//         type="text"
//         placeholder="Nommez l’écran et/ou la section concernée"
//         className="w-full border p-2 rounded-lg border-blue-600"
//         onChange={onChange}
//         name="iterationContext"
//       />

//       <label className="font-semibold">Dites-nous en plus !</label>
//       <textarea
//         placeholder="Décrivez ce que vous souhaitez améliorer par rapport à la fonctionnalité existante."
//         className="w-full border p-2 rounded-lg border-blue-600"
//         onChange={onChange}
//         name="iterationDescription"></textarea>

//       <label className="font-semibold">Captures d’écran / Vidéos</label>
//       <input type="file" className="w-full border p-2 rounded-lg border-blue-600" accept="image/*,video/*" onChange={onChange} name="iterationMedia" />
//       <div className="flex justify-end mr-6">
//         <button onClick={handleCreate} className="blue-btn p-6">
//           Créer
//         </button>
//       </div>
//     </div>
//   );
// };
