import React, { useEffect, useState } from "react";

export default ({ value, onChange }) => {
  const STATUS = [
    {
      name: "Active",
      value: false,
    },
    {
      name: "Archived",
      value: true,
    },
  ];

  return (
    <div>
      <select
        className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
        name="archived"
        typeof="boolean"
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          onChange(JSON.parse(e.target.value));
        }}>
        {STATUS?.map((e) => {
          return (
            <option key={e.value} value={e.value}>
              {e.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
