import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../../services/api";
import Modal from "../../../../components/modal";
import Table from "../../../../components/Table";
import Paginator from "../../../../components/Paginator";

import ContactModal from "../../component/contactModal";

import SelectCampaign from "../../component/SelectCampaign";

export default ({ campaign }) => {
  const [filters, setFilters] = useState({ page: 1, events: [] });
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {

    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;

    newFilters.campaign_id = campaign._id;

    if (filters.events && filters.events.length) newFilters.events = filters.events;
    if (filters.search) newFilters.search = filters.search;

    const { data, total, ok } = await api.post("/s_contact/search", newFilters);
    if (!ok) return toast.error("Error fetching contacts");
    setContacts(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search value={filters.search} onChange={(search) => setFilters({ ...filters, search })} />
        <SelectEvents
          value={filters.events}
          onChange={(e) => {
            console.log("events", e);
            setFilters({ ...filters, events: e });
          }}
        />
        <CreateContact onDone={fetch} />
      </div>
      <div>{total} items</div>
      <ContactModal contact={selectedContact} setContact={() => setSelectedContact(null)} />
      <Table
        total={total}
        header={[
          { title: "Name", key: "first_name" },
          { title: "Job", key: "job_titles" },
          { title: "Company", key: "company" },
          { title: "Social", key: "social" },
          { title: "Events", key: "events" },
          { title: "Campaign", key: "campaign" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {contacts.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedContact(item);
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{`${item.first_name} ${item.last_name}`}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.job_title}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.company_name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex space-x-2">
                  {item.linkedin && (
                    <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-5" />
                    </a>
                  )}
                  {item.email && (
                    <a href={`mailto:${item.email}`}>
                      <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-5" />
                    </a>
                  )}
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex flex-wrap gap-1">
                  {item.events.map((e) => (
                    <span key={e} className="bg-gray-200 text-xs px-2 py-1 rounded-md">
                      {e}
                    </span>
                  ))}
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {item.campaigns.map((e) => {
                  return (
                    <span key={e._id} className="bg-gray-200 text-xs px-2 py-1 rounded-md mr-1">
                      {e.name}
                    </span>
                  );
                })}
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.created_at}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ value, onChange }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(value || "");
  }, [value]);

  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => onChange(str)}>
        Search
      </button>
    </div>
  );
};

const CreateContact = ({ onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onSubmit() {
    try {
      const res = await api.post("/s_contact", values);
      if (!res.ok) throw res;
      toast.success("Client created");

      setOpen(false);
      onDone();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }
  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={() => setOpen(true)}>
        Create a contact
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div>
          <div className="flex flex-col justify-between flex-wrap">
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium	">Name</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="name"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium	">Email</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="email"
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="w-full flex justify-end">
          <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
            Invite
          </button>
        </div>
      </Modal>
    </div>
  );
};

// CONTACTED: "CONTACTED",
// DELIVERED: "DELIVERED",
// NOT_DELIVERED: "NOT_DELIVERED",
// OPENED: "OPENED",
// ACCEPTED: "ACCEPTED",
// REPLIED: "REPLIED",
// CLICKED: "CLICKED",
// INTERESTED: "INTERESTED",
// NOT_INTERESTED: "NOT_INTERESTED",
// UNSUBSCRIBED: "UNSUBSCRIBED",
// CONVERSIONS: "CONVERSIONS",
// ERROR: "ERROR",
// OTHER: "OTHER",

const SelectEvents = ({ value, onChange }) => {
  const EVENTS = [
    "CONTACTED",
    "DELIVERED",
    "NOT_DELIVERED",
    "OPENED",
    "ACCEPTED",
    "REPLIED",
    "CLICKED",
    "INTERESTED",
    "NOT_INTERESTED",
    "UNSUBSCRIBED",
    "CONVERSIONS",
    "ERROR",
    "OTHER",
  ];

  const handleSelect = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    onChange(selectedValues);
  };

  return (
    <div className="w-full">
      <select
        value={value}
        onChange={handleSelect}
        className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        <option key={null} value={null}>
          ALL
        </option>
        {EVENTS.map((event) => (
          <option key={event} value={event}>
            {event.replace("_", " ")}
          </option>
        ))}
      </select>
    </div>
  );
};
