import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Tree, TreeNode } from "react-organizational-chart";
import { Link } from "react-router-dom";

import api from "../../services/api";

export default ({}) => {
  const [tree, setTree] = useState(null);
  const [other, setOther] = useState([]);

  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    try {
      let arr = [];
      const res = await api.post(`/user/search`, { role: ["admin", "normal"], contracts: ["VIE", "CDD", "CDI", "APPRENTICESHIP", "INTERNSHIP"], status: "active" });
      arr.push(...res.data.users);
      const d = new Date();
      d.setMonth(d.getMonth() - 2);
      const res1 = await api.post(`/user/search`, { contracts: ["FREELANCE"], payment_type: "MONTHLY", status: "active" });
      arr.push(...res1.data.users);
      const res2 = await api.post(`/user/search`, { role: "applicant", statuses: ["HIRE"] });
      arr.push(...res2.data.users);
      const nf = arr.filter((e) => !e.manager_id);
      setOther(nf);
      arr = arr.map((e) => ({ ...e, children: [] }));
      const items = arr.reduce((acc, e) => {
        acc[e._id] = { ...e, children: [] };
        return acc;
      }, {});
      arr = arr.reduce((acc, e) => {
        if (e.manager_id && items[e.manager_id]) {
          items[e.manager_id].children.push(items[e._id]);
        } else {
          acc.push(items[e._id]);
        }
        return acc;
      }, []);

      setTree(arr);
    } catch (error) {
      console.error(error);
      toast.error("Error while fetching data");
    }
  }

  if (!tree) return <div>Loading</div>;

  return (
    <div className="p-4 h-full">
      <div className="flex justify-between h-12">
        <Tree label={<div>Root</div>}>
          <DisplayTree
            tree={{ children: tree }}
            onClick={(i) => {
              query.set("user_modal_id", i._id);
              navigate({ search: query.toString() });
            }}
          />
        </Tree>
      </div>
    </div>
  );
};

const DisplayTree = ({ tree, onClick }) => {
  if (!tree.children.length) return <></>;

  return (
    <>
      {tree.children.map((e) => (
        <TreeNode label={<UserCard user={e} onClick={onClick} />}>
          <DisplayTree tree={e} onClick={onClick} />
        </TreeNode>
      ))}
    </>
  );
};

const UserCard = ({ user, onClick }) => {
  const [activities, setActivities] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsleads, setProjectsLeads] = useState([]);

  useEffect(() => {
    getActivities();
    getProjects();
  }, []);

  async function getActivities() {
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 2);
    const res = await api.post(`/activity/search`, { userId: user._id, dateFrom });
    const arr = [];
    for (let i = 0; i < res.data.length; i++) {
      const a = res.data[i];
      const f = arr.findIndex((e) => e.projectId == a.projectId);
      if (f !== -1) continue;
      arr.push(a);
    }
    setActivities(arr);
  }

  async function getProjects() {
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 2);
    const res = await api.post(`/project/search`, { responsible_id: user._id, last_activity_at: { gt: dateFrom } });
    setProjects(res.data.projects);

    const res1 = await api.post(`/project/search`, { lead_id: user._id, last_activity_at: { gt: dateFrom } });
    setProjectsLeads(res1.data.projects);
  }

  const count = user.children.length ? `(${user.children.length})` : "";

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md min-w-[320px]" onClick={(e) => onClick(user)}>
      <div className="flex items-center p-4">
        <img src={user.avatar} className="w-10 h-10 rounded-full" />
        <div className="ml-4">
          <div className="text-lg font-semibold">{`${user.name} ${count}`}</div>
          <div className="text-sm text-gray-500">{user.job_title}</div>
          <div className="text-sm text-gray-500">{user.contract}</div>
          <div>
            <div>Responsible</div>
            <div className="flex-col">
              {projects.map((e) => {
                return <img src={e.logo} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
              })}
            </div>
          </div>
          <div>
            <div>Leads</div>
            <div className="flex-col">
              {projectsleads.map((e) => {
                return <img src={e.logo} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
              })}
            </div>
          </div>
          <div>
            <div>Contribute</div>
            <div className="flex-col">
              {activities.map((e) => {
                return <img src={e.projectLogo} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
