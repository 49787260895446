import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { HiMagnifyingGlass } from "react-icons/hi2";
import DebounceInput from "react-debounce-input";
import Board from "react-trello";
import { useNavigate, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import SelectUsers from "../../components/selectUser";

import { RiMoneyEuroCircleFill } from "react-icons/ri";

import api from "../../services/api";
import FolkModal from "./folkModal";
import Loose from "./folkModal/loose";

export const categoryExplanations = {
  PROSPECTION: "Various contact methods (mail, Linkedin, phone/Whatsapp) and establishing a connection.",
  QUALIFIED: "Understanding the prospect's problem, knowing what to sell, and being in contact with the right people.",
  DISCOVERY: "The prospect describes their problem, budget, and timing, and a clear identification of their needs.",
  PROPOSAL: "Sending a proposal.",
  NEGOTIATION: "Modifying price/timing.",
  CLOSING: "Getting the go-ahead but lacking the signature or project not started.",
  WON: "Contract signed, and the project has started.",
  LOST: "Client rejection or no communication for three weeks.",
};

export const percentageSebHappy = {
  PROSPECTION: "10,00%",
  QUALIFIED: "20,00%",
  DISCOVERY: "50,00%",
  PROPOSAL: "75,00%",
  NEGOTIATION: "80,00%",
  CLOSING: "90,00%",
  WON: "100,00%",
  LOST: "0,00%",
};

const INACTIVE = new Date(new Date() - 60 * 24 * 60 * 60 * 1000);

export default () => {

  const [folks, setFolks] = useState([]);
  const [selectedFolk, setSelectedfolk] = useState(null);
  const [filters, setFilters] = useState({ showAll: false });
  const [lostModalOpen, setLostModalOpen] = useState(false);
  const [lostModalFolk, setSelectedLostFolk] = useState(null);

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    const newFilters = {};
    if (!filters.showAll) newFilters.updated_at = INACTIVE;
    if (filters.user) newFilters.user_id = filters.user;
    newFilters.search = filters.search;
    newFilters.contactType = "CLIENT";
    newFilters.status = ["DISCOVERY", "PROPOSAL", "NEGOTIATION", "CLOSING"];

    const { data } = await api.post("/folk/search", newFilters);
    setFolks(data);
  }

  return (
    <div className="bg-white h-full">
      <FolkModal
        onClose={() => {
          setSelectedfolk(null);
          fetch();
        }}
        contact={selectedFolk}
      />
      <Loose open={lostModalOpen} setOpen={setLostModalOpen} values={lostModalFolk} setValues={setSelectedLostFolk} onClose={fetch} />
      <div className="flex justify-between mb-4">
        <div className="flex space-x-4">
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Search"}</div>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 top-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                name="search"
                id="search"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search"
                value={filters.search}
                onChange={(e) => {
                  e.persist();
                  setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
                }}
              />
            </div>
          </div>

          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Owner"}</div>
            <SelectUsers value={filters.user} userName={filters.user?.name} onChange={(v) => setFilters({ ...filters, user: v?._id })} />
          </div>
          <div className="flex">
            <input type="checkbox" checked={filters.showAll} onChange={() => setFilters({ ...filters, showAll: !filters.showAll })} className="mr-2" />
            <label className="flex text-sm text-gray-600">Show all items</label>
          </div>
        </div>
        <Create afterCreate={() => fetch()} />
      </div>

      <Board
        components={{ Card }}
        style={{ backgroundColor: "#F5F6FC", overflow: "scroll", height: "100%" }}
        laneStyle={{ backgroundColor: "#e5e6eC", overflow: "scroll" }}
        hideCardDeleteIcon
        data={{
          lanes: ["DISCOVERY", "PROPOSAL", "NEGOTIATION", "CLOSING"].map((category) => ({
            id: category,
            title: (
              <div>
                <h2 className="text-base">
                  {category}&nbsp;{percentageSebHappy[category]}
                </h2>
                <div className="text-gray-600 text-sm font-normal flex gap-1 items-center">
                  <RiMoneyEuroCircleFill />
                  {folks
                    .filter((folk) => folk.status === category)
                    .reduce((prev, curr) => prev + (curr.budget || 0), 0)
                    .toLocaleString()}
                  &nbsp;-&nbsp;
                  {folks.filter((folk) => folk.status === category).length}&nbsp;folks
                </div>
                <div className="max-w-[250px] overflow-hidden overflow-wrap text-sm font-normal text-gray-600 whitespace-pre-line">{categoryExplanations[category]}</div>
              </div>
            ),
            cards: folks
              .filter((folk) => folk.status === category)
              .sort((a, b) => {
                const dateA = new Date(a.last_updated_at);
                const dateB = new Date(b.last_updated_at);
                return dateB - dateA;
              })
              .map((folk) => ({ folk, id: folk._id })),
          })),
        }}
        onCardClick={(id) => {
          const folk = folks.find((f) => f._id === id);
          setSelectedfolk(folk);
          query.set("folk_modal_id", folk._id);
          navigate({ search: query.toString() });
        }}
        onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => {
          if (toLaneId === "LOST") {
            setLostModalOpen(true);
            setSelectedLostFolk(folks.find((f) => f._id === cardId));
            return;
          }

          const folk = folks.find((f) => f._id === cardId);
          folk.status = toLaneId;
          api.put(`/folk/${cardId}`, folk);
          toast.success("folk moved successfully");
        }}
      />
    </div>
  );
};

const Create = ({ afterCreate }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onCreate() {
    const { res, ok } = await api.post("/folk", values);
    if (!ok) return toast.error("Error");
    setOpen(false);
    toast.success("Created!");
    afterCreate();
  }

  return (
    <>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create a Folk
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center items-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md" onClick={(e) => e.stopPropagation()}>
            <React.Fragment>
              <div className="mb-4">
                <label className="block mb-2">Company's name</label>
                <input type="text" name="name" value={values.company} onChange={(e) => setValues({ ...values, company: e.target.value })} className="w-full p-2 border rounded" />
              </div>
              <button type="submit" className="btn btn-primary mt-4" onClick={onCreate}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Card = ({ folk, onClick }) => {
  const type = folk.type === "UPSELL" ? "U" : "C";

  return (
    <div className="w-[250px]">
      <div className="p-2 my-2 flex flex-col bg-gray-50 rounded shadow-md cursor-pointer" onClick={onClick}>
        <div className="flex justify-between space-x-2">
          {(folk.first_name || folk.last_name) && <div className="text-sm text-gray-500">{`${folk.first_name} ${folk.last_name}`}</div>}
          <span className={`ml-2 border px-2 text-xs ${type === "C" ? "border-purple-200 bg-purple-50 text-purple-800" : "border-orange-200 bg-orange-50 text-orange-800"}`}>
            {type}
          </span>
        </div>
        <div>{folk.company || `${folk.first_name} ${folk.last_name}`}</div>
        {folk.budget && <div>{`${formatNumber(folk.budget)}€`}</div>}
        <div className="text-sm text-gray-500">{folk.domain}</div>
        <div className="text-sm text-gray-500">{`${folk.entity ?? ""}  ${folk.team ?? ""}`}</div>
        {folk.project_id && (
          <div className="flex ">
            <div className="mr-2">Project : </div>
            <img src={folk.project_logo} className="w-6 h-6 rounded-full mr-2" />
            <div>{folk.project_name}</div>
          </div>
        )}
        <div className="text-sm text-gray-500">{folk.start && "STARTS: " + moment(folk.start).format("DD/MM/YYYY, HH:mm")}</div>
        <div className="text-sm text-gray-500">{folk.end_at && "DEADLINE: " + moment(folk.end_at).format("DD/MM/YYYY, HH:mm")}</div>
        <hr className="mt-2 mb-2" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <img src={folk.user_avatar} className="w-8 h-8 rounded-full" alt={folk.user_name} />
            {folk.people.map((e) => (
              <img key={e.user_avatar} className="h-8 w-8 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
            ))}
          </div>
          <div>
            <div className="text-xs text-gray-500">{`Updated : ${moment(folk.updated_at).format("DD/MM/YYYY")}`}</div>
            <div className="text-xs text-gray-500">{`Created : ${moment(folk.created_at).format("DD/MM/YYYY")}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const Item = ({ folk, onClick }) => {
//   const label = folk.company || `${folk.first_name} ${folk.last_name}`;
//   const budget = folk.budget;
//   const type = folk.type === "UPSELL" ? "U" : "C"
//   return (
//     <div onClick={onClick} className="w-full flex justify-between items-center gap-2 odd:bg-gray-100 p-2 cursor-pointer">
//       <div>
//         <span className="text-sm">{label}</span>
//         <span className={`ml-2 border px-2 text-xs ${type === "C" ? "border-purple-200 bg-purple-50 text-purple-800" : "border-orange-200 bg-orange-50 text-orange-800"}`}>{type}</span>
//       </div>
//       <span className="border border-purple-200 bg-purple-50 text-purple-800 px-2 text-xs">{budget ? `${budget}€` : "N/A"}</span>
//     </div>
//   )
// }

function formatNumber(num) {
  return (num || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
