import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import ProgressBar from "../../../../../components/ProgressBar";

export default ({ project }) => {
  const [budgets, setBudgets] = useState([]);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const { data } = await api.post("/budget/search", { projectId: project._id, status: "active" });
    setBudgets(data);
  }

  if (!budgets) return <div>Loading</div>;

  return (
    <div className="flex flex-row gap-4 mb-2">
      {budgets.map((e) => {
        return <Item key={e._id} item={e} />;
      })}
    </div>
  );
};

const Item = ({ item }) => {
  const max = item.amount;
  const total = item.amountUsed;
  const percentage = (total / max) * 100;

  return (
    <div className="flex">
      <div className="cursor-pointer flex items-center justify-between p-2  items-center flex-col rounded-md border border-gray-200 ">
        <div className="text-sm font-semibold mb-1">{item.name}</div>
        <ProgressBar percentage={percentage} max={max} value={total} services={0} expenses={0} legendSymmbol={"€"} />
      </div>
    </div>
  );
};
