import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import toast from "react-hot-toast";
import api from "../../../../services/api";

export default ({ project, filters }) => {
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    getData();
  }, [filters.name, filters.date]);

  const getData = async () => {
    try {
      const { ok, data } = await api.post(`/bank/groupByMonth`, { projectId: project._id, groupBy: "name", category: "GENERAL_COST", name: filters.name, date: filters.date });

      if (!ok) return toast.error("Error while fetching activities");
      setExpenses(data);
    } catch (error) {
      toast.error("Error while fetching activities");
    }
  };

  if (!expenses) return null;

  const names = expenses
    .map((a) => Object.keys(a).filter((k) => k !== "month"))
    .flat()
    .filter((v, i, a) => a.indexOf(v) === i);

  const colorPalette = ["#5d5fef", "#a5a6f6", "#7d3c98", "#6c3483", "#5b2c6f"];

  const getColor = (bar) => colorPalette[names.indexOf(bar.id)];

  return (
    <div className="h-[32rem] p-4">
      <ResponsiveBar
        data={expenses}
        keys={names}
        indexBy="month"
        margin={{ top: 50, right: 160, bottom: 80, left: 90 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        enableLabel={false}
        colors={getColor}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Expenses",
          legendPosition: "middle",
          legendOffset: 42,
          format: (value) => {
            const date = new Date(`${value}-01`);
            return date.toLocaleString("en-US", { month: "long" });
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 20,
          tickRotation: 0,
          legend: "Cost",
          legendPosition: "middle",
          legendOffset: -80,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Expenses"
        barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      />
    </div>
  );
};
