import React, { Fragment, useEffect, useState } from "react";
import { Combobox as HLCombobox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine, RiCheckLine } from "react-icons/ri";

const Combobox = ({
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  nullable = true,
  placeholder = "Sélectionner une option",
  width = "w-64",
  position = "left-0",
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
  match = (o, s) => getLabel(o).toLowerCase().includes(s.toLowerCase()),
  displayValue = (o) => (multiple && o[0] ? getLabel(o[0]) + (o.length > 1 ? ` +${o.length - 1}` : "") : getLabel(o)),
  onInputChanged = null,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (onInputChanged) {
      onInputChanged(e);
    } else {
      const filteredOptions = options.filter((option) => match(option, value));
      setFilteredOptions(filteredOptions);
    }
  };

  return (
    <HLCombobox value={value || ""} onChange={onChange} disabled={disabled} by={by} multiple={multiple}>
      {({ open }) => (
        <>
          <div className="relative w-full">
            <div
              className={`w-full flex items-center justify-between border border-gray-300 rounded hover:border-sky-700 overflow-hidden ${
                (multiple && value.length > 0) || (!multiple && value) ? "!border-sky-700" : ""
              }`}>
              <HLCombobox.Input
                className="border-none flex-1 text-left placeholder:text-gray-600 text-sm py-2 px-3"
                displayValue={displayValue}
                onChange={handleInputChange}
                placeholder={placeholder}
              />
              {((multiple && value.length > 0) || (!multiple && value)) && !disabled && nullable ? (
                <button onClick={() => onChange(multiple ? [] : null)} className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <RiCloseLine />
                </button>
              ) : (
                <HLCombobox.Button className="absolute top-1/2 translate-y-1/2 right-2">
                  <div className={`absolute inset-y-0 right-0 flex items-center pr-2 ${disabled ? "opacity-50" : ""}`}>{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
                </HLCombobox.Button>
              )}
            </div>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <HLCombobox.Options
                className={`absolute z-20 my-1 max-h-64 ${width} ${position} text-base overflow-y-scroll border border-gray-200 rounded-md bg-white divide-y divide-gray-200`}>
                {filteredOptions.map((option, i) => (
                  <HLCombobox.Option key={i} value={option} className="select-none list-none">
                    {({ active, selected }) => (
                      <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`}>
                        <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
                        {selected && <RiCheckLine />}
                      </div>
                    )}
                  </HLCombobox.Option>
                ))}
              </HLCombobox.Options>
            </Transition>
          </div>
        </>
      )}
    </HLCombobox>
  );
};
export default Combobox;
