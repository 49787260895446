import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { MdKeyboardArrowUp, MdOutlineFactory, MdOutlineHome, MdOutlineShoppingCart, MdPersonOutline } from "react-icons/md";
import { BsBoxes } from "react-icons/bs";
import { PiGitPullRequestBold } from "react-icons/pi";

import {
  FaBriefcase,
  FaBroadcastTower,
  FaBuilding,
  FaCalendar,
  FaCalendarCheck,
  FaChartLine,
  FaClipboardList,
  FaDatabase,
  FaDesktop,
  FaEdit,
  FaEnvelope,
  FaEuroSign,
  FaFileAlt,
  FaFileInvoiceDollar,
  FaGraduationCap,
  FaHeadset,
  FaHome,
  FaMoneyCheck,
  FaPiggyBank,
  FaProjectDiagram,
  FaQuoteLeft,
  FaRegQuestionCircle,
  FaRocket,
  FaStar,
  FaSwimmingPool,
  FaTasks,
  FaTools,
  FaUserCheck,
  FaUserFriends,
  FaUsers,
  FaWallet,
  FaNetworkWired,
} from "react-icons/fa";

import { FaPeopleGroup, FaQuestion } from "react-icons/fa6";
import useStore from "../services/store";

const SideBar = () => {
  const { sideBarOpen, setSideBarOpen } = useStore();
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    if (window.innerWidth < 768) setSideBarOpen(false);
  }, []);

  if (!user) return null;

  return (
    <nav className={`${sideBarOpen ? "z-30 w-[90%] md:w-72" : "w-16 p-0"} absolute top-0 left-0 z-10 ease-in-out duration-300`}>
      <div className="bg-white border-r border-slate-300 flex flex-col w-full h-screen overflow-y-scroll overflow-x-hidden">
        <div className="flex justify-between px-6 mb-4 items-center">
          <div className="flex items-center w-full">
            <Link to={user.role === "applicant" ? "/applicant" : "/"} className="flex">
              <h1 className="text-2xl font-semibold text-sky-700">A</h1>
              <h1 className={`text-2xl font-semibold text-sky-700 ${sideBarOpen ? "opacity-100" : "opacity-0 hidden w-0"} transition-all`}>ccounting</h1>
            </Link>
          </div>
        </div>
        <div className="space-y-4">
          <Group title="Dashboard" Icon={MdOutlineHome}>
            <Item title="Home" to="/" Icon={FaHome} roles={["admin", "normal"]} />
            <Item title="Projects" to="/project" Icon={FaProjectDiagram} roles={["admin", "normal", "client"]} />
            <Item title="Support" to="/message" Icon={FaHeadset} roles={["admin", "normal", "applicant", "client"]} />
            <Item title="Perks" to="/perks" Icon={FaEuroSign} roles={["admin", "normal", "client"]} />
            <Item title="Components Library" to="/uxui" Icon={FaTasks} roles={["admin", "normal"]} />
            <Item title="Activities" to="/activity" Icon={FaCalendarCheck} roles={["admin", "normal"]} />
            <Item title="People" to="/people" Icon={FaUsers} roles={["admin"]} />
            <Item title="Reviews" to="/review" Icon={PiGitPullRequestBold} roles={["admin"]} />
          </Group>

          <Group title="Missions" Icon={MdOutlineFactory}>
            <Item title="Quotation" to="/quote" Icon={FaQuoteLeft} roles={["admin"]} />
            <Item title="Folk" to="/folk" Icon={FaQuoteLeft} roles={["admin"]} />
            <Item title="Listes" to="/list" Icon={FaQuoteLeft} roles={["admin"]} />
            <Item title="Missions" to="/missions" Icon={FaRocket} roles={["admin", "normal"]} />
            <Item title="Acquiz" to="/acquiz" Icon={FaRocket} roles={["admin", "normal"]} />
            <Item title="Help" to="/helps" Icon={FaRocket} roles={["admin", "normal"]} />
            <Item title="Missions AO" to="/missions_ao" Icon={FaRocket} roles={["admin", "normal"]} />
            <Item title="Dataroom" to="/data" Icon={FaDatabase} roles={["admin", "normal", "client"]} />
          </Group>

          <Group title="Recruitment" Icon={MdPersonOutline}>
            <Item title="Job" to="/job" Icon={FaBriefcase} roles={["admin"]} />
            <Item title="Referral" to="/referral" Icon={FaUserFriends} roles={["admin", "normal"]} />
            <Item title="Pool" to="/pool" Icon={FaSwimmingPool} roles={["admin", "client"]} />
            <Item title="Channels" to="/jobchannel" Icon={FaBroadcastTower} roles={["admin"]} />
            <Item title="My applications" to="/applicant" Icon={FaUserCheck} roles={["admin", "normal", "applicant"]} />
          </Group>

          <Group title="Workshops" Icon={MdOutlineShoppingCart}>
            <Item title="Workshops" to="/learn" Icon={FaGraduationCap} roles={["admin", "normal", "client"]} />
            <Item title="Workshops" to="/learn_public/" Icon={FaGraduationCap} roles={["applicant"]} />
            <Item title="Skills" to="/skills" Icon={FaTools} roles={["admin", "normal"]} />
            <Item title="Questioning" to="/questioning" Icon={FaRegQuestionCircle} roles={["admin"]} />
            <Item title="Editor" to="/learn-editor" Icon={FaEdit} roles={["admin"]} />
            <Item title="Ratings" to="/learn-rating" Icon={FaStar} roles={["admin", "normal"]} />
            <Item title="Meetings" to="/meetings" Icon={FaCalendar} roles={["admin", "normal"]} />
          </Group>

          <Group title="Accounting" Icon={BsBoxes}>
            <Item title="Invoice" to="/invoice" Icon={FaFileInvoiceDollar} roles={["admin"]} />
            <Item title="Payment" to="/payment" Icon={FaMoneyCheck} roles={["admin", "normal"]} />
            <Item title="Bank" to="/bank" Icon={FaPiggyBank} roles={["admin"]} />
            <Item title="Salary" to="/salary" Icon={FaPiggyBank} roles={["admin", "client"]} />
            <Item title="Mails" to="/mail" Icon={FaEnvelope} roles={["admin"]} />
            <Item title="Tools" to="/tools" Icon={FaPiggyBank} roles={["admin", "normal"]} />
            <Item title="Documents" to="/document" Icon={FaFileAlt} roles={["admin"]} />
            <Item title="Organisations" to="/organisation" Icon={FaBuilding} roles={["admin"]} />
            <Item title="Organigramme" to="/organigramme" Icon={FaNetworkWired} roles={["admin"]} />
            <Item title="Devices" to="/device" Icon={FaDesktop} roles={["admin"]} />
            <Item title="Report" to="/report" Icon={FaClipboardList} roles={["admin", "normal"]} />
            <Item title="Collection" to="/cashout" Icon={FaClipboardList} roles={["admin"]} />
            <Item title="Wallet" to="/wallet" Icon={FaWallet} roles={["admin"]} />
            <Item title="Portfolio" to="/portfolio" Icon={FaChartLine} roles={["admin"]} />
            <Item title="Find Your Cofounder" to="/find_your_cofounder" Icon={FaPeopleGroup} roles={["admin"]} />
            <Item title="Articles" to="/articles" Icon={FaPeopleGroup} roles={["admin"]} />
          </Group>
        </div>
      </div>
    </nav>
  );
};

const Group = ({ title, children, Icon }) => {
  const { sideBarOpen, setSideBarOpen } = useStore();
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    if (!sideBarOpen) setSideBarOpen(true);
    setOpen((e) => !e);
  };

  return (
    <div className="w-full">
      <button onClick={handleClick} className="transparent-btn p-2 flex items-center justify-between w-full">
        <div className="flex items-center gap-2 ">
          <Icon className="text-sky-700 text-base" />
          <h2 className={`text-sky-700 font-semibold text-base ${sideBarOpen ? "opacity-100" : "opacity-0 w-0"} transition-all`}>{title}</h2>
        </div>
        {sideBarOpen ? <MdKeyboardArrowUp className={`${open ? "rotate-0" : "-rotate-180"} transition-all text-sky-700 text-base`} /> : null}
      </button>
      <ul className={`sidebar ${sideBarOpen ? (open ? "open" : "") : "h-0 hidden"}`} style={{ maxHeight: open ? children.length * 28 : 0, overflow: open ? "visible" : "hidden" }}>
        {children}
      </ul>
    </div>
  );
};

const Item = ({ title, to, span, Icon, roles }) => {
  const user = useSelector((state) => state.Auth.user);
  if (roles && !roles.includes(user.role)) return null;

  return (
    <li className="w-full">
      <NavLink to={to} className={({ isActive }) => `${isActive ? "rounded bg-sky-100" : "transparent-btn"} h-7 p-1 pl-8 flex items-center justify-between w-full" font-semibold`}>
        <div className="flex items-center gap-2">
          {Icon ? <Icon className="text-sm" /> : null}
          <span className="text-sm truncate font-normal text-black">{title}</span>
        </div>
        {span ? span : null}
      </NavLink>
    </li>
  );
};

export default SideBar;
