import React, { useState, useEffect, Fragment } from "react";
import toast from "react-hot-toast";
import { HiOutlineDocumentText, HiOutlineMap, HiOutlineRss, HiOutlineClock } from "react-icons/hi2";
import { FiExternalLink } from "react-icons/fi";

import { FaUsers } from "react-icons/fa";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import validator from "validator";

import champercheImg from "../../../assets/champerche.svg";
import docloopImg from "../../../assets/docloop.svg";
import moneywalkieImg from "../../../assets/moneywalkie.svg";
import jobmakerImg from "../../../assets/jobmaker.svg";
import amplitude from "../../../services/amplitude";
import LOGO_SELEGO from "../../../assets/selego_logo2.jpg";

import betagouv from "../../../assets/jobOffers/betagouv.png";
import champerche from "../../../assets/jobOffers/champerche.png";
import erasmus from "../../../assets/jobOffers/erasmus.png";
import jobmaker from "../../../assets/jobOffers/jobmaker.png";
import moneywalkie from "../../../assets/jobOffers/moneywalkie.png";
import meteory from "../../../assets/jobOffers/meteory.png";
import royalcanin from "../../../assets/jobOffers/royalcanin.png";
import snu from "../../../assets/jobOffers/snu.png";
import animaj from "../../../assets/jobOffers/animaj.png";
import apiengagement from "../../../assets/jobOffers/apiengagement.png";
import climby from "../../../assets/jobOffers/climby.png";
import jeveuxaider from "../../../assets/jobOffers/jeveuxaider.png";
import monsuivipsy from "../../../assets/jobOffers/monsuivipsy.png";
import oz from "../../../assets/jobOffers/oz.png";
import selego from "../../../assets/selego_logo2.jpg";

import Loader from "../../../components/loader";

import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import Hotjar from "../../../services/hotjar";

const Create = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);

  const [jobPost, setJobPost] = useState(null);
  const [job, setJob] = useState(null);

  const [jobPostId, setJobPostId] = useState(query.get("jobpost"));

  const [jobsPostAccounting, setJobsPostAccounting] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [currentJobIndex, setCurrentJobIndex] = useState();

  useEffect(() => {
    amplitude.logEvent("signup");
  }, []);

  useEffect(() => {
    if (!jobs) {
      getJobs();
    } else {
      getJob();
    }
  }, [jobPostId]);

  useEffect(() => {
    getJob();
  }, [jobs]);
  useEffect(() => {
    if (!query.get("jobpost") && jobsPostAccounting && jobsPostAccounting.length) {
      query.set("jobpost", jobsPostAccounting[0]._id);
      window.location.href = `/auth/signup?${query.toString()}`;
    }
  }, [jobsPostAccounting]);
  async function getJob() {
    if (!jobs) return;
    const { data: p } = await api.get(`/jobpost/${jobPostId}?incrementViews=true`);
    setJobPost(p);
    setJob(jobs.find((j) => j._id === p.job_id));
    setLoading(false);
  }

  async function getJobs() {
    //fetch jobsPost accounting
    const { data } = await api.post(`/jobpost/public-search`);
    setJobsPostAccounting(data.filter((jp) => jp.channel_name === "Accounting"));

    //fetch active jobs
    const { data: dataJobs } = await api.post(`/job/public-search`, { status: ["active"] });
    setJobs(dataJobs);

    ///fetch jobPost
    const { data: datajobPost } = await api.get(`/jobpost/${jobPostId}`);
    setCurrentJobIndex(dataJobs.findIndex((j) => j._id === datajobPost.job_id));
  }

  const getPreviousJobPostId = () => {
    const jobId = currentJobIndex === 0 ? jobs[jobs.length - 1]._id : jobs[currentJobIndex - 1]._id;
    setCurrentJobIndex(jobs.findIndex((j) => j._id === jobId));
    return jobsPostAccounting.find((jp) => jp.job_id === jobId)._id;
  };

  const getNextJobPostId = () => {
    const jobId = currentJobIndex === jobs.length - 1 ? jobs[0]._id : jobs[currentJobIndex + 1]._id;
    setCurrentJobIndex(jobs.findIndex((j) => j._id === jobId));
    return jobsPostAccounting.find((jp) => jp.job_id === jobId)._id;
  };

  if (loading) return <Loader />;

  if (!job)
    return (
      <div>
        <Apply forceOpen />
      </div>
    );

  return (
    <>
      <Hotjar />
      <div className="min-h-screen bg-Slate-100 px-2 md:!px-20 mb-4">
        <div className="pt-6 pb-4 w-full flex justify-center">
          {jobs?.length ? (
            <div className="flex items-center gap-4">
              <button
                onClick={() => {
                  navigate(`/auth/signup?jobpost=${getPreviousJobPostId()}`);
                  setJobPostId(getPreviousJobPostId());
                }}
                className="flex items-center justify-center h-9 w-9 rounded-lg bg-[#fff] border-[1px] border-gray-300 hover:bg-gray-50 cursor-pointer">
                <BsArrowLeft />
              </button>
              <div className="tracking-widest">
                {currentJobIndex + 1}/{jobs.length}
              </div>
              <button
                onClick={() => {
                  navigate(`/auth/signup?jobpost=${getNextJobPostId()}`);
                  setJobPostId(getNextJobPostId());
                }}
                className="flex items-center justify-center h-9 w-9 rounded-lg bg-white border-[1px] border-gray-300 hover:bg-gray-50 cursor-pointer">
                <BsArrowRight />
              </button>
            </div>
          ) : null}
        </div>
        <div className="bg-white rounded-xl shadow-sm">
          <img className="object-contain p-1 rounded-md shadow-sm w-14 h-14 md:w-20 md:h-20 bg-light-white -translate-y-1/3 translate-x-1/2" src={LOGO_SELEGO} />
          <div className="flex flex-col gap-4 md:flex-row items-start justify-between px-10">
            <div className="flex flex-col">
              <h2 className="text-slate-700 text-xs md:text-normal uppercase font-sm tracking-wide">{job.project_name}</h2>
              <h1 className="textlg md:text-2xl text-black font-semibold mt-1 mb-2">{job.title}</h1>
              <div className="flex gap-x-5 gap-y-2 flex-wrap">
                {job.contract_type ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineDocumentText className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.contract_type}</span>
                  </div>
                ) : null}
                {job.contract_duration ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineClock className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.contract_duration}</span>
                  </div>
                ) : null}
                {job.location ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineMap className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.location}</span>
                  </div>
                ) : null}
                {job.remote ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineRss className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.remote}</span>
                  </div>
                ) : null}
                {job.applicant_count ? (
                  <div className="flex items-center gap-1">
                    <FaUsers className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.applicant_count} applicants</span>
                  </div>
                ) : null}
              </div>
              <div className="mt-4 group items-center text-blue-600 hover:text-blue-800">
                <a href="https://www.selego.co" target="_blank" rel="noreferrer" className="flex items-center gap-2  font-semibold hover:underline  transition-colors duration-200">
                  View our website
                  <FiExternalLink className="w-5 h-5   transition-colors duration-200" />
                </a>
              </div>
            </div>

            <Apply jobPost={jobPost} job={job} />
          </div>
          <div className="px-3 py-4 md:!px-14 md:!py-16">
            <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
          </div>
        </div>
        <div className="my-3" />
        <div className="w-full flex justify-center">
          <Apply jobPost={jobPost} job={job} />
        </div>
        <div className="my-3" />
        <div className="bg-blue-900 rounded-xl shadow-sm p-4">
          {/* <h2 className="text-sm text-white font-semibold">Selego</h2>
          <p className="mt-2 mb-4 text-sm font-normal text-white">
            {job.project_name} is part of Le stud startup studio. We help startups create their product. By joining us you will have access to top startups / scalups, a community
            of experts, and resources that can help accelerate your career.
          </p> */}
          <div className="flex flex-row items-center gap-4">
            <a href="https://www.selego.co" target="_blank" rel="noreferrer" className="rounded-3xl overflow-hidden">
              <img className="cursor-pointer" src={selego} alt="" width={70} height={70} />
            </a>
            <div>
              <h2 className="mt-3  text-sm font-semibold text-white">Selego</h2>
              <p className=" mb-4 text-sm font-normal text-white">
                We help startups create their product. By joining us you will have access to top startups / scalups, a community of experts, and resources that can help accelerate
                your career.
              </p>
            </div>
          </div>
          <h2 className="mt-8 text-sm font-semibold text-white">Our startups portfolio</h2>

          <div className="flex gap-4 py-4">
            <a href="https://moneywalkie.com/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={moneywalkie} alt="" width={100} height={100} />
            </a>
            <a href="https://www.jobmaker.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={jobmaker} alt="" width={100} height={100} />
            </a>

            <a href="https://www.champerche.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={champerche} alt="" width={100} height={100} />
            </a>

            {/* <a href="https://www.erasmus-entrepreneurs.eu/index.php?lan=en" target="_blank" rel="noreferrer">
              <img className="cursor-pointer" src={erasmus} alt="" width={100} height={100} />
            </a> */}
            <a href="https://www.snu.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={snu} alt="" width={100} height={100} />
            </a>
            <a href="https://www.beta.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor pointer" src={betagouv} alt="" width={100} height={100} />
            </a>
            <a href="https://meteory.eu/en" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={meteory} alt="" width={100} height={100} />
            </a>
            {/* <a href="https://www.royalcanin.fr/" target="_blank" rel="noreferrer">
                
              <img className="cursor-pointer" src={royalcanin} alt="" width={100} height={102} />
            </a> */}
            <a href="https://www.animaj.com/#pocoyo" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={animaj} alt="" width={100} height={100} />
            </a>
            <a href="https://api-engagement.beta.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={apiengagement} alt="" width={100} height={100} />
            </a>
            <a href="https://www.climbyapp.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={climby} alt="" width={100} height={100} />
            </a>
            <a href="https://www.jeveuxaider.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={jeveuxaider} alt="" width={100} height={100} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.monsuivipsy&hl=fr" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={monsuivipsy} alt="" width={100} height={100} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.addicto&hl=fr" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={oz} alt="" width={100} height={100} />
            </a>
          </div>
          <div className="flex  justify-center my-2">
            <a
              className="appearance-none hover:no-underline hover:text-gray-900 bg-[#fff] hover:bg-gray-50 text-gray-800 tracking-wider px-5 py-2 rounded-[10px]"
              href={`mailto:office@selego.co?subject=Contact pour le ${jobPostId ? `jobpost[${jobPostId}]` : ""}`}>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const Apply = ({ jobPost, job, forceOpen }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(forceOpen);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!validator.isEmail(email)) toast.error("Invalid email");
      setLoading(true);

      const obj = { name, password, email, jobpost_id: jobPost?._id, job_id: job?._id };

      const { data, token, code, ok } = await api.post(`/user/applicant`, obj);

      setLoading(false);
      if (!ok) {
        toast.error("Wrong signup", code);
        return;
      }
      api.setToken(token);
      dispatch(setUser(data));

      return navigate(`/auth/signup/profile`);
    } catch (e) {
      setLoading(false);
      if (e.code === "USER_ALREADY_REGISTERED") {
        return toast.error(
          <span>
            This email is already registered.
            <br />
            We&apos;ll contact you soon.
          </span>,
        );
      }
      toast.error(e.code);
    }
  }

  if (!open) {
    return (
      <div className="flex flex-col gap-2">
        <Link
          to="/jobs"
          className="flex flex-row items-center gap-2 border border-blue-600  hover:bg-blue-600 hover:text-white text-blue-600 tracking-wider px-5 py-3 text-lg font-bold rounded-[10px]">
          <BsArrowLeft />
          All the offers
        </Link>
        <button onClick={() => setOpen((e) => !e)} className="bg-blue-600 hover:bg-blue-500 text-white tracking-wider px-5 py-3 text-lg font-bold rounded-[10px]">
          Apply to this job
        </button>
      </div>
    );
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={() => setOpen(forceOpen || false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-[#00000066] backdrop-blur-sm bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel as="div" className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
                  <form className="flex flex-col" onSubmit={handleSubmit}>
                    <div className="mb-6">
                      {job ? <h1 className="text-2xl text-black font-bold mb-1">Your Application to our startup studio</h1> : null}
                      <p className="text-sm">
                        Create your account to apply to the offer or if you already have one {" "}
                        <Link to="/auth" className="text-primary font-bold">
                          Log in
                        </Link>{" "}
                        to apply
                      </p>
                    </div>
                    <div className="flex flex-col gap-4 mb-4">
                      <div className="flex flex-col">
                        <div className="text-gray-800 text-base mb-1">Full Name</div>
                        <input
                          required
                          type="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                          placeholder="John Doe"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 mb-4">
                      <div className="flex flex-col">
                        <div className="text-gray-800 text-base mb-1">Email</div>
                        <input
                          required
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                          placeholder="example@mail.com"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-gray-800 text-base mb-1">Password</div>
                      <input
                        required
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                        placeholder="Enter your password"
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={loading || !email || !password}
                      className="mt-3 self-center bg-blue-600 hover:bg-blue-500 text-white tracking-wider px-5 py-3 text-base font-medium rounded-[10px] cursor-pointer">
                      Create my account and apply
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Create;
