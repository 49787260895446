import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ReactTooltip from "react-tooltip";
// import { HiThumbUp } from "react-icons/hi";

import api from "../../../services/api";
import App, { AppContext } from "./context";

export default ({ onDelete }) => {
  const [comment, setComment] = useState("");

  const context = useContext(AppContext);
  const user = useSelector((state) => state.Auth.user);

  const comments = context.workshop.comments || [];

  if (!context.workshop.steps[context.index]) return <div />;

  const step_id = context.workshop.steps[context.index]._id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;
    setComment("");

    comments.push({ text: comment, user_name: user.name, user_id: user._id, user_avatar: user.avatar, step_id });
    const { data } = await api.put(`/workshop/${context.workshop._id}`, { comments });
    context.setWorkshop(data);
    toast.success("Comment added!");
  };

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this comment?") === false) return;
    const comments = context.workshop.comments.filter((c) => c._id !== id);
    const { data } = await api.put(`/workshop/${context.workshop._id}`, { comments });
    context.setWorkshop(data);
    toast.success("Comment deleted!");
  }

  return (
    <div className="lg:flex hidden flex-col self-start flex-shrink-0 w-2/3 mx-auto pb-20">
      <div className="flex flex-col">
        {(comments || [])
          .filter((e) => e.step_id === step_id)
          .map((comment, index) => (
            <div key={comment?._id} className={`${index === comments.filter((e) => e.step_id === step_id).length - 1 ? "" : "border-b-[0.5px]"} pb-2 mb-2`}>
              <div className="flex flex-row">
                <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <div className="text-sm font-bold">{comment.user_name}</div>
                    <div className="text-sm text-gray-500">{new Date(comment.created_at).toLocaleDateString()}</div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-start">
                <div className="flex-grow max-w-[70%] overflow-hidden">
                  <div className="ml-14 flex flex-wrap break-words whitespace-pre-wrap">{comment.text}</div>
                </div>
                {/* <HiThumbUp
                    className="cursor-pointer"
                    onClick={async () => {
                      const { data } = await api.post(`/workshop/${context.workshop._id}/like_comment`, { comment_id: comment._id });
                      context.setWorkshop(data);
                    }}
                  /> */}
                {/* <div className="text-sm text-gray-500 cursor-pointer underline" data-tip data-for={`like-${comment._id}`}>
                    {comment?.likes?.length || 0}
                  </div>
                  <ReactTooltip id={`like-${comment._id}`}>
                    {(comment.likes || []).map((l) => (
                      <div>{l.user_name}</div>
                    ))}
                  </ReactTooltip> */}
                {onDelete && (
                  <div className="text-sm text-red-600 cursor-pointer w-[10%] flex-shrink-0" onClick={() => onDelete(comment._id)}>
                    delete
                  </div>
                )}
              </div>
            </div>
          ))}
        {/* {comments.length === 0 && (
          <div>
            <div className="text-gray-500 flex">No comments yet</div>
            <div className="">A question, something to add? Be the first to comment</div>
          </div>
        )} */}
      </div>

      <div>
        <div className="flex items-center">
          <img src={user.avatar} alt="userlogo" className="rounded-full w-9 h-9 mr-2" />
          <textarea className="rounded-md border border-gray-100 shadow-sm block w-full" placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>
        <div className="flex justify-end mt-3">
          <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};
