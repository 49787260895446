import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ contact, setContact }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    get();
  }, [contact]);

  async function get() {
    const { data } = await api.post(`/s_activity/search`, { contact_id: contact._id });
    setActivities(data);
  }

  console.log("activities", activities);

  return (
    <div className="p-6">
      <div className="space-y-4">
        {activities.map((activity) => (
          <div key={activity._id} className="border p-4 rounded ">
            <div className="font-semibold">{activity.type}</div>
            <div className="text-sm text-gray-500">{new Date(activity.created_at).toLocaleString()}</div>
            <div className="text-sm text-gray-500">{activity.raw.message}</div>
            <div className="text-sm text-gray-500">{activity.raw.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
