import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";
import api from "../../../services/api";
import { useSearchParams } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const HEADER = [{ title: "Budget" }, { title: "Budgeted", position: "right" }, { title: "Used", position: "right" }, { title: "Used vs Budgeted", colSpan: 2, position: "center" }];

const Dashboard = ({ project }) => {
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBudgetData();
  }, []);

  const fetchBudgetData = async () => {
    try {
      const res = await api.post(`/budget/search`, { projectId: project._id });
      if (res.ok) {
        const budgets = res.data;
        if (budgets.length > 0) {
          // get the first budget active
          let activeBudget = budgets.find((budget) => budget.status === "active");
          if (!activeBudget) {
            // if no active budget, get the first one
            activeBudget = budgets[0];
          }
          // set the firsts selected budget active
          setSelectedBudget(activeBudget);

          await handleBudgetSelect(activeBudget);
        }
      } else {
        throw new Error("Failed to fetch budgets");
      }
    } catch (error) {
      console.error(error);
      toast.error("No budget found");
    } finally {
      setLoading(false);
    }
  };

  const handleBudgetSelect = async (budget) => {
    setLoading(true);
    try {
      const [activitiesRes, banksRes] = await Promise.all([
        api.post(`/activity/search`, { projectId: project._id, budgetId: budget._id }),
        api.post(`/bank/search`, { projectId: project._id, budgetId: budget._id }),
      ]);

      if (activitiesRes.ok) {
        const activitiesData = activitiesRes.data.map((activity) => ({
          ...activity,
          percentageUsed: (activity.amountUsed / budget.amount) * 100,
        }));
        setActivities(activitiesData);
      }

      if (banksRes.ok) setBanks(banksRes.data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch budget details");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex gap-3">
        {/* Table budget */}
        <div className="w-1/2 rounded-md bg-white border flex flex-col gap-4 p-2">
          <BudgetClient project={project} setSelectedBudget={setSelectedBudget} onBudgetSelect={handleBudgetSelect} selectedBudget={selectedBudget} />
        </div>
        <div className="w-1/2 rounded-md bg-white border flex flex-col gap-4 p-2">
          <Budget budgetChart={selectedBudget} activities={activities} banks={banks} />
        </div>
      </div>
    </div>
  );
};

const BudgetClient = ({ project, setSelectedBudget, onBudgetSelect, selectedBudget }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    status: searchParams.get("status") || "active",
    showInactive: searchParams.get("showInactive") === "true",
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { projectId: project._id };
        if (!filters.showInactive) query.status = "active";

        const res = await api.post(`/budget/search`, query);
        if (!res.ok) throw new Error("Failed to fetch budgets");
        setData(res.data);

        const newSearchParams = new URLSearchParams();
        if (!filters.showInactive) newSearchParams.set("status", "active");
        if (filters.showInactive) newSearchParams.set("showInactive", "true");
        setSearchParams(newSearchParams);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [project, filters, setSearchParams]);

  return (
    <div className="space-y-6">
      <div className="flex flex-wrap gap-4 items-center">
        <input type="checkbox" id="showInactive" checked={filters.showInactive} onChange={(e) => setFilters({ ...filters, showInactive: e.target.checked })} className="checkbox" />
        <label htmlFor="showInactive" className="text-sm font-semibold select-none cursor-pointer">
          Show inactive budgets
        </label>
      </div>
      <TablePagination header={HEADER}>
        {data.map((item, index) => (
          <BudgetRow
            key={index}
            item={item}
            isSelected={item._id === selectedBudget?._id}
            onClick={() => {
              setSelectedBudget(item);
              onBudgetSelect(item);
            }}
          />
        ))}
      </TablePagination>
    </div>
  );
};

const BudgetRow = ({ item, onClick, isSelected }) => (
  <tr className={`h-14 border-t ${isSelected ? "bg-blue-100" : item.status === "inactive" ? "bg-gray-100" : "bg-white"} cursor-pointer `} onClick={() => onClick(item)}>
    <td className="text-left p-2 text-sm">
      {item.name} {item.status === "inactive" && <span className="ml-4 text-xs text-gray-500">Inactive</span>}
    </td>
    <td className="text-right p-2 text-sm">{(item.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">{(item.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
    <td colSpan={2} className="text-left p-2 px-8 text-sm">
      <ProgressBar value={(item.amountUsed / item.amount) * 100} />
    </td>
  </tr>
);

const ProgressBar = ({ value }) => (
  <div className="w-full flex items-center gap-2">
    <div className="w-full relative h-2 rounded-full bg-gray-200">
      <div className={`absolute h-2 rounded-full ${value >= 100 ? "bg-red-900" : value >= 80 ? "bg-red-600" : "bg-sky-700"}`} style={{ width: `${Math.min(value, 100)}%` }} />
    </div>
    <div className="w-8 text-sm text-right">{value.toFixed(0)}%</div>
  </div>
);

const createLegendLabels = (chart) => {
  const { datasets, labels } = chart.data;
  const { data } = datasets[0];

  const total = data.reduce((acc, val) => acc + Math.abs(val), 0);

  return labels
    .map((label, index) => {
      const value = Math.abs(data[index]);
      const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
      const formattedValue = value.toLocaleString("fr-FR", { style: "currency", currency: "EUR" });
      const color = datasets[0].backgroundColor[index];

      return `
        <li style="list-style: none; margin: 10px; font-size: 14px;">
          <span style="display: inline-block; width: 12px; height: 12px; background-color: ${color}; margin-right: 8px;"></span>
          ${label}: ${percentage}% (${formattedValue})
        </li>
      `;
    })
    .join("");
};

const toTitleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const Budget = ({ budgetChart, activities, banks }) => {
  const totalAmountActivities = activities?.map((activity) => Math.abs(activity.value)).reduce((acc, curr) => acc + curr, 0) || 0;

  const bankCategories =
    banks?.banks?.reduce((acc, bank) => {
      let { sub_category, amount } = bank;
      let formattedCategory = toTitleCase(sub_category);

      const knownCategories = ["Tools", "Hosting", "Ads", "Invest", "Travel", "Foodandbeverage"];
      if (!knownCategories.includes(formattedCategory)) {
        formattedCategory = "Other";
      }

      if (!acc[formattedCategory]) {
        acc[formattedCategory] = 0;
      }
      acc[formattedCategory] += Math.abs(amount);
      return acc;
    }, {}) || {};

  const filteredBankCategories = Object.entries(bankCategories)
    .filter(([category]) => category !== "Other")
    .reduce((acc, [category, amount]) => {
      acc[category] = amount;
      return acc;
    }, {});

  const totalAmountBanks = Object.values(filteredBankCategories).reduce((acc, curr) => acc + curr, 0);

  const totalUsed = totalAmountActivities + totalAmountBanks;
  const totalAmount = budgetChart?.amount || 0;
  const remainingAmount = totalAmount - totalUsed;
  const finalRemainingAmount = Math.max(remainingAmount, 0);

  const budgetName = budgetChart?.name;

  const getColorForCategory = (category) => {
    switch (category) {
      case "Tools":
        return "#f0f1f2";
      case "Hosting":
        return "#024f75";
      case "Ads":
        return "#c01416";
      case "Invest":
        return "#071f2b";
      case "Travel":
        return "#c1c7ca";
      case "Foodandbeverage":
        return "#d9534f";
      case "Activities":
        return "#C0D8E3";
      case "Unused":
        return "#E4E7EB";
      default:
        return "#E4E7EB";
    }
  };

  const chartData = [];

  if (totalAmountActivities > 0) {
    chartData.push({
      label: "Activities",
      value: totalAmountActivities,
      color: getColorForCategory("Activities"),
    });
  }

  Object.keys(filteredBankCategories).forEach((category) => {
    chartData.push({
      label: category,
      value: filteredBankCategories[category],
      color: getColorForCategory(category),
    });
  });

  if (finalRemainingAmount > 0) {
    chartData.push({
      label: "Unused",
      value: finalRemainingAmount,
      color: getColorForCategory("Unused"),
    });
  }
  chartData.sort((a, b) => b.value - a.value);

  const labels = chartData.map((item) => item.label);
  const dataValues = chartData.map((item) => item.value);
  const backgroundColors = chartData.map((item) => item.color);

  const data = {
    labels,
    datasets: [
      {
        label: "Budget",
        data: dataValues,
        backgroundColor: backgroundColors,
        borderColor: "#ffffff",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          generateLabels: function (chart) {
            const legend = createLegendLabels(chart);
            const legendContainer = document.querySelector(".chart-legend");
            if (legendContainer) {
              legendContainer.innerHTML = legend;
            }
            return [];
          },
        },
      },
    },
    cutout: "60%",
  };

  return (
    <div className="flex flex-row items-center">
      <div className="flex flex-col p-2">
        <h2 className="text-lg font-semibold mb-4 mx-auto text-center">{budgetName}</h2>
        <div className="mx-auto text-xl font-bold">{totalUsed.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
        <div className="mx-auto my-4 border px-2 py-1 rounded-md">
          <div className="text-black font-light">Remaining Amount: {remainingAmount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
        </div>
        <div className="chart-legend my-auto min-w-[300px] max-w-[300px]"></div>
      </div>
      <div className="flex flex-col p-2 mx-auto">
        <div className="chart-container mx-auto">
          <Doughnut data={data} options={options} width={220} height={220} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
