import React, { useState, useEffect, useRef } from "react";
import api from "../services/api";

export default ({ value, onChange, projectId, status = "active" }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    if (projectId) get();
  }, [projectId]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  async function get() {
    const query = { limit: 20, status };
    if (projectId) query.projectId = projectId;
    const res = await api.post("/budget/search", query);
    setOptions(res.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
  }

  async function onSelect(e) {
    const selected = options.find((o) => o._id === e.target.value);
    setSelectedOption(selected);
    onChange(selected);
  }

  return (
    <select disabled={!projectId} value={selectedOption?._id || ""} onChange={onSelect} className="select">
      <option value="">Select budget</option>
      {options.map((e, i) => (
        <option key={i} value={e._id}>
          {e.name}
        </option>
      ))}
    </select>
  );
};
