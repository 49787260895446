import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import Modal from "../../components/modal";
import api from "../../services/api";
import SelectUser from "../../components/selectUser";
import { formatDate } from "../../utils";

const Edit = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  const getDevice = async () => {
    const { data } = await api.get(`/device/${id}`);
    setValues(data);
  };

  useEffect(() => {
    getDevice();
  }, []);

  const navigate = useNavigate();

  async function submit() {
    if (values.status === "active" && !values.userName) return toast.error("Please select a user");
    if (values.status === "inactive" && !values.office) return toast.error("Please select an office");
    if (!values.serial_number) return toast.error("Please enter a serial number");

    setLoading(true);
    if (values.userId === undefined) {
      values.userId = null;
    }
    if (values.userName === undefined) {
      values.userName = null;
    }
    const { data } = await api.put(`/device/${values._id.toString()}`, values);
    setLoading(false);
    toast.success("Updated");
    navigate(`/device/${data._id.toString()}`);
  }

  if (!values) return null;

  return (
    <div className="max-w-2xl w-full h-auto bg-[white] p-[25px] rounded-md grid grid-cols-1 gap-2">
      <input
        className="border border-gray-300 px-2 py-1"
        placeholder="Name"
        value={values.name}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, name: e.target.value }));
        }}
      />
      <select
        className="border border-gray-300 px-2 py-1"
        placeholder="model"
        value={values.model || ""}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, model: e.target.value }));
        }}>
        <option label="Choose Model" value="" disabled>
          Choose Model
        </option>
        {[
          "MacBook Air (15-inch, M3, 2024)",
          "MacBook Air (13-inch, M3, 2024)",
          "MacBook Air (15-inch, M2, 2023)",
          "MacBook Pro (16-inch, 2023)",
          "MacBook Pro (14-inch, 2023)",
          "MacBook Air (13-inch, M2, 2022)",
          "MacBook Pro (13-inch, M2, 2022)",
          "MacBook Pro (16-inch, 2021)",
          "MacBook Pro (14-inch, 2021)",
          "MacBook Pro (13-inch, M1, 2020)",
          "MacBook Air (M1, 2020)",
          "MacBook Pro (13-inch, Four Thunderbolt, 2020)",
          "MacBook Pro (13-inch, Two Thunderbolt, 2020)",
          "MacBook Air (Retina, 13-inch, 2020)",
          "MacBook Pro (16-inch, 2019)",
          "MacBook Air (Retina, 13-inch, 2019)",
          "MacBook Pro (13-inch, Two Thunderbolt, 2019)",
          "MacBook Pro (13-inch, Four Thunderbolt, 2019)",
          "MacBook Pro (15-inch, 2019)",
          "MacBook Air (13-inch, Retina, 2018)",
          "MacBook Pro (2018)",
        ].map((option, i) => (
          <option key={`option-model-${i}`} label={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        className="border border-gray-300 px-2 py-1"
        placeholder="serial_number"
        value={values.serial_number}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, serial_number: e.target.value }));
        }}
      />
      <select
        className="border border-gray-300 px-2 py-1"
        placeholder="ram"
        value={values.ram || ""}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, ram: e.target.value }));
        }}>
        <option label="Choose Ram" value="" disabled>
          Choose Ram
        </option>
        {["4 GB", "8 GB", "16 GB", "32GB", "64GB"].map((option, i) => (
          <option key={`option-ram-${i}`} label={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        className="border border-gray-300 px-2 py-1"
        placeholder="storage"
        value={values.storage || ""}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, storage: e.target.value }));
        }}>
        <option label="Choose storage" value="" disabled>
          Choose storage
        </option>
        {["128 GB", "256 GB", "512 GB", "1 TB"].map((option, i) => (
          <option key={`option-storage-${i}`} label={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <p className="mt-4">is the device active (used by someone) ?</p>
      <select
        className="border border-gray-300 px-2 py-1"
        placeholder="status"
        value={values.status || ""}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({
            ...p,
            status: e.target.value,
            userId: e.target.value === "active" ? values.userId : null,
            userName: e.target.value === "active" ? values.userName : null,
            userAvatar: e.target.value === "active" ? values.userAvatar : null,
            office: e.target.value === "inactive" ? values.office : null,
          }));
        }}>
        <option label="Choose status" value="" disabled>
          Choose Status
        </option>
        {["active", "inactive"].map((option, i) => (
          <option key={`option-status-${i}`} label={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {values.status === "active" && (
        <>
          <p className="mt-2">who ?</p>
          <SelectUser value={values.userId} onChange={(u) => setValues({ ...values, userId: u._id, userName: u.name, userAvatar: u.avatar })} />
        </>
      )}
      {values.status === "inactive" && (
        <>
          <p className="mt-2">Office where the device is stored :</p>
          <select
            className="border border-gray-300 px-2 py-1"
            placeholder="status"
            value={values.office || ""}
            onChange={(e) => {
              e.persist();
              setValues((p) => ({
                ...p,
                office: e.target.value,
              }));
            }}>
            <option label="Choose an office" value="" disabled>
              Choose an office :
            </option>
            <option value="Paris">Paris</option>
            <option value="Amsterdam">Amsterdam</option>
            <option value="Barcelone">Barcelone</option>
          </select>
        </>
      )}
      <p className="mt-4">comments (when/where is was bought, where it is stored, specificities, known issues, other...) :</p>
      <textarea
        className="border border-gray-300 px-2 py-1"
        placeholder="comments"
        value={values.comments}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, comments: e.target.value }));
        }}
      />
      <div className="mt-4">
        <p>Device Purchase Date</p>
        <input
          className="mt-2"
          type="date"
          value={values?.purchase_date && new Date(values?.purchase_date).toISOString().split("T")[0]}
          onChange={(e) => {
            e.persist();
            setValues((p) => ({ ...p, purchase_date: new Date(e.target.value) }));
          }}
        />
      </div>
      <button
        disabled={loading}
        className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px] disabled:opacity-40 disabled:cursor-not-allowed"
        onClick={submit}>
        Save
      </button>
    </div>
  );
};

export default Edit;
