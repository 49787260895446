import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";

import api from "../../services/api";
import { useSearchParams } from "react-router-dom";

import Loader from "../../components/loader";
import SelectUser from "../../components/selectUser";
import Table from "../../components/Table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import toast from "react-hot-toast";
import TablePagination from "../../components/TablePagination";

export default () => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: "-created_at",
    status: "active",
    contracts: ["CDD", "CDI", "INTERNSHIP", "FREELANCE", "APPRENTICESHIP", "VIE"],
    per_page: 1000,
  });
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  async function get() {
    try {
      setLoading(true);
      const { data } = await api.post(`/user/search`, filters);
      setUsers(data.users);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  const getProjects = async () => {
    setLoading(true);
    try {
      const query = { status: "active", types: ["startup-project", "startup-invest"], per_page: 1000 };
      const { ok, data } = await api.post("/project/search", query);
      if (!ok) throw new Error("Failed to fetch projects");
      setProjects(data.projects);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch projects");
    }
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, [filters]);

  useEffect(() => {
    getProjects();
  }, []);

  const calculateAverageLevelBySubCategory = (users, subCategory) => {
    let totalLevel = 0;
    let count = 0;
    users.forEach((user) => {
      user.stats_skillLevels?.forEach((skill) => {
        if (skill.subCategory === subCategory) {
          totalLevel += skill.level;
          count++;
        }
      });
    });
    return count > 0 ? totalLevel / count : 0;
  };

  const projectLeaderInPercentage = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    const leaders = members.filter((member) => {
      const isLeader = member.userStatsSkillLevels?.find((skill) => skill.category === "Leadership" && skill.subCategory === "Experience" && skill.level >= 3);
      return isLeader;
    });

    return Math.min(1, Math.max(0, ...leaders.map((leader) => leader.hoursLastThreeMonths / total / 0.2)));
  };

  const projectBusinessInPercentage = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    const leaders = members.filter((member) => {
      const isBusiness = member.userStatsSkillLevels?.find((skill) => skill.category === "Leadership" && skill.subCategory === "Business" && skill.level >= 3);
      return isBusiness;
    });
    return Math.min(1, Math.max(0, ...leaders.map((leader) => leader.hoursLastThreeMonths / total / 0.2)));
  };

  const projectCTOInPercentage = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    const leaders = members.filter((member) => {
      const leader = member.userStatsSkillLevels?.find((skill) => skill.category === "Leadership" && skill.subCategory === "Experience" && skill.level >= 3);
      const tech = member.userStatsSkillLevels?.find((skill) => skill.category === "Code" && skill.subCategory === "LeStud principles" && skill.level >= 3);
      return leader && tech;
    });
    return Math.min(1, Math.max(0, ...leaders.map((leader) => leader.hoursLastThreeMonths / total / 0.2)));
  };

  const projectProductInPercentage = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    const leaders = members.filter((member) => {
      const isProduct = member.userStatsSkillLevels?.find((skill) => skill.category === "Product" && skill.subCategory === "Product strategy - Leadership" && skill.level >= 3);
      return isProduct;
    });
    return Math.min(1, Math.max(0, ...leaders.map((leader) => leader.hoursLastThreeMonths / total / 0.2)));
  };

  if (!users) return <Loader />;

  const HEADER_COLS = [
    { title: "Project" },
    { title: "Health" },
    {
      title: (
        <span>
          lead<span className="px-2 bg-sky-700 text-white">level</span>
          <span className="px-2 bg-sky-200">last three months</span>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        header={HEADER_COLS}
        loading={loading}
        height=""
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No result</div>}>
        {projects
          ?.sort((projectA, projectB) => {
            const totalTeamHoursA = projectA.team.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
            const totalTeamHoursB = projectB.team.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
            return totalTeamHoursB - totalTeamHoursA;
          })
          ?.map((project) => {
            const team = users.filter((u) => project.team.find((member) => member.userId === u._id));
            const totalTeamHours = project.team.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);

            return (
              <tr key={project._id} className={`hover:bg-gray-200 odd:bg-gray-100 even:bg-white`}>
                <td className="px-2 py-1 w-1/12">
                  <a className="flex items-center gap-2 hover:underline cursor-pointer" href={`/project/${project._id}`} target="_blank">
                    {project.name}
                    <MdArrowOutward />
                  </a>
                </td>
                <td className="px-2 py-1 w-6/12">
                  <div className="flex items-center gap-1 justify-between">
                    <span>Leader</span>
                    <ProgressBar percentage={projectLeaderInPercentage({ members: project.team }) * 100} />
                  </div>
                  {/* <div className="flex items-center gap-1 justify-between">
                    <span>Business</span>
                    <ProgressBar percentage={projectBusinessInPercentage({ members: project.team }) * 100} />
                  </div>
                  <div className="flex items-center gap-1 justify-between">
                    <span>CTO</span>
                    <ProgressBar percentage={projectCTOInPercentage({ members: project.team }) * 100} />
                  </div> */}
                  <div className="flex items-center gap-1 justify-between">
                    <span>Product</span>
                    <ProgressBar percentage={projectProductInPercentage({ members: project.team }) * 100} />
                  </div>
                  <BudgetClient project={project} />
                </td>
                <td className="px-2 py-1 w-5/12">
                  <div className="flex flex-col items-end">
                    {project.team
                      .filter((member) => member.userRole !== "client")
                      .sort((memberA, memberB) => memberB.hoursLastThreeMonths - memberA.hoursLastThreeMonths)
                      .map((member) => {
                        const percentage = (member.hoursLastThreeMonths / totalTeamHours) * 100;
                        return (
                          <div key={member._id} className="flex items-center text-xs text-gray-900 my-1">
                            <div className="flex flex-col items-end mr-1 ">
                              <button
                                className="flex items-center hover:underline cursor-pointer"
                                onClick={() => {
                                  query.set("user_modal_id", member.userId);
                                  query.set("user_modal_tab", "skills");
                                  navigate({ search: query.toString() });
                                }}>
                                <img src={member.userAvatar} className="w-6 h-6 rounded-full mr-2" />
                                <div>{member.userName}</div>
                              </button>
                              <div className="text-xs text-neutral-400 italic">{member.userJobTitle}</div>
                            </div>
                            <span className="px-2 bg-sky-200">
                              {(member.hoursLastThreeMonths / 8).toFixed(2)}days - {percentage.toFixed(2)}%
                            </span>
                          </div>
                        );
                      })}
                    <span className="text-sm text-gray-900">
                      TEAM Budgeted{" "}
                      <span className="px-2 bg-sky-200">
                        {(totalTeamHours / 8).toFixed(2)}
                        days
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
      </Table>
    </div>
  );
};

const Tag = ({ active }) => {
  let bgColor, textColor, dotColor;
  if (active) {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  } else {
    bgColor = "bg-red-100";
    textColor = "text-red-600";
    dotColor = "bg-red-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
    </span>
  );
};

const ProgressBar = ({ percentage }) => {
  if (!percentage < 0) return <div className="w-full bg-gray-200 h-3 rounded-sm overflow-hidden animate-pulse" />;
  let bgColor;
  if (percentage < 50) bgColor = "bg-red-600";
  else if (percentage < 80) bgColor = "bg-yellow-600";
  else if (percentage >= 80) bgColor = "bg-green-600";

  return (
    // Progress Bar
    <div className="flex gap-2 items-center">
      <div className={`w-40 bg-gray-200 h-3 rounded-sm overflow-hidden`}>
        <div className="bg-white rounded-sm " style={{ width: `${Math.min(100, percentage)}%` }}>
          <div className={`flex justify-center items-center h-3 ${bgColor} rounded-sm`} />
        </div>
      </div>
      <div className="text-gray-700 text-xs">{percentage.toFixed(2)}</div>
    </div>
  );
};

const BudgetClient = ({ project, selectedBudget }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    status: searchParams.get("status") || "active",
    showInactive: searchParams.get("showInactive") === "true",
  });

  const HEADER = [{ title: "Budget" }, { title: "Budgeted", position: "right" }, { title: "Used vs Budgeted", colSpan: 2, position: "center" }];

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const query = { projectId: project._id };
      if (!filters.showInactive) query.status = "active";

      const res = await api.post(`/budget/search`, query);
      if (!res.ok) throw new Error("Failed to fetch budgets");
      setData(res.data);

      const newSearchParams = new URLSearchParams();
      if (!filters.showInactive) newSearchParams.set("status", "active");
      if (filters.showInactive) newSearchParams.set("showInactive", "true");
      setSearchParams(newSearchParams);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [project, filters, setSearchParams]);

  return (
    <div className="space-y-6">
      <TablePagination header={HEADER}>
        {data.map((item, index) => (
          <tr key={index} className={`h-14 border-t ${item.status === "inactive" ? "bg-gray-100" : "bg-white"} cursor-pointer `}>
            <td className="text-left p-2 text-sm">
              {item.name} {item.status === "inactive" && <span className="ml-4 text-xs text-gray-500">Inactive</span>}
            </td>
            <td className="text-right p-2 text-sm">{(item.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
            {/* <td className="text-right p-2 text-sm">{(item.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td> */}
            <td colSpan={2} className="text-left p-2 px-8 text-sm">
              <ProgressBar percentage={(item.amountUsed / item.amount) * 100} />
            </td>
          </tr>
        ))}
      </TablePagination>
    </div>
  );
};
