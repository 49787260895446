import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ contact, setContact }) => {
  const user = useSelector((state) => state.Auth.user);

  const [values, setValues] = useState(null);

  useEffect(() => {
    setValues(contact);
  }, [contact]);

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    try {
      await api.remove(`/s_contact/${contact._id}`);
      toast.success("successfully removed!");
      setContact(null);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { data } = await api.put(`/s_contact/${contact._id}`, values);
      setContact(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  if(!values) return <div>Loading...</div>

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="space-y-2">
                <input className="input w-full" type="text" name="first_name" value={values.first_name} onChange={(e) => setValues({ ...values, first_name: e.target.value })} />
              </div>
              <div className="space-y-2">
                <input className="input w-full" type="text" name="last_name" value={values.last_name} onChange={(e) => setValues({ ...values, last_name: e.target.value })} />
              </div>
            </div>
            <div className="space-y-2">
              <input className="input w-full" type="text" name="email" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="description">
                Description
              </label>
              <textarea
                rows={4}
                className="input w-full"
                type="text"
                name="description"
                value={values.description}
                onChange={(e) => setValues({ ...values, description: e.target.value })}
              />
            </div>
            <div className="flex space-x-4">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
