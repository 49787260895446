import { useLayoutEffect, useRef, useEffect, useState } from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { getBase64file } from "../utils";
import SelectUser from "../../../../components/selectUser";
import Select from "../../../../components/Select";
import API from "../../../../services/api";

const Team = ({ values, editing, onChange, lang }) => {
  if (editing) return <TeamForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className="flex flex-col gap-4 cursor-pointer">
      <div className="flex flex-row gap-3 flex-wrap justify-between">
        {(values.users || []).map((user, index) => {
          return <TeamCard key={index} user={user} visibleField={values.visible_field} lang={lang} />;
        })}
      </div>
    </div>
  );
};

const TeamCard = ({ user, visibleField, lang }) => {
  const [imageB64Url, setImageB64Url] = useState("");

  useEffect(() => {
    if (user.avatar) {
      getBase64file(user.avatar).then((url) => {
        setImageB64Url(url);
      });
    }
  }, [user.avatar]);

  if (!user) return null;

  return (
    <div className="flex flex-col w-52 gap-2 p-3 bg-white border border-gray-200 rounded font-poppins">
      {visibleField.avatar && (
        <div className="w-full overflow-hidden rounded flex justify-center">
          <img className="w-36 h-36 rounded" src={imageB64Url} alt="User Avatar" />
        </div>
      )}
      <div>
        {visibleField.name && <p className="border-none m-0 w-full font-semibold text-sm">{user.name}</p>}
        {visibleField.role && (
          <div className="text-sm">
            {lang === "fr" ? "Poste : " : "Job: "}
            <span className="border-none overflow-visible text-sm py-0 px-2">{user.role ? user.role[lang] : ""}</span>
          </div>
        )}

        {visibleField.location && (
          <div className="text-sm">
            {lang === "fr" ? "Localisation : " : "Location: "}
            <span className="border-none overflow-visible text-sm py-0 px-2">{user.location}</span>
          </div>
        )}

        {visibleField.sell_price && (
          <div className="text-sm">
            {lang === "fr" ? "TJM : " : "ADR: "}
            <span className="border-none overflow-visible text-sm py-0 px-2">
              {user.sell_price?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              {lang === "fr" ? " / jour" : " / day"}
            </span>
          </div>
        )}
        {visibleField.cost_price && (
          <div className="text-sm">
            {lang === "fr" ? "TJM coûtant : " : "ADR cost: "}
            <span className="border-none overflow-visible text-sm py-0 px-2">
              {user.cost_price?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              {lang === "fr" ? " / jour" : " / day"}
            </span>
          </div>
        )}

        {visibleField.experience_total && (
          <div className="text-sm">
            {lang === "fr" ? "Exp : " : "Exp: "}
            <span className="border-none overflow-visible text-sm py-0 px-2">
              {user.experience_total} {`year${user?.experience_total > 1 ? "s" : ""}`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const TeamForm = ({ values, onChange, lang }) => {
  const [filters, setFilters] = useState({ position: null, status: "active", role: ["admin", "normal"], sort: "starting_date" });

  const updateUser = (user) => {
    const updatedUsers = values.users.map((u) => (u.id === user.id ? user : u));
    onChange({ ...values, users: updatedUsers });
  };

  const handleChange = (e) => {
    onChange({ ...values, [e.target.name]: e.target.value });
  };

  const handleDevUsers = async (e) => {
    try {
      const { data, ok } = await API.post("/user/search", filters);
      if (!ok) return;
      const newUsers = data.users.map((user) => ({
        id: user._id,
        name: user.name,
        avatar: user.avatar,
        sell_price: user.tjms,
        cost_price: user.tjm_salary,
        location: user.country,
        role: user.position,
        description: user.description,
        experience_total: user.experience_total,
      }));
      onChange({ ...values, users: newUsers });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-end gap-3 mt-5">
        <Select
          options={["FULLSTACK", "DATA", "DEVOPS", "PRODUCT", "SALES", "OTHER"]}
          value={filters.position}
          onChange={(e) => setFilters({ ...filters, position: e })}
          placeholder="Add a position"
          width="w-full"
        />
        <button onClick={handleDevUsers} className="text-sm font-semibold blue-btn">
          Add profiles
        </button>
      </div>

      <div className="text-sm font-semibold">select visible fields</div>
      <div className="flex justify-between gap-3">
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.avatar}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, avatar: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Avatar</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.name}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, name: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Name</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.role}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, role: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Role</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.sell_price}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, sell_price: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Price</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.cost_price}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, cost_price: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Cost</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.location}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, location: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Location</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={values.visible_field.experience_total}
            onChange={(e) => onChange({ ...values, visible_field: { ...values.visible_field, experience_total: e.target.checked } })}
          />
          <label className="text-sm font-semibold">Exp. Years</label>
        </div>
      </div>

      <div className="text-sm font-semibold">Add a specific user</div>
      <SelectUser
        userRoles={["admin", "normal", "applicant"]}
        onChange={(user) => {
          if (!user?._id) return;
          const newUsers = values.users || [];
          newUsers.push({
            id: user._id,
            name: user.name,
            avatar: user.avatar,
            sell_price: user.tjms,
            cost_price: user.tjm_salary,
            location: user.country,
            role: { fr: user.job_title, en: user.job_title },
            description: user.description,
          });
          onChange({ ...values, users: newUsers });
        }}
      />

      <div className="flex flex-row gap-3 p-2 flex-wrap">
        {(values.users || []).map((user, index) => {
          return <TeamCardForm key={index} user={user} updateUser={updateUser} values={values} onChange={onChange} lang={lang} />;
        })}
      </div>
    </div>
  );
};

const TeamCardForm = ({ user, updateUser, values, onChange, lang }) => {
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 1)}px`;
  }, [user]);

  function onDeleteUser(user) {
    const updatedUsers = values.users.filter((u) => u.id !== user.id);
    onChange({ ...values, users: updatedUsers });
  }

  return (
    <div className="flex flex-col w-48 gap-2 p-5 pb-8 bg-white border border-gray-200 rounded mb-4 font-poppins relative">
      {values.visible_field.avatar && (
        <div className="w-full h-40 overflow-hidden rounded">
          <img className="w-full rounded" src={user?.avatar} alt="User Avatar" />
        </div>
      )}
      <div>
        {values.visible_field.name && (
          <input className="border-none p-0 m-0 w-full text-sm font-semibold" value={user?.name} onChange={(e) => updateUser({ ...user, name: e.target.value })} />
        )}
        {values.visible_field.role && (
          <textarea
            ref={textareaRef}
            style={{
              minHeight: 1,
              resize: "none",
            }}
            className="border-none p-0 m-0 w-full text-xs text-gray-500"
            value={user?.role[lang]}
            onChange={(e) => updateUser({ ...user, role: { ...user.role, [lang]: e.target.value } })}
          />
        )}

        {values.visible_field.location && (
          <input className="border-none p-0 m-0 w-full text-sm font-semibold" value={user?.location} onChange={(e) => updateUser({ ...user, location: e.target.value })} />
        )}

        {values.visible_field.sell_price && (
          <div className="font-poppins font-semibold text-gray-800 text-sm">
            {lang === "fr" ? "TJM vendu : " : "ADR sold: "}
            <input
              className="border-none w-[60px] overflow-visible font-semibold text-gray-800 text-sm py-0"
              value={user?.sell_price}
              onChange={(e) => updateUser({ ...user, sell_price: e.target.value })}
            />
            €
          </div>
        )}
        {values.visible_field.cost_price && (
          <div className="font-poppins font-semibold text-gray-800 text-sm">
            {lang === "fr" ? "TJM coûtant : " : "ADR cost: "}
            <input
              className="border-none w-[60px] overflow-visible font-semibold text-gray-800 text-sm py-0"
              value={user?.cost_price}
              onChange={(e) => updateUser({ ...user, cost_price: e.target.value })}
            />
            €
          </div>
        )}
        {values.visible_field.experience_total && (
          <div className="font-poppins font-semibold text-gray-800 text-sm">
            {lang === "fr" ? "Années d'expérience : " : "Years of Experience: "}
            <input
              className="border-1 w-[60px] overflow-visible font-semibold text-gray-800 text-sm py-0"
              value={user?.experience_total}
              onChange={(e) => updateUser({ ...user, experience_total: e.target.value })}
            />
          </div>
        )}
      </div>

      <div className="absolute bottom-2 right-3">
        <RiDeleteBin2Fill onClick={() => onDeleteUser(user)} className="text-2xl p-1.5 text-red-500 bg-red-50 hover:bg-red-200 rounded-full cursor-pointer" />
      </div>
    </div>
  );
};

export default Team;
