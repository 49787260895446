import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import Loader from "../../../../../components/loader";

const Table = ({ header, sort, total, onSort, loading, children, selectedNotions, setSelectedNotions, Notions }) => {
  if (total === 0 && !loading) return <div className="w-full px-3 py-5">No data found</div>;

  const handleSelectAll = (e) => {
    const allSelected = Object.values(selectedNotions).every((item) => item) && Object.values(selectedNotions).length > 0;
    if (allSelected) {
      setSelectedNotions(Object.fromEntries(Notions.map((item) => [item._id, false])));
    } else {
      setSelectedNotions(Object.fromEntries(Notions.map((item) => [item._id, true])));
    }
  };

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full border rounded overflow-x-auto no-scrollbar h-full bg-white">
      <table className={`w-full`}>
        <thead className={`text-left`}>
          <tr className="border-b">
            {header.map((item, index) => {
              return (
                <th key={index} className="p-2" colSpan={item.colSpan || 1}>
                  <div className={`flex items-center gap-2 ${item.position === "right" ? "justify-end" : item.position === "center" ? "justify-center" : "justify-start"}`}>
                    {item.key === "checkbox" ? (
                      <input
                        className="mx-auto"
                        type="checkbox"
                        checked={Object.values(selectedNotions).every((item) => item) && Object.values(selectedNotions).length > 0}
                        onChange={handleSelectAll}
                      />
                    ) : (
                      <h3 className="text-sm font-semibold">{item.title}</h3>
                    )}
                    {item.key && item.key !== "checkbox" && (
                      <button
                        className="h-4 flex flex-col justify-center"
                        onClick={() => {
                          if (!item.key) return;
                          if (!onSort) return;
                          onSort(item.key);
                        }}>
                        {sort && sort[item.key] === 1 ? (
                          <IoIosArrowUp className="text-secondary" />
                        ) : sort && sort[item.key] === -1 ? (
                          <IoIosArrowDown className="text-secondary" />
                        ) : (
                          <>
                            <IoIosArrowUp className="text-secondary" />
                            <IoIosArrowDown className="text-secondary" />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="relative">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
