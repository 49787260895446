import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";

import TablePagination from "../../components/TablePagination";
import DebounceInput from "../../components/DebounceInput";
import toast from "react-hot-toast";
import Loader from "../../components/loader";
import api from "../../services/api";
import BankModal from "../../components/bankModal";

import All from "./all";
import { BANKS, CARDS, CATEGORIES, SUBCATEGORIES } from "../../constants/banks";
import Stats from "./components/Stats";
import { exportCSV } from "../../utils";

const HEADER = [
  { title: "Date" },
  { title: "Name", colSpan: 3 },
  { title: "Status" },
  { title: "Project", colSpan: 2 },
  { title: "Banq", colSpan: 2 },
  { title: "Amount", position: "right" },
];

const STATUS_SPAN = {
  DONE: (
    <div className="text-xs text-green-600 bg-green-50 px-2 py-1 rounded flex w-fit items-center gap-2">
      <span className="h-2 w-2 rounded-full bg-green-600" />
      <p>Done</p>
    </div>
  ),
  TODO: (
    <div className="text-xs text-red-600 bg-red-50 px-2 py-1 rounded flex w-fit items-center gap-2">
      <span className="h-2 w-2 rounded-full bg-red-600" />
      <p>Todo</p>
    </div>
  ),
};

const CATEGORIES_SPAN = {
  DISBURSEMENT: <span className="text-xs text-blue-600 bg-green-50 px-1 rounded">Disbursement</span>,
  GENERAL_COST: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">General Cost</span>,
  INCOME: <span className="text-xs text-green-600 bg-green-50 px-1 rounded">Income</span>,
  REFUND: <span className="text-xs text-gray-600 bg-gray-50 px-1 rounded">Refund</span>,
  TAXES: <span className="text-xs text-yellow-600 bg-yellow-50 px-1 rounded">Taxes</span>,
  TOOLS: <span className="text-xs text-purple-600 bg-purple-50 px-1 rounded">Tools</span>,
  VARIABLE_COST: <span className="text-xs text-pink-600 bg-pink-50 px-1 rounded">Variable Cost</span>,
};

const SUBCATEOGORIES_SPAN = {
  BANK: <span className="text-xs text-blue-600 bg-green-50 px-1 rounded">Bank</span>,
  TRAVEL: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">Travel</span>,
  HOSTING: <span className="text-xs text-green-600 bg-green-50 px-1 rounded">Hosting</span>,
  OFFICE: <span className="text-xs text-gray-600 bg-gray-50 px-1 rounded">Office</span>,
  ADS: <span className="text-xs text-yellow-600 bg-yellow-50 px-1 rounded">Ads</span>,
  ACCOUNTING: <span className="text-xs text-purple-600 bg-purple-50 px-1 rounded">Accounting</span>,
  TOOLS: <span className="text-xs text-pink-600 bg-pink-50 px-1 rounded">Tools</span>,
  FOODANDBEVERAGE: <span className="text-xs text-blue-600 bg-green-50 px-1 rounded">Food and Beverage</span>,
  LEGAL: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">Legal</span>,
  OTHER: <span className="text-xs text-green-600 bg-green-50 px-1 rounded">Other</span>,
  SALARY_FREELANCE: <span className="text-xs text-gray-600 bg-gray-50 px-1 rounded">Salary Freelance</span>,
  SALARY_EMPLOYEE: <span className="text-xs text-yellow-600 bg-yellow-50 px-1 rounded">Salary Employee</span>,
  TAX_SALARY: <span className="text-xs text-purple-600 bg-purple-50 px-1 rounded">Tax Salary</span>,
  BUSINESS_INTRODUCER_COST: <span className="text-xs text-pink-600 bg-pink-50 px-1 rounded">Business Introducer Cost</span>,
  INVOICE: <span className="text-xs text-blue-600 bg-green-50 px-1 rounded">Invoice</span>,
  VAT_INCOME: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">VAT Income</span>,
  BUSINESS_INTRODUCER_INCOME: <span className="text-xs text-green-600 bg-green-50 px-1 rounded">Business Introducer Income</span>,
  SUBVENTION: <span className="text-xs text-gray-600 bg-gray-50 px-1 rounded">Subvention</span>,
  CORPORATION_TAX: <span className="text-xs text-yellow-600 bg-yellow-50 px-1 rounded">Corporation Tax</span>,
  VAT_DISBURSEMENT: <span className="text-xs text-purple-600 bg-purple-50 px-1 rounded">VAT Disbursement</span>,
  TRANSFER: <span className="text-xs text-pink-600 bg-pink-50 px-1 rounded">Transfer</span>,
  INVEST: <span className="text-xs text-blue-600 bg-green-50 px-1 rounded">Invest</span>,
  DIVIDEND: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">Dividend</span>,
  IT_EQUIPMENT: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">IT Equipment</span>,
  REFUND: <span className="text-xs text-green-600 bg-green-50 px-1 rounded">Refund</span>,
};

const BANKS_SPAN = {
  BUNQ: <span className="text-xs text-blue-600 bg-green-50 px-1 rounded">Bunq</span>,
  BNP: <span className="text-xs text-orange-600 bg-orange-50 px-1 rounded">Bnp</span>,
  BUNQ_LESTUD: <span className="text-xs text-green-600 bg-green-50 px-1 rounded">Le stud</span>,
};

const Bank = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    banq: searchParams.get("banq") || "",
    card: searchParams.get("card") || "",
    search: searchParams.get("search") || "",
    status: searchParams.get("status") || "TODO",
    project: searchParams.get("project") || "",
    category: searchParams.get("category") || "",
    sub_category: searchParams.get("sub_category") || "",
    from_date: searchParams.get("from_date") ? new Date(searchParams.get("from_date")) : null,
    to_date: searchParams.get("to_date") ? new Date(searchParams.get("to_date")) : null,
    page: parseInt(searchParams.get("page")) || 1,
    per_page: parseInt(searchParams.get("per_page")) || 100,
    minAmount: searchParams.get("minAmount") || "",
    maxAmount: searchParams.get("maxAmount") || "",

    // TODO Multiple select
    // projects: searchParams.get("projects") ? searchParams.get("projects").split(",") : [],
    // cards: searchParams.get("cards") ? searchParams.get("cards").split(",") : [],
    // banks: searchParams.get("banks") ? searchParams.get("banks").split(",") : [],
    // countries: searchParams.get("countries") ? searchParams.get("countries").split(",") : [],
    // categories: searchParams.get("categories") ? searchParams.get("categories").split(",") : [],
    // subcategories: searchParams.get("subcategories") ? searchParams.get("subcategories").split(",") : [],
    // leads: searchParams.get("leads") ? searchParams.get("leads").split(",") : [],
  });
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok, data } = await api.post("/project/search", { status: "active" });
        if (!ok) throw new Error("Error while fetching projects");
        data.projects.sort(function (a, b) {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
          return 0;
        });
        setProjects(data.projects);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching projects");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = {};
        if (filters.search) query.search = filters.search;
        if (filters.project.length) query.project_id = filters.project;
        if (filters.status) query.status = filters.status;
        if (filters.card) query.card_id = filters.card;
        if (filters.banq) query.source = filters.banq;
        if (filters.category) query.category = filters.category;
        if (filters.sub_category) query.sub_category = filters.sub_category;

        if (filters.from_date) query.startDate = filters.from_date;
        if (filters.to_date) query.endDate = filters.to_date;

        if (filters.page) query.page = filters.page;
        if (filters.per_page) query.per_page = filters.per_page;

        if (filters.minAmount) query.minAmount = filters.minAmount;
        if (filters.maxAmount) query.maxAmount = filters.maxAmount;

        const { ok, data } = await api.post("/bank/search", query);
        if (!ok) throw new Error("Error while fetching banks");
        setData(data.banks);
        setTotal(data.total);

        const newSearchParams = new URLSearchParams();
        if (filters.search) newSearchParams.set("search", filters.search);
        if (filters.project) newSearchParams.set("project", filters.project);
        if (filters.status) newSearchParams.set("status", filters.status);
        if (filters.card) newSearchParams.set("card", filters.card);
        if (filters.banq) newSearchParams.set("banq", filters.banq);
        if (filters.category) newSearchParams.set("category", filters.category);
        if (filters.sub_category) newSearchParams.set("sub_category", filters.sub_category);
        if (filters.from_date) newSearchParams.set("from_date", filters.from_date);
        if (filters.to_date) newSearchParams.set("to_date", filters.to_date);

        if (filters.page) newSearchParams.set("page", filters.page);
        if (filters.per_page) newSearchParams.set("per_page", filters.per_page);

        if (filters.minAmount) newSearchParams.set("minAmount", filters.minAmount);
        if (filters.maxAmount) newSearchParams.set("maxAmount", filters.maxAmount);

        if (searchParams.get("bank_id")) newSearchParams.set("bank_id", searchParams.get("bank_id"));

        setSearchParams(newSearchParams);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching banks");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  const handleExport = async () => {
    setExporting(true);
    try {
      const query = { page: 1, per_page: 10000 };
      if (filters.search) query.search = filters.search;
      if (filters.project.length) query.project_id = filters.project;
      if (filters.status) query.status = filters.status;
      if (filters.card) query.card_id = filters.card;
      if (filters.banq) query.source = filters.banq;
      if (filters.category) query.category = filters.category;
      if (filters.sub_category) query.sub_category = filters.sub_category;

      if (filters.from_date) query.startDate = filters.from_date;
      if (filters.to_date) query.endDate = filters.to_date;
      if (filters.minAmount) query.minAmount = filters.minAmount;
      if (filters.maxAmount) query.maxAmount = filters.maxAmount;

      const { ok, data } = await api.post("/bank/search", query);
      if (!ok) return;
      const arr = [];
      data.banks.forEach((p, i) => {
        const obj = {};
        obj["Index"] = i;
        obj["Name"] = p.name || "";
        obj["Description"] = p.description || "";
        obj["Date"] = p.date || "";
        obj["Month"] = p.month || "";
        obj["Amount"] = p.amount || "";
        obj["Tax"] = p.tax || "";
        obj["Country"] = p.country || "";
        obj["Category"] = p.category || "";
        obj["SubCategory"] = p.sub_category || "";
        obj["Docs"] = p.docs || "";
        obj["Invoice Name"] = p.invoice_name || "";
        obj["Status"] = p.status || "";

        arr.push(obj);
      });
      exportCSV("statistiques", arr);
    } catch (error) {
      console.error(error);
      toast.error("Error while exporting");
    }
    setExporting(false);
  };

  return (
    <div className="px-8 py-12 space-y-4">
      <h1 className="text-2xl font-semibold">
        Banks
        <span className="text-4xl ml-4">CA AVANACE UN PEU</span>
      </h1>
      <BankModal />
      <div className="grid grid-cols-4 gap-4 mb-4">
        <DebounceInput
          debounce={300}
          value={filters.search}
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          className={`input ${filters.search ? "border-sky-500" : ""}`}
          placeholder="Search"
        />

        <select value={filters.card} onChange={(e) => setFilters({ ...filters, card: e.target.value })} className={`select ${filters.card ? "border-sky-500" : ""}`}>
          <option value="">Select a card</option>
          {CARDS.map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select value={filters.project} onChange={(e) => setFilters({ ...filters, project: e.target.value })} className={`select ${filters.project ? "border-sky-500" : ""}`}>
          <option value="">Select a project</option>
          {projects.map((item, i) => (
            <option key={i} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
        <select value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })} className={`select ${filters.status ? "border-sky-500" : ""}`}>
          <option value="">Status</option>
          <option value="DONE">Done</option>
          <option value="TODO">Todo</option>
        </select>
        <select value={filters.bank} onChange={(e) => setFilters({ ...filters, bank: e.target.value })} className={`select ${filters.bank ? "border-sky-500" : ""}`}>
          <option value="">Select a bank</option>
          {BANKS.map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select value={filters.category} onChange={(e) => setFilters({ ...filters, category: e.target.value })} className={`select ${filters.category ? "border-sky-500" : ""}`}>
          <option value="">Select a category</option>
          {CATEGORIES.map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <select
          value={filters.sub_category}
          onChange={(e) => setFilters({ ...filters, sub_category: e.target.value })}
          className={`select ${filters.sub_category ? "border-sky-500" : ""}`}>
          <option value="">Select a sub category</option>
          {SUBCATEGORIES.map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>

        <DebounceInput
          debounce={300}
          value={filters.minAmount}
          onChange={(e) => setFilters({ ...filters, minAmount: e.target.value })}
          className={`input ${filters.search ? "border-sky-500" : ""}`}
          placeholder="Min Amount"
        />

        <DebounceInput
          debounce={300}
          value={filters.maxAmount}
          onChange={(e) => setFilters({ ...filters, maxAmount: e.target.value })}
          className={`input ${filters.search ? "border-sky-500" : ""}`}
          placeholder="Max Amount"
        />
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-2 items-center">
        {Object.keys(filters)
          .filter((key) => filters[key] && !["page", "per_page"].includes(key))
          .map((key) => (
            <div key={key} className="flex items-center px-2 py-1 text-xs text-sky-800 rounded bg-sky-100">
              <p>
                {key}: {filters[key]}
              </p>
              <button onClick={() => setFilters({ ...filters, [key]: "" })} className="ml-2 rounded-full">
                <RiCloseLine />
              </button>
            </div>
          ))}
      </div>

      <Stats filters={filters} />
      <section>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex items-center justify-end mb-2 gap-4">
              <select value={filters.per_page} onChange={(e) => setFilters({ ...filters, per_page: e.target.value, page: 1 })} className="select w-24 h-8 py-1">
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <button className="blue-btn h-8" onClick={handleExport} disabled={exporting}>
                {exporting ? <Loader size="small" color="white" /> : "Export"}
              </button>
            </div>
            <TablePagination
              header={HEADER}
              total={total}
              loading={loading}
              page={filters.page}
              pageSize={filters.per_page}
              onPageChange={(page) => setFilters({ ...filters, page })}>
              {data.map((e, i) => (
                <Row key={i} data={e} />
              ))}
            </TablePagination>
          </>
        )}
      </section>
    </div>
  );
};

const Row = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRowClick = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("bank_id", data._id);
    setSearchParams(newSearchParams);
  };

  return (
    <tr className="h-14 hover:bg-gray-100 border-t border-gray-200 cursor-pointer" onClick={handleRowClick}>
      <td className="text-left p-2 text-sm">
        <p>{new Date(data.date).toLocaleDateString("fr")}</p>
        <p className="text-xs text-gray-700">{new Date(data.date).toLocaleTimeString("fr")}</p>
      </td>

      <td colSpan={3} className="text-left p-2 text-sm">
        <p className="font-semibold">{data.name?.substring(0, 20)}</p>
        <div className="flex items-center gap-2">
          {CATEGORIES_SPAN[data.category]} {SUBCATEOGORIES_SPAN[data.sub_category]}
        </div>
      </td>
      <td className="text-left p-2 text-sm">
        <div>{STATUS_SPAN[data.status]}</div>
      </td>

      <td colSpan={2} className="text-left p-2 text-sm">
        {data.projectName}
      </td>
      <td colSpan={2} className="text-left p-2 text-sm">
        <div className="flex items-center gap-2">
          {BANKS_SPAN[data.source]} <span className="text-xs border border-gray-700 bg-gray-50 text-gray-700 px-1 rounded">{data.type}</span>
        </div>
      </td>
      <td className={`text-right font-bold p-2 text-sm ${data.amount < 0 ? "text-red-600" : "text-green-600"}`}>
        {(data.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
      </td>
    </tr>
  );
};

const Switcher = () => {
  const [version, serVersion] = useState("new");
  return (
    <div className="">
      <div className="flex items-center gap-4">
        <button className={version === "old" ? "blue-btn" : "transparent-btn"} onClick={() => serVersion("old")}>
          Old version
        </button>
        <button className={version === "new" ? "blue-btn" : "transparent-btn"} onClick={() => serVersion("new")}>
          New version
        </button>
      </div>
      {version === "old" ? <All /> : <Bank />}
    </div>
  );
};

// export default Bank;
export default Switcher;
