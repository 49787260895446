import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiCalendar } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";

import api from "../../../services/api";
import { useSelector } from "react-redux";
import { useHolidayActivities } from "./hooks";

const Contracts = () => {
  const user = useSelector((state) => state.Auth.user);

  const [contractsStarting, setContractsStarting] = useState(null);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  useEffect(() => {
    getContractsStarting();
  }, []);

  async function getContractsStarting() {
    let thirtyDaysLater = new Date();
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 90);
    const res = await api.post("/user/search", { starting_date: thirtyDaysLater, sort: "starting_date" });
    if (!res.ok) return;
    setContractsStarting(res.data.users);
  }

  return (
    <div className="grid grid-cols-1 gap-8">
      {user.role === "admin" && (
        <>
          <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
            <div className="p-3 md:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Incoming arrivals</h1>
              <p className="mt-2 text-sm text-gray-700">A list of all the contracts that will start in the next 30 days</p>
            </div>
            <div className="mt-2 flow-root">
              <div className="overflow-x-auto">
                <div className="bg-white inline-block min-w-full align-middle">
                  {contractsStarting && contractsStarting.length > 0 && (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                            Name
                          </th>
                          <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                            Start date
                          </th>
                          <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                            Contract
                          </th>
                          <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                            Office
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {contractsStarting.map((item) => (
                          <tr key={item.name} className="even:bg-gray-100">
                            <td
                              className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 md:pl-3 pl-0 cursor-pointer group"
                              onClick={() => {
                                query.set("user_modal_id", item._id);
                                navigate({ search: query.toString() });
                              }}>
                              <div className="flex items-center gap-1">
                                <img src={item.avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                                <div>
                                  <div className="group-hover:underline">{item.name}</div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="flex items-center gap-1">
                                  <HiCalendar className="w-4 h-4" />
                                  <div>{moment(item.starting_date).format("DD MMM YYYY")}</div>
                                </div>
                                {/* <div className="text-xs text-gray-500">In {differenceDays(today, new Date(item.starting_date))} days</div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="flex items-center gap-1">
                                  <div>{item.contract ? item.contract : item.contracts && item.contracts.length !== 0 ? item.contracts[item.contracts.length - 1] : "-"}</div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="flex items-center gap-1">
                                  <div>{item.office ? item.office : "-"}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {contractsStarting && contractsStarting.length === 0 && <div className="p-3 text-gray-500">No entries in the next 2 weeks</div>}
                </div>
              </div>
            </div>
          </div>
          <ExitDate />
        </>
      )}
    </div>
  );
};

const ExitDate = ({}) => {
  const [contractsEnding, setContractsEnding] = useState([]);

  const { hollidayActivities }  = useHolidayActivities();
  const formattedHolidayActivities = hollidayActivities && hollidayActivities
    .sort((a, b) => {
      const nextHollidayA = differenceDays(new Date(), a.ranges[0].start);
      const nextHollidayB = differenceDays(new Date(), b.ranges[0].start);
      return nextHollidayA - nextHollidayB;
    })
    .filter((e) => differenceDays(new Date(), e.ranges[0].start) > 0)

  useEffect(() => {
    get();
  }, []);

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  async function get() {
    let later = new Date();
    later.setDate(later.getDate() + 90);

    const res = await api.post("/user/search", { exit_date: later, sort: "exit_date" });
    if (!res.ok) return;
    setContractsEnding(res.data.users);
  }

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
      <div className="p-3 md:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">Incoming exits</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the contracts that will end in the next 2 weeks</p>
      </div>
      <div className="mt-2 flow-root">
        <div className="overflow-x-auto">
          <div className="bg-white inline-block min-w-full align-middle">
            {contractsEnding && contractsEnding.length > 0 && (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                      Name
                    </th>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Exit date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {contractsEnding.map((item) => (
                    <tr key={item.name} className="even:bg-gray-100">
                      <td
                        className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 md:pl-3 pl-0 cursor-pointer group"
                        onClick={() => {
                          query.set("user_modal_id", item._id);
                          navigate({ search: query.toString() });
                        }}>
                        <div className="flex items-center gap-1">
                          <img src={item.avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                          <div>
                            <div className="group-hover:underline">{item.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div className="flex flex-1 flex-col gap-1">
                          <div className="flex items-center gap-1">
                            <HiCalendar className="w-4 h-4" />
                            <div>{moment(item.exit_date).format("DD MMM YYYY")}</div>
                          </div>
                          <div className="text-xs text-gray-500">In {differenceDays(today, new Date(item.exit_date))} days
                            {formattedHolidayActivities && formattedHolidayActivities.find((e) => e.userId === item._id) && (
                              <span className="text-red-900"> ({
                                formattedHolidayActivities
                                  .find((e) => e.userId === item._id).ranges
                                  .reduce((acc, range) => acc + differenceDays(range.start, range.end) + 1, 0)} days off left)</span>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {contractsEnding && contractsEnding.length === 0 && <div className="p-3 text-gray-500">No exits in the next 2 weeks</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const differenceDays = (date1, date2) => {
  let difference = date2.getTime() - date1.getTime();
  let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return totalDays;
};

export default Contracts;
