import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { IoInformationCircleOutline } from "react-icons/io5";
import { toast } from "react-hot-toast";
import api from "../../../../services/api";

import About from "./about";
import Raw from "./raw";
import Contacts from "./contacts";
import Companies from "./companies";

import { classNames } from "../../../../utils";

export default ({}) => {
  const [tab, setTab] = useState("contact");
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    get();
  }, []);

  const { id } = useParams();

  console.log(`id`, id);

  async function get() {
    try {
      const { data, ok } = await api.get(`/s_campaign/${id}`);
      console.log(data, ok);
      if (!ok) return toast.error("Error fetching campaign");
      setCampaign(data);
    } catch (e) {
      console.log(e);
      toast.error("Error fetching campaign");
    }
  }

  console.log(`campaign`, campaign);

  if (!campaign) return null;

  return (
    <div className="w-full h-[90vh] bg-[#FBFBF9] p-2 overflow-y-auto">
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
        <div className="flex items-center gap-2 p-4">
          <div className="flex justify-between flex-1">
            <div className="flex items-center gap-2">
              <div>
                <div className="text-lg font-bold">Campaign : {campaign.name}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-x-4">
              <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
              <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(campaign.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
              <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
              <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(campaign.created_at).format("DD/MM/YYYY, HH:mm")}</span>
            </div>
          </div>
        </div>
        <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
          <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
          <TabItem title="Contacts" tab="contact" setTab={setTab} active={tab === "contact"} Icon={IoInformationCircleOutline} />
          <TabItem title="Companies" tab="company" setTab={setTab} active={tab === "company"} Icon={IoInformationCircleOutline} />
          <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
        </nav>
      </div>
      <div className="my-2" />
      {tab === "about" ? <About campaign={campaign} setCampaign={setCampaign} /> : null}
      {tab === "raw" ? <Raw campaign={campaign} setCampaign={setCampaign} /> : null}
      {tab === "contact" ? <Contacts campaign={campaign} setCampaign={setCampaign} /> : null}
      {tab === "company" ? <Companies campaign={campaign} setCampaign={setCampaign} /> : null}
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
