import React, { useEffect, useState } from "react";
import SelectUser from "../../../components/selectUser";
import toast from "react-hot-toast";

import api from "../../../services/api";

export default ({ project, setProject }) => {
  const [values, setValues] = useState(project);

  async function handleChange(event) {
    const { name, value } = event.target;
    console.log(name, value);
    setValues({ ...values, [name]: value });
  }

  async function onSubmit() {
    console.log(values);
    await api.put(`/project/${project._id}`, values);
    toast.success("Saved");
    setProject(values);
  }
  return (
    <div className="bg-white border-[1px]  overflow-hidden px-4">
      <div
        className="mt-8 inline-flex items-center mb-3"
        onClick={() => {
          handleChange({
            target: {
              value: [
                ...values.profitSharing,
                { name: "", percent: "", startDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().split("T")[0] },
              ],
              name: "profitSharing",
            },
          });
        }}>
        <span className="text-xl">Profit Sharing</span>
        <div className="rounded-full ml-2 bg-primary w-6 h-6 flex items-center justify-center text-white">+</div>
      </div>
      <div className="text-sm italic mb-3">changes will apply to all invoices starting this month</div>

      {values.profitSharing.map((profitShare, index) => {
        return (
          <>
            <div className="pb-5 mb-10 border-b">
              <div className="flex items-center">
                <input
                  className="projectsInput w-[180px] text-[14px] font-normal text-[#212325] rounded-[10px]"
                  placeholder="percent"
                  type="number"
                  name="profitSharingPercent"
                  value={profitShare.percent}
                  onChange={(event) => {
                    const newProfitShare = values.profitSharing.map((e, i) => {
                      if (index === i) {
                        return { ...e, percent: event.target.value };
                      }
                      return e;
                    });
                    handleChange({ target: { value: newProfitShare, name: "profitSharing" } });
                  }}
                />
                <span className="mx-2">% to </span>
                <SelectUser
                  // value={{ name: equity.name, _id: equity.userId }}
                  placeholder={profitShare.name}
                  onChange={(selectedUser) => {
                    const newProfitShare = values.profitSharing.map((e, i) => {
                      if (index === i) return { ...e, name: selectedUser.name, userId: selectedUser._id, userName: selectedUser.name, userAvatar: selectedUser.avatar };
                      return e;
                    });
                    handleChange({ target: { value: newProfitShare, name: "profitSharing" } });
                  }}
                />
                <span className="mx-2">Role : </span>
                <select
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px] w-[180px]"
                  name="profitSharingRole"
                  value={profitShare.role}
                  onChange={(event) => {
                    const newProfitShare = values.profitSharing.map((e, i) => {
                      if (index === i) return { ...e, role: event.target.value };
                      return e;
                    });
                    handleChange({ target: { value: newProfitShare, name: "profitSharing" } });
                  }}>
                  <option value="lead">lead</option>
                  <option value="business introducer">business introducer</option>
                </select>
                <button
                  className="ml-[10px] bg-[#F43F5E] text-[12px] text-[#fff] py-1 px-2 rounded-[10px]"
                  onClick={() => {
                    const newProfitShare = values.profitSharing.filter((e, i) => i !== index);
                    handleChange({ target: { value: newProfitShare, name: "profitSharing" } });
                  }}>
                  Remove
                </button>
              </div>
            </div>
          </>
        );
      })}
      <button className="ml-[10px] bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
        Update
      </button>
    </div>
  );
};
