import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi2";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import Pagination from "../../../components/Paginator";

export default () => {
  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState({ search: "", page: 1, pageSize: 30, status: "active" });
  const [jobs, setJobs] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, [filter]);

  async function load() {
    setLoading(true);
    const newFilter = { ...filter };

    try {
      const { ok, data, total } = await api.post("/job/search", newFilter);
      if (!ok) return console.error("Error fetching jobs");
      setJobs(data);
      setTotal(total);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-1">
      <section>
        <div className="flex flex-1 items-center gap-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <SelectStatus value={filter.status} onChange={(status) => setFilter({ ...filter, status })} />
            <Create />
          </div>
        </div>
      </section>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Job title
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Location
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Contract
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Created at
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Applicants
            </th>
            <th scope="col" className="flex flex-row items-center  px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Job posts {"("}
              <HiEye className="h-5 w-5 text-gray-300 " aria-hidden="true" />
              {")"}
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Account manager
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {(jobs || []).map((job, index) => (
            <Job job={job} />
          ))}
        </tbody>
      </table>
      <div className="flex w-fit">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.pageSize)} />
      </div>
    </div>
  );
};

const SelectStatus = ({ value, onChange }) => {
  return (
    <select className="form-select block w-full" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">All</option>
      <option value="active">Active</option>
      <option value="inactive">Archived</option>
    </select>
  );
};

const Job = ({ job }) => {
  const [job_post, setJob_post] = useState(null);

  async function get() {
    const { data } = await api.post("/jobpost/search", { job_id: job._id });
    setJob_post(data);
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <tr key={job._id} className="group hover:bg-gray-100">
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-center group-hover:underline">
          <Link to={`/job/${job._id}`} target="_blank">
            {job.title}
          </Link>
        </div>
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.location}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.contract_type}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.created_at.substring(0, 10)}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 "> {job.applicant_count_v2?.applicant || 0}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 ">
        {job_post?.map((job_post, index) => (
          <p key={index}>
            - {job_post.channel_name} {"("}
            {job_post.views}
            {")"}
          </p>
        ))}
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.user_name}</td>
    </tr>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ project_id: "62fb3ecdf4d045068e31a78d" });
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);

  async function onCreate() {
    if (!values.title) return toast.error("Missing title !");
    const res = await api.post("/job", values);

    let obj = {};
    obj.job_id = res.data._id;
    obj.job_title = res.data.title;

    obj.user_id = user._id;
    obj.user_name = user.name;
    obj.user_avatar = user.avatar;

    obj.channel_id = "650c0ae98e232b2a9879d6c0";
    obj.channel_name = "Accounting";

    const jobPost = await api.post("/jobpost", obj);
    toast.success("Created!");

    navigate(`/job/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create a job
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Title (*)</div>
          <input className="projectsInput block" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
