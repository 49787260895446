import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/modal";
import api from "../../../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import SelectUsers from "../../../../../components/selectUser";
import toast from "react-hot-toast";

export default function BulkActionsModal({ isOpen, onClose, selectedNotions, projectId }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [budgets, setBudgets] = useState([]);
  const [actions, setActions] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        const { data } = await api.post("/budget/search", { projectId, status: "active" });
        setBudgets(data);
      } catch (e) {
        console.error("Error fetching budgets:", e);
      }
    };

    fetchBudgets();
  }, [projectId]);

  const handleSelectChange = (key, value) => {
    setActions((prev) => ({
      ...prev,
      [key]: value || undefined,
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const ids = Object.entries(selectedNotions)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
      if (actions.budget_id) {
        if (actions.budget_id === "no_budget") {
          actions.budget_id = "";
          actions.budget_name = "";
        } else {
          const budget = budgets.find((budget) => budget._id === actions.budget_id);
          actions.budget_name = budget.name;
        }
      }
      for (const id of ids) {
        await api.put(`/notion/${id}`, actions);
      }
      toast.success(`${ids.length} tickets updated`);

      navigate(search);
      onClose();
    } catch (e) {
      console.error("Error saving bulk actions:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Bulk actions">
      <div className="p-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold">Set priority</h3>
            <select className="border border-gray-300 rounded p-2" onChange={(e) => handleSelectChange("priority", e.target.value)}>
              <option value="">Select</option>
              <option value="P0">P0</option>
              <option value="P1">P1</option>
              <option value="P2">P2</option>
              <option value="P3">P3</option>
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold">Set status</h3>
            <select className="border border-gray-300 rounded p-2" onChange={(e) => handleSelectChange("status", e.target.value)}>
              <option value="">Select</option>
              <option value="TODO">To do</option>
              <option value="READY_TO_DEV">Ready to dev</option>
              <option value="IN_PROGRESS">In progress</option>
              <option value="TESTING">Testing</option>
              <option value="DONE">Done</option>
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold">Set category</h3>
            <select className="border border-gray-300 rounded p-2" onChange={(e) => handleSelectChange("category", e.target.value)}>
              <option value="">Select</option>
              <option value="BUG">Bug</option>
              <option value="FEATURE">Feature</option>
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold">Set budget</h3>
            <select className="border border-gray-300 rounded p-2" onChange={(e) => handleSelectChange("budget_id", e.target.value)}>
              <option value="">Select</option>
              <option value="no_budget">No budget</option>
              {budgets.map((budget) => (
                <option key={budget._id} value={budget._id}>
                  {budget.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold">Set assignees</h3>
            <div className="flex gap-2">
              <SelectUsers
                name="people"
                className="projectsInput w-full"
                placeholder="Set assignee"
                onChange={(user) => {
                  if (user._id) {
                    setActions((prev) => ({
                      ...prev,
                      users: [
                        ...(prev.users || []),
                        {
                          user_id: user._id,
                          user_name: user.name,
                          user_avatar: user.avatar,
                          user_email: user.email,
                        },
                      ],
                    }));
                  }
                }}
              />
            </div>
            <div className="flex gap-2">
              {actions.users &&
                actions.users.map((e) => (
                  <div key={e.user_avatar} className="group relative">
                    <span className=" opacity-0 group-hover:opacity-100 absolute -top-2 left-0">x</span>
                    <img
                      className="h-6 w-6 rounded-full"
                      src={e.user_avatar}
                      alt={e.user_name}
                      onClick={async () => {
                        try {
                          setActions((prev) => ({
                            ...prev,
                            users: prev.users.filter((user) => user.user_id !== e.user_id),
                          }));
                        } catch (e) {
                          console.error("Error removing assignee:", e);
                        }
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-4">
          <button className="px-4 py-2 border border-gray-300 rounded" onClick={onClose}>
            Cancel
          </button>
          <button className="blue-btn" onClick={handleSave} disabled={loading}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}
