import moment from "moment";
import React from "react";
import { HiCalendar } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";

import Loader from "../../../components/loader";

import { differenceDays, useHolidayActivities } from "./hooks";

const DaysOff = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  const { hollidayActivities, peopleCurrentlyOff } = useHolidayActivities();

  if (!peopleCurrentlyOff) return <Loader />;

  return (
    <div className="grid grid-cols-1 gap-8">
      <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
        <div className="p-3 flex items-center justify-between">
          <div className="md:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">{peopleCurrentlyOff?.length} people off</h1>
            <p className="mt-2 text-sm text-gray-700">A list of all the users currently not working</p>
          </div>
          <a
            href="/days-off"
            target="_blank"
            rel="noreferrer"
            className="hover:no-underline border-[1px] border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-[#ffffff] py-0.5 px-2.5 rounded-full text-base font-normal flex items-center gap-1">
            <HiCalendar /> Calendar view
          </a>
        </div>
        <div className="mt-2 flow-root">
          <div className="overflow-x-auto">
            <div className="bg-white inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                      Name
                    </th>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Dates
                    </th>
                    {/* <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Duration
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {peopleCurrentlyOff.map((item) => (
                    <tr key={item.userName} className="even:bg-gray-100">
                      <td
                        className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 md:pl-3 pl-0 cursor-pointer group"
                        onClick={() => {
                          query.set("user_modal_id", item.userId);
                          navigate({ search: query.toString() });
                        }}>
                        <div className="flex items-center gap-1">
                          <img src={item.userAvatar} alt="userlogo" className="rounded-full w-7 h-7" />
                          <div>
                            <div className="group-hover:underline">{item.userName}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div className="flex flex-1 flex-col gap-1">
                          {item.ranges.map((range) => {
                            if (range.start === range.end)
                              return (
                                <div className="" key={range.start.getTime()}>
                                  {moment(range.start).format("DD MMMM YYYY")}
                                </div>
                              );
                            return (
                              <div key={range.start.getTime()}>
                                <div className="flex items-center gap-1">
                                  {moment(range.start).format("DD MMM YYYY")} ... {moment(range.end).format("DD MMM YYYY")} ({differenceDays(range.start, range.end) + 1} days)
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
        <div className="p-3 md:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Incoming days off</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all the users that will be not working in the near future</p>
        </div>
        <div className="mt-2 flow-root">
          <div className="overflow-x-auto">
            <div className="bg-white inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                      Name
                    </th>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Dates
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {hollidayActivities
                    .sort((a, b) => {
                      const nextHollidayA = differenceDays(new Date(), a.ranges[0].start);
                      const nextHollidayB = differenceDays(new Date(), b.ranges[0].start);
                      return nextHollidayA - nextHollidayB;
                    })
                    .filter((e) => differenceDays(new Date(), e.ranges[0].start) > 0)
                    .map((item) => (
                      <tr key={item.userName} className="even:bg-gray-100">
                        <td
                          className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 md:pl-3 pl-0 cursor-pointer group"
                          onClick={() => {
                            query.set("user_modal_id", item.userId);
                            navigate({ search: query.toString() });
                          }}>
                          <div className="flex items-center gap-1">
                            <img src={item.userAvatar} alt="userlogo" className="rounded-full w-7 h-7" />
                            <div>
                              <div className="group-hover:underline">{item.userName}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                          <div className="flex flex-1 flex-col gap-1">
                            {item.ranges.map((range) => {
                              if (range.start === range.end)
                                return (
                                  <div className="" key={range.start.getTime()}>
                                    {moment(range.start).format("DD MMMM YYYY")}
                                  </div>
                                );
                              return (
                                <div key={range.start.getTime()}>
                                  <div className="flex items-center gap-1">
                                    {moment(range.start).format("DD MMM YYYY")} ... {moment(range.end).format("DD MMM YYYY")} ({differenceDays(range.start, range.end) + 1} days)
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default DaysOff;
