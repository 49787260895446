import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export default ({ values, onChange }) => {
  return (
    <MultiSelect
      values={values}
      id="select-contract"
      options={[
        { value : "CDD", label: "CDD" },
        { value : "CDI", label: "CDI" },
        { value : "INTERNSHIP", label: "INTERNSHIP" },
        { value : "APPRENTICESHIP", label: "APPRENTICESHIP" },
        { value : "FREELANCE", label: "FREELANCE" },
        { value : "VIE", label: "VIE" },

      
      ]}
      onSelectedChange={onChange}
      placeholder="Select a contract"
    />
  );
};
