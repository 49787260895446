import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/loader";

export default function ({ notion, onClick }) {
  console.log("🚀 ~ notion:", notion);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const user = useSelector((state) => state.Auth.user);

  const PriorityTag = () => {
    if (!notion?.priority) return null;
    const colors = {
      P0: "bg-green-100 text-xs text-green-800",
      P1: "bg-yellow-100 text-xs text-yellow-800",
      P2: "bg-orange-100 text-xs text-orange-800",
      P3: "bg-red-100 text-xs text-red-800",
    };
    return <div className={`px-2 py-1 rounded w-fit ${colors[notion?.priority]}`}>{notion?.priority}</div>;
  };
  const CategoryTag = () => {
    if (!notion?.category) return null;
    const colors = {
      FEATURE: "bg-blue-100 text-xs text-blue-800",
      BUG: "bg-red-100 text-xs text-red-800",
    };
    return <div className={`px-2 py-1 rounded w-fit ${colors[notion?.category]}`}>{notion?.category}</div>;
  };

  if (!user) return <Loader />;

  return (
    <div className="w-[350px]">
      <div
        className="p-3 py-2 mb-2 flex flex-col justify-center bg-gray-50 rounded shadow-md cursor-pointer border border-gray-200"
        onClick={() => {
          query.set("task_modal_id", notion._id);
          navigate({ search: query.toString() });
        }}>
        <div className="flex gap-2 justify-between items-center">
          <div className={"flex items-center gap-1 text-sm font-bold whitespace-nowrap text-ellipsis overflow-hidden w-full mb-2"}>
            #{notion?.index}&nbsp;-&nbsp;
            {notion?.name}
          </div>
          <PriorityTag />
        </div>
        <div className="mb-1">
          <CategoryTag />
        </div>

        <div>{notion.budget_name}</div>
        {/* {Boolean(notion?.estimated_price) && <div className="text-xs mb-3">{(notion?.estimated_price).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>} */}
        {/* effort_tshirt_size ? */}
        {notion?.effort_tshirt_size && (
          <div className="text-xs mb-3 gap-1 flex items-center">
            Effort<p className=" bg-gray-500/70 text-white px-1 font-medium rounded-lg">{notion?.effort_tshirt_size}</p>
          </div>
        )}
        <div className="flex flex-wrap">
          {notion.users?.map((e) => (
            <div>
              <img key={e.user_avatar} className="h-6 w-6 rounded-full mr-1" src={e.user_avatar} alt={e.user_name} />
              {/* <span className="text-xs">{e.user_name}</span> */}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className={"text-xs text-gray-500"}>{`${daysAgo(notion?.created_at)} days ago`}</div>
          <div className="flex items-center">{notion?.leader_id && <img src={notion?.leader_avatar} className="w-6 h-6 rounded-full" alt={notion?.leader_name} />}</div>
        </div>
      </div>
    </div>
  );
}

const daysAgo = (date) => {
  const today = new Date();
  const diff = today - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};
