import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoInformationCircleOutline } from "react-icons/io5";

import Modal from "../modal";
import { classNames } from "../../utils";
import api from "../../services/api";

import About from "./about";
import Raw from "./raw";
import toast from "react-hot-toast";

export default ({ action, setAction }) => {
  const [tab, setTab] = useState("about");

  // console.log("contact", contact);

  if (!action) return <></>;

  return (
    <Modal isOpen={true} onClose={() => setAction(null)}>
      <div className="w-full h-[90vh] bg-[#FBFBF9] p-2 overflow-y-auto">
        <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
          <div className="flex items-center justify-between gap-2 p-2 bg-white shadow rounded-lg">
            <div className="flex items-center flex-1">
              <TitleInput action={action} className="font-medium text-lg" />
              <div className="text-sm text-gray-500 ml-2">{action.location}</div>
            </div>
            <div className="flex items-center gap-4">
              <SelectStatus action={action} setAction={setAction} />
              <div className="w-full">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-600">Created</span>
                    <span className="text-sm text-gray-600">{moment(action.created_at).format("DD/MM/YYYY, HH:mm")}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-600">Deadline</span>
                    <span className="text-sm text-gray-600">{moment(action.deadline).format("DD/MM/YYYY, HH:mm")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
            <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
          </nav>
        </div>
        <div className="my-2" />
        {tab === "about" ? <About action={action} setAction={setAction} /> : null}
        {tab === "raw" ? <Raw action={action} /> : null}
      </div>
    </Modal>
  );
};

const SelectStatus = ({ action, setAction }) => {
  async function onSave(status) {
    const { data } = await api.put(`/s_action/${action._id}`, { status });
    toast.success("Status updated!");
    setAction(data);
  }

  return (
    <select
      className="w-full rounded-md border-[1px] bg-[#ffffff] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2"
      name="status"
      value={action.status}
      onChange={(e) => onSave(e.target.value)}>
      <option value="TODO">To do</option>
      <option value="PENDING">Pending</option>
      <option value="DONE">Done</option>
    </select>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

const TitleInput = ({ action }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    setName(action.name);
  }, []);

  async function onSave() {
    if (name === action.name) return;
    await api.put(`/s_action/${action._id}`, { name });
    toast.success("Title updated!");
  }

  return (
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name="title"
      value={name}
      onChange={(e) => setName(e.target.value)}
      placeholder={"Title"}
      onBlur={onSave}
    />
  );
};
