import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { LuDownload } from "react-icons/lu";

import SelectMonth from "../../../components/selectMonth";
import api from "../../../services/api";
import Modal from "../../../components/modal";
import { MONTHS, YEARS } from "../../../constants";
import Loader from "../../../components/loader";

const Breakdown = ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    year: Number(searchParams.get("year")) || "",
    month: Number(searchParams.get("month")) || "",
    budget: searchParams.get("budget") || "",
    invoice: searchParams.get("invoice") || "",
    type: searchParams.get("type") || "",
  });
  const [budgets, setBudgets] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [selected, setSelected] = useState([]);
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resBudgets = await api.post("/budget/search", { projectId: project._id, limit: 50 });
        if (resBudgets.ok) setBudgets(resBudgets.data);

        const resInvoices = await api.post("/invoice/search", { projectId: project._id, limit: 50 });
        if (resInvoices.ok) setInvoices(resInvoices.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching data");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (filters.type !== "bank") {
          const query = { projectId: project._id };

          if (filters.budget) query.budgetId = filters.budget;
          if (filters.year) {
            if (filters.month) {
              query.dateFrom = new Date(filters.year, filters.month, 1);
              query.dateTo = new Date(filters.year, filters.month + 1, 0);
            } else {
              query.dateFrom = new Date(filters.year, 0, 1);
              query.dateTo = new Date(filters.year, 11, 31);
            }
          }
          if (filters.invoice) query.invoiceId = filters.invoice;

          const { ok, data } = await api.post(`/activity/search`, query);
          if (ok) setActivities(data.filter((a) => a.value).map((e) => ({ ...e, type: "activity" })));
        } else {
          setActivities([]);
        }

        if (filters.type !== "activity") {
          const query = { projectId: project._id };
          query.notCategories = ["INCOME", "DISBURSEMENT"];
          query.per_page = 20000;
          query.budgetId = filters.budget;
          if (filters.year) {
            if (filters.month) {
              query.startDate = new Date(filters.year, filters.month, 1);
              query.endDate = new Date(filters.year, filters.month + 1, 0);
            } else {
              query.startDate = new Date(filters.year, 0, 1);
              query.endDate = new Date(filters.year, 11, 31);
            }
          }
          const { ok, data } = await api.post(`/bank/search`, query);
          if (ok) setBanks(data.banks.map((e) => ({ ...e, type: "bank", amount: -e.amount })));
        } else {
          setBanks([]);
        }

        // set search params
        const newSearchParams = new URLSearchParams();
        if (filters.year) newSearchParams.set("year", filters.year);
        if (filters.month) newSearchParams.set("month", filters.month);
        if (filters.budget) newSearchParams.set("budget", filters.budget);
        if (filters.invoice) newSearchParams.set("invoice", filters.invoice);
        if (filters.type) newSearchParams.set("type", filters.type);
        setSearchParams(newSearchParams);
      } catch (error) {
        toast.error("Error while refreshing");
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  const user = useSelector((state) => state.Auth.user);

  if (!["admin", "user"].includes(user.role)) return <div>Forbidden</div>;

  let total = activities.reduce((acc, a) => acc + a.value, 0);
  total = banks.reduce((acc, a) => acc + a.amount, total);

  const handleActivityChange = async (item) => {
    try {
      const budget = budgets.find((b) => b._id === item.budgetId);
      const invoice = invoices.find((i) => i._id === item.invoiceId);
      const values = { ...item, budgetId: budget?._id, budgetName: budget?.name, budgetYear: budget?.year, invoiceId: invoice?._id || null, invoiceName: invoice?.name || null };

      const res = await api.put(`/activity/${item._id}`, values);
      if (res.ok) {
        setActivities(activities.map((a) => (a._id === item._id ? { ...a, ...item } : a)));
        toast.success("updated");
      }
    } catch (error) {
      console.error(error);
      toast.error("error");
    }
  };

  const handleBankChange = async (item) => {
    try {
      const budget = budgets.find((b) => b._id === item.budgetId);
      const invoice = invoices.find((i) => i._id === item.invoiceId);
      const values = { ...item, budgetId: budget?._id, budgetName: budget?.name, budgetYear: budget?.year, invoiceId: invoice?._id || null, invoiceName: invoice?.name || null };

      const res = await api.put(`/bank/${item._id}`, values);
      if (res.ok) {
        setBanks(banks.map((a) => (a._id === item._id ? { ...a, ...item } : a)));
        toast.success("updated");
      }
    } catch (error) {
      console.error(error);
      toast.error("error");
    }
  };

  const handleChangeManyBudget = async (e) => {
    setUpdating(true);
    try {
      const budget = budgets.find((b) => b._id === e.target.value);
      if (!budget) return;

      const update = { budgetId: budget._id, budgetName: budget.name, budgetYear: budget.year };

      for (const item of selected) {
        if (item.type === "activity") {
          const { ok } = await api.put(`/activity/${item._id}`, update);
          if (!ok) throw new Error("error");
        } else {
          const { ok } = await api.put(`/bank/${item._id}`, update);
          if (!ok) throw new Error("error");
        }
      }

      const aIds = selected.filter((s) => s.type === "activity").map((s) => s._id);
      const bIds = selected.filter((s) => s.type === "bank").map((s) => s._id);
      setActivities(activities.map((a) => (aIds.includes(a._id) ? { ...a, ...update } : a)));
      setBanks(banks.map((a) => (bIds.includes(a._id) ? { ...a, ...update } : a)));

      toast.success("updated");
    } catch (error) {
      console.error(error);
      toast.error("Error while updating");
    }
    setUpdating(false);
  };

  const handleChangeManyInvoice = async (e) => {
    setUpdating(true);
    try {
      const invoice = invoices.find((b) => b._id === e.target.value);
      if (!invoice) return;

      const update = { invoiceId: invoice._id, invoiceName: invoice.name };

      for (const item of selected) {
        if (item.type === "activity") {
          const { ok } = await api.put(`/activity/${item._id}`, update);
          if (!ok) throw new Error("error");
        } else {
          const { ok } = await api.put(`/bank/${item._id}`, update);
          if (!ok) throw new Error("error");
        }
      }

      const aIds = selected.filter((s) => s.type === "activity").map((s) => s._id);
      const bIds = selected.filter((s) => s.type === "bank").map((s) => s._id);
      setActivities(activities.map((a) => (aIds.includes(a._id) ? { ...a, ...update } : a)));
      setBanks(banks.map((a) => (bIds.includes(a._id) ? { ...a, ...update } : a)));

      toast.success("updated");
    } catch (error) {
      console.error(error);
      toast.error("Error while updating");
    }
    setUpdating(false);
  };

  const handleBudgetCreated = (budget) => {
    setBudgets([...budgets, budget]);
    const newActivities = [...activities];
    for (const activity of newActivities) {
      const s = selected.find((s) => s._id === activity._id);
      if (s) {
        activity.budgetId = budget._id;
        activity.budgetName = budget.name;
        activity.budgetYear = budget.year;
      }
    }
    setActivities(newActivities);
    const newBanks = [...banks];
    for (const bank of newBanks) {
      const s = selected.find((s) => s._id === bank._id);
      if (s) {
        bank.budgetId = budget._id;
        bank.budgetName = budget.name;
        bank.budgetYear = budget.year;
      }
    }
    setBanks(newBanks);
  };

  if (loading) return <Loader />;

  const totalPrice = activities.reduce((acc, a) => acc + a.value, 0) + banks.reduce((acc, a) => acc + a.amount, 0);

  return (
    <div className="py-4 space-y-6">
      <div className="flex items-center gap-4 justify-end">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="year">
            Year
          </label>
          <select value={filters.year} onChange={(e) => setFilters({ ...filters, year: e.target.value })} className="select">
            <option value="">Select year</option>
            {YEARS.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        {filters.year && (
          <div className="space-y-2 w-full">
            <label className="block text-sm font-medium text-gray-700" htmlFor="month">
              Month
            </label>

            <select id="month" value={filters.month} onChange={(e) => setFilters({ ...filters, month: e.target.value })} className="select">
              <option value="">Select month</option>
              {MONTHS.map((e, i) => (
                <option key={i} value={i}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="budget">
            Budget
          </label>

          <select id="budget" value={filters.budget} onChange={(e) => setFilters({ ...filters, budget: e.target.value })} className="select">
            <option value="">Select budget</option>
            {budgets.map((e, i) => (
              <option key={i} value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="invoice">
            Invoice
          </label>
          <select value={filters.invoice} onChange={(e) => setFilters({ ...filters, invoice: e.target.value })} className="select">
            <option value="">Select invoice</option>
            {invoices.map((e, i) => (
              <option key={i} value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="type">
            Type
          </label>
          <select value={filters.type} onChange={(e) => setFilters({ ...filters, type: e.target.value })} className="select">
            <option value="">Select type</option>
            <option value="activity">Activity</option>
            <option value="bank">Bank</option>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-4 justify-between h-12">
        {selected.length > 0 ? (
          <h2 className="text-xl font-semibold">{selected.length} selected</h2>
        ) : (
          <h2 className="text-xl font-semibold">{activities.length + banks.length} items found</h2>
        )}

        <div className="flex items-center gap-2">
          {updating && <Loader size="small" />}
          {selected.length > 0 && (
            <>
              <select onChange={(e) => handleChangeManyBudget(e)} className="select w-40" disabled={updating}>
                <option value="">Change budget</option>
                {budgets.map((e, i) => (
                  <option key={i} value={e._id}>
                    {e.name}
                  </option>
                ))}
              </select>

              <select onChange={(e) => handleChangeManyInvoice(e)} className="select w-40" disabled={selected.length === 0 || updating}>
                <option value="">Select invoice</option>
                {invoices.map((e, i) => (
                  <option key={i} value={e._id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </>
          )}
          <CreateBudget project={project} selected={selected} onDone={handleBudgetCreated} />

          <CreateInvoice selected={selected} project={project} budgets={budgets} />
        </div>
      </div>
      {loading ? (
        <div>loading...</div>
      ) : (
        <div className="w-full p-6 border border-gray-300 rounded">
          <table className="table-fixed">
            <thead className="border-b border-gray-300">
              <tr>
                <th colSpan={2} className="p-2">
                  <div className="flex items-center gap-2">
                    {selected.length === 0 ? (
                      <input type="checkbox" className="checkbox mr-2" checked={false} onChange={() => setSelected([...activities, ...banks])} />
                    ) : selected.length === activities.length + banks.length ? (
                      <input type="checkbox" className="checkbox mr-2" checked={true} onChange={() => setSelected([])} />
                    ) : (
                      <div className="w-4 h-4 rounded mr-2 border border-gray-400 flex items-center justify-center" onClick={() => setSelected([])}>
                        <span className="bg-gray-400 w-2 h-0.5 rounded" />
                      </div>
                    )}
                    <p className="text-left font-semibold text-sm">Name</p>
                  </div>
                </th>
                <th className="text-left font-semibold text-sm p-2">Date</th>
                <th className="text-right font-semibold text-sm p-2">TJM</th>
                <th className="text-right font-semibold text-sm p-2">Days</th>
                <th className="text-right font-semibold text-sm p-2">Cost</th>
                <th colSpan={2} className="text-left font-semibold text-sm p-2">
                  Budget
                </th>
                <th colSpan={2} className="text-left font-semibold text-sm p-2">
                  Invoice
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activities.length + banks.length > 0 ? (
                <>
                  {activities.map((item, index) => (
                    <ActivityRow
                      key={index}
                      item={item}
                      budgets={budgets}
                      invoices={invoices}
                      selected={selected.some((s) => s._id === item._id)}
                      onChange={handleActivityChange}
                      onSelect={(e) => setSelected(e ? [...selected, item] : selected.filter((s) => s._id !== item._id))}
                    />
                  ))}
                  {banks.map((item) => (
                    <BankRow
                      key={item._id}
                      item={item}
                      budgets={budgets}
                      invoices={invoices}
                      selected={selected.some((s) => s._id === item._id)}
                      onChange={handleBankChange}
                      onSelect={(e) => setSelected(e ? [...selected, item] : selected.filter((s) => s._id !== item._id))}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={8} className="p-2 text-sm text-center text-gray-400">
                    No activity found
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className="border-t border-gray-300">
              <tr>
                <td colSpan={5} className="p-2 text-sm font-semibold">
                  Total
                </td>
                <td className="text-right p-2 text-sm font-semibold">{totalPrice.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

const ActivityRow = ({ item, budgets, invoices, selected, onChange, onSelect }) => {
  return (
    <tr className="hover:bg-gray-100">
      <td colSpan={2} className="p-2 text-sm">
        <div className="flex items-center gap-2">
          <input type="checkbox" className="checkbox mr-2" checked={selected} onChange={(e) => onSelect(e.target.checked)} />
          <div className="flex-1 space-y-1">
            <p className="font-semibold">{item.userName}</p>
            <p className="text-xs text-gray-600">{item.userJobTitle}</p>
          </div>
        </div>
      </td>
      <td className="text-left p-2 text-sm">{new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })} </td>
      <td className="text-right p-2 text-sm">{(item.userTjms || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
      <td className="text-right p-2 text-sm">{(item.total / 8).toFixed(2)}</td>
      <td className="text-right p-2 text-sm">{(item.value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
      <td colSpan={2} className="p-2 text-sm">
        <select
          value={item.budgetId}
          onChange={(e) => onChange({ ...item, budgetId: e.target.value })}
          className={`flex-1 select ${!item.budgetId ? "border-red-800 bg-red-100" : "border-gray-200"}`}>
          <option value="">Select budget</option>
          {budgets.map((e, i) => (
            <option key={i} value={e._id}>
              {e.name}
            </option>
          ))}
        </select>
      </td>
      <td colSpan={2} className="p-2 text-sm">
        <select
          value={item.invoiceId}
          onChange={(e) => onChange({ ...item, invoiceId: e.target.value })}
          className={`flex-1 select ${!item.invoiceId ? "border-red-800 bg-red-100" : "border-gray-200"}`}>
          <option value="">Select invoice</option>
          {invoices.map((e, i) => (
            <option key={i} value={e._id}>
              {e.name}
            </option>
          ))}
        </select>
      </td>
      <td className="p-2 text-sm">
        <div className="flex items-center gap-2">
          {item.invoiceId ? (
            <Link to={`/invoice/${item.invoiceId}`} target="_blank" className="text-sm h-10 w-10 border border-gray-200 rounded-md flex items-center justify-center">
              <MdOutlineRemoveRedEye />
            </Link>
          ) : (
            <div className="w-10" />
          )}
          <div className="w-10" />
        </div>
      </td>
    </tr>
  );
};

const BankRow = ({ item, budgets, invoices, selected, onChange, onSelect }) => {
  return (
    <tr key={item._id}>
      <td colSpan={2} className="text-left p-2 text-sm">
        <div className="flex items-center gap-2">
          <input type="checkbox" className="checkbox mr-2" checked={selected} onChange={(e) => onSelect(e.target.checked)} />

          <p className="font-semibold">{item.name}</p>
        </div>
      </td>
      <td className="p-2 text-sm">{new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })} </td>
      <td colSpan={3} className="text-right p-2 text-sm">
        {item.amount.toLocaleString("fr", { style: "currency", currency: "EUR" })}
      </td>
      <td colSpan={2} className="p-2 text-sm">
        <select
          value={item.budgetId || ""}
          onChange={(e) => onChange({ ...item, budgetId: e.target.value })}
          className={`w-full border text-sm rounded-md h-10 ${!item.budgetId ? "border-red-800 bg-red-100" : "border-gray-200"}`}>
          <option value="">Select budget</option>
          {budgets.map((e, i) => (
            <option key={i} value={e._id}>
              {e.name}
            </option>
          ))}
        </select>
      </td>
      <td colSpan={2} className="p-2 text-sm">
        <select
          value={item.invoiceId || ""}
          onChange={(e) => onChange({ ...item, invoiceId: e.target.value })}
          className={`w-full border text-sm rounded-md h-10 ${!item.invoiceId ? "border-red-800 bg-red-100" : "border-gray-200"}`}>
          <option value="">Select invoice</option>
          {invoices.map((e, i) => (
            <option key={i} value={e._id}>
              {e.name}
            </option>
          ))}
        </select>
      </td>
      <td className="p-2 text-sm">
        <div className="flex items-center gap-2">
          {item.invoiceId ? (
            <Link to={`/invoice/${item.invoiceId}`} target="_blank" className="text-sm h-10 w-10 border border-gray-200 rounded-md flex items-center justify-center">
              <MdOutlineRemoveRedEye />
            </Link>
          ) : (
            <div className="w-10" />
          )}
          {(item.files || []).length ? (
            <a href={item.files[0]} target="_blank" className="text-sm h-10 w-10 border border-gray-200 rounded-md flex items-center justify-center">
              <LuDownload />
            </a>
          ) : (
            <div className="w-10" />
          )}
        </div>
      </td>
    </tr>
  );
};

const CreateBudget = ({ project, selected, onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    projectId: project._id,
    projectName: project.name,
    name: "",
    year: new Date().getFullYear(),
  });

  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async () => {
    if (!values.name) return toast.error("Missing title !");

    try {
      const res = await api.post("/budget", values);
      if (!res.ok) return toast.error("Failed to create budget");
      for (const item of selected) {
        if (item.type === "activity") await api.put(`/activity/${item._id}`, { budgetId: res.data._id, budgetName: res.data.name, budgetYear: res.data.year });
        else await api.put(`/bank/${item._id}`, { budgetId: res.data._id, budgetName: res.data.name, budgetYear: res.data.year });
      }
      toast.success("Budget created");
      onDone(res.data);
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to create budget");
    }
  };

  if (selected.length === 0)
    return (
      <button className="blue-btn" onClick={() => toast.success("Coming soon")} disabled>
        Create Budget
      </button>
    );
  return (
    <div>
      <button className="blue-btn" onClick={() => setOpen(true)}>
        Create budget
      </button>
      <Modal isOpen={open} className="w-2/3" onClose={() => setOpen(false)}>
        <div className="px-16 py-12 space-y-8">
          <h2 className="text-xl font-semibold mb-4">Create new budget</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-name">
                Name
              </label>
              <input id="budget-name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Budget name" className="input" />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-name">
                Year
              </label>
              <select id="budget-year" value={values.year} onChange={(e) => setValues({ ...values, year: e.target.value })} className="select">
                <option value="">Select year</option>
                {YEARS.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button className="gray-btn mr-2" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className="blue-btn" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const CreateInvoice = ({ selected, budgets }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    name: "",
    budgetId: "",
    date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    project_id: id,
  });

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.post("/invoice", values);
      if (!res.ok) return toast.error("Error while creating invoice");
      for (const item of selected) {
        await api.put(`/activity/${item._id}`, { invoiceId: res.data._id, invoiceName: res.data.name });
      }
      setOpen(false);
      navigate(`/invoice/${res.data._id}`);
      toast.success("Invoice created");
    } catch (error) {
      console.error(error);
      toast.error("Error while creating invoice");
    }
  };

  if (selected.length === 0)
    return (
      <button className="blue-btn" onClick={() => setOpen(true)} disabled>
        Create Invoice
      </button>
    );
  return (
    <div>
      <button className="blue-btn" onClick={() => setOpen(true)}>
        Create Invoice
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
        <div className="px-16 py-12 space-y-8">
          <h2 className="text-xl font-semibold mb-4">Create New Invoice</h2>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="invoice-name">
              Invoice name
            </label>
            <input id="invoice-name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Invoice name" className="input" />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="invoice-budget">
              Budget
            </label>
            <select id="invoice-budget" value={values.budgetId} onChange={(e) => setValues({ ...values, budgetId: e.target.value })} className="select">
              <option value="">Select budget</option>
              {budgets.map((e, i) => (
                <option key={i} value={e._id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="invoice-date">
              Invoice Date
            </label>
            <SelectMonth
              id="invoice-date"
              placeholder="Select month"
              indexDefaultValue={0}
              start={0}
              value={values.date}
              onChange={(e) => setValues({ ...values, date: e.target.value })}
            />
          </div>
          <div className="text-sm text-gray-500">
            {`Selected ${selected.length} items for a total of ${selected.reduce((acc, a) => acc + a.value, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}`}
          </div>
          <div className="mt-6 flex justify-end gap-2">
            <button className="gray-btn" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className="blue-btn" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Breakdown;
