import React, { Fragment, useEffect, useRef, useState } from "react";
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri";

const SearchSelect = ({ options, value, onSelect, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  useEffect(() => {
    if (!search) setIsOpen(false);
    else setIsOpen(true);
  }, [search]);

  return (
    <div className="relative w-full" ref={ref}>
      <div className="relative w-full">
        <input className="select w-full pr-9 truncate" onChange={(e) => setSearch(e.target.value)} placeholder={props.placeholder} value={search || value?.label || ""} />
        {value && (
          <button
            type="button"
            className="absolute inset-y-2 right-4 flex items-center pr-2"
            onClick={() => {
              onSelect(null);
              setIsOpen(false);
              setSearch("");
            }}>
            <RiCloseLine className="text-sm" />
          </button>
        )}
        <button className="absolute inset-y-2 right-0 flex items-center pr-2" onClick={() => setIsOpen(!isOpen)}>
          <RiArrowDownSLine className={`text-sm ${isOpen ? "transform rotate-180" : ""}`} />
        </button>
      </div>
      {isOpen && (
        <div
          className={`absolute z-20 my-1 max-h-64 text-base overflow-y-scroll border border-gray-200 rounded-md divide-y divide-gray-200 transition duration-100 ease-in bg-white shadow-lg ${
            props.className || "w-full"
          }`}>
          <ul className="list-none">
            {options
              .filter((option) => (search ? option.label.toLowerCase().includes(search.toLowerCase()) : true))
              .map((option, i) => (
                <Fragment key={i}>
                  {i !== 0 ? <div className="h-px mx-4 bg-gray-100" /> : null}
                  <li
                    className={`relative flex gap-4 cursor-default select-none list-none items-center justify-between px-4 py-3 text-sm hover:bg-gray-100 hover:text-blue-dark bg-white text-black`}
                    onClick={() => {
                      onSelect(option);
                      setIsOpen(false);
                      setSearch("");
                    }}>
                    <p className="truncate flex-1">{option.label}</p>
                    {option.count && <span>{option.count}</span>}
                  </li>
                </Fragment>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
