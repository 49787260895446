import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import toast from "react-hot-toast";
import api from "../../../../services/api";

export default ({ project, filters }) => {
  const [activities, setActivities] = useState([]);
  const [groupBy, setGroupBy] = useState("userName");

  useEffect(() => {
    getData();
  }, [filters.userName, filters.date, groupBy]);

  const getData = async () => {
    try {
      const { ok, data } = await api.post(`/activity/groupByMonth`, {
        projectId: project._id,
        groupBy: groupBy,
        userName: filters.userName,
        date: filters.date,
      });
      if (!ok) return toast.error("Error while fetching activities");
      setActivities(data);
    } catch (error) {
      toast.error("Error while fetching activities");
    }
  };

  if (!activities) return null;

  const keys = activities
    .map((a) => Object.keys(a).filter((k) => k !== "month"))
    .flat()
    .filter((v, i, a) => a.indexOf(v) === i);

  const colorPalette = [
    "#00aaff",
    "#0099cc",
    "#0066ff",
    "#3399ff",
    "#003366",
    "#66cccc",
    "#66ccff",
    "#009999",
    "#33cc99",
    "#006666",
    "#003399",
    "#6666ff",
    "#6633cc",
    "#6600ff",
    "#330066",
    "#6699ff",
    "#99ccff",
    "#66ffcc",
    "#336666",
    "#99cc99",
    "#ccffcc",
  ];

  // unique colors
  const getColor = (bar) => colorPalette[keys.indexOf(bar.id) % colorPalette.length];

  return (
    <div className="h-[40rem] w-full p-4">
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-sm font-medium">Activity breakdown (€) (5 last months)</h2>
        <select
          className="w-1/4 p-2 bg-gray-100 border border-gray-300 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mr-auto ml-10"
          onChange={(e) => setGroupBy(e.target.value)}>
          <option value="userName">By People</option>
          <option value="userJobTitle">By Type of Work</option>
        </select>
      </div>

      <ResponsiveBar
        data={activities}
        keys={keys}
        indexBy="month"
        margin={{ top: 50, right: 160, bottom: 80, left: 90 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={getColor}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: groupBy === "userName" ? "People" : "Type of Work",
          legendPosition: "middle",
          legendOffset: 42,
          format: (value) => {
            const date = new Date(`${value}-01`);
            return date.toLocaleString("en-US", { month: "long" });
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 20,
          tickRotation: 0,
          legend: "Cost",
          legendPosition: "middle",
          legendOffset: -80,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Services"
        barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      />
    </div>
  );
};
