import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { HiMagnifyingGlass, HiEllipsisVertical } from "react-icons/hi2";
import { GrFormClose } from "react-icons/gr";
import { FaShare } from "react-icons/fa";
import DebounceInput from "react-debounce-input";
import { Menu, Switch } from "@headlessui/react";

import Loader from "../../../components/loader";
import SelectProjects from "../components/SelectProjects";
import SelectJobs from "../components/SelectJobs";
import SelectJob from "../../../components/selectJob";
import SelectStatuses from "../components/SelectStatuses";
import SelectSector from "../components/SelectSector";
import SelectUser from "../components/selectUser";
import SelectContract from "../components/SelectContract";

import Pagination from "../components/Pagination";
import Modal from "../../../components/modal";
import Send from "../../../components/userModal/send";

import CvIcon from "../../../assets/cv.svg";
import GithubIcon from "../../../assets/github.svg";
import LinkedinIcon from "../../../assets/linkedin.svg";
import EntryLevel from "../../../assets/entryLevel.svg";

import api from "../../../services/api";

import UserCard from "./userCard";
import { copyToClipboard } from "../../../utils";
import SelectLocation from "../components/SelectLocations";

const ApplicantList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [total, setTotal] = useState(null);
  const [includeNotCompletApplicants, setIncludeNotCompletApplicants] = useState(false);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [filters, setFilters] = useState();

  const getusers = async () => {
    try {
      setLoading(true);
      const obj = {};
      if (filters.search) obj.search = filters.search;
      if (filters.projects) obj.project_ids = filters.projects.map((e) => e.value);
      if (filters.jobs) obj.job_ids = filters.jobs.map((e) => e.value);
      if (filters.statuses) obj.statuses = filters.statuses.map((e) => e.value);
      if (filters.sectors) obj.sectors = filters.sectors.map((e) => e.value);
      if (filters.job_location) obj.job_location = filters.job_location.map((e) => e.value);
      if (filters.contract) obj.contract = filters.contract.map((e) => e.value);
      if (filters.page) obj.page = filters.page;
      if (filters.per_page) obj.per_page = filters.per_page;
      if (filters.user) obj.user_id = filters.user._id;
      obj.sort = "-created_at";
      obj.role = "applicant";

      const { data } = await api.post(`/user/search`, obj);
      setLoading(false);
      setUsers(
        data.users.map((e) => {
          if (e.resume) return e;
          return { ...e, applicant_status: "NOT COMPLETED" };
        }),
      );
      setTotal(data.total);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!filters) return;
    getusers();
    query.set("search", filters.search);
    if (filters.page) query.set("page", filters.page);
    if (filters.per_page) query.set("per_page", filters.per_page);
    navigate({ pathname: location.pathname, search: query.toString() });
  }, [filters]);

  useEffect(() => {
    (async () => {
      const _filters = {
        search: "",
        page: 1,
        per_page: 100,
        statuses: [
          { value: "NEW", label: "NEW" },
          { value: "SHORTLIST", label: "SHORTLIST" },
          { value: "CONTACTED", label: "CONTACTED" },
          { value: "FINAL_LIST", label: "FINAL_LIST" },
          { value: "INTERVIEW", label: "INTERVIEW" },
          { value: "TECHNICAL_TEST", label: "TECHNICAL_TEST" },
          { value: "HIRE", label: "HIRE" },
        ],
      };
      if (query.get("search")) _filters.search = query.get("search");
      if (query.get("page")) _filters.page = query.get("page");
      setFilters(_filters);
    })();
  }, []);

  if (!users) return <Loader />;

  return (
    <div className="px-4 pt-2 ">
      <div className="flex justify-between flex-wrap gap-2">
        <div className="flex flex-col">
          <div className="flex gap-1 items-center">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                name="email"
                id="email"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search"
                value={filters.search}
                onChange={(e) => {
                  e.persist();
                  setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
                }}
              />
            </div>
            {loading ? <Loader size="small" /> : null}
          </div>
          <div className="flex items-center gap-2 flex-wrap mt-2">
            <SelectProjects values={filters.projects} onChange={(e) => setFilters((f) => ({ ...f, projects: e, page: 1 }))} />
            <SelectJobs values={filters.jobs} onChange={(e) => setFilters((f) => ({ ...f, jobs: e, page: 1 }))} />
            <SelectStatuses values={filters.statuses} onChange={(e) => setFilters((f) => ({ ...f, statuses: e, page: 1 }))} />
            <SelectSector values={filters.sectors} onChange={(e) => setFilters((f) => ({ ...f, sectors: e, page: 1 }))} />
            <SelectUser placeholder="Lead" value={{ name: filters.user }} onChange={(e) => setFilters({ ...filters, user: e })} />
            <SelectLocation values={filters.job_location} onChange={(e) => setFilters((f) => ({ ...f, job_location: e, page: 1 }))} />
            <SelectContract values={filters.contract} onChange={(e) => setFilters((f) => ({ ...f, contract: e, page: 1 }))} />

            <div
              className="p-2 cursor-pointer text-dull-grey text-sm flex items-center space-x-1.5 border rounded-lg bg-lightShade-grey hover:bg-gray-300"
              onClick={() => {
                const obj = {};
                if (filters.jobs) obj.jobs = filters.jobs?.map((e) => e.value).join(",");
                if (filters.statuses) obj.statuses = filters.statuses?.map((e) => e.value).join(",");
                if (filters.projects) obj.projects = filters.projects?.map((e) => e.value).join(",");
                if (filters.sector) obj.sector = filters.sector?.map((e) => e.value).join(",");
                if (filters.job_location) obj.job_location = filters.job_location?.map((e) => e.value).join(",");
                if (filters.contract) obj.contract = filters.contract?.map((e) => e.value).join(",");

                copyToClipboard("https://accounting.selego.co/job_pool/" + window.location.search + "&" + new URLSearchParams(obj).toString());
                toast.success("URL copied !");
              }}>
              <FaShare /> <span>Share public</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-2">
            <Create />
            <Export filters={filters} />
          </div>
          <div className="flex items-center gap-x-2 ">
            <span className="text-sm text-gray-500">Include Applicants without resume</span>
            <Switch
              checked={includeNotCompletApplicants}
              onChange={setIncludeNotCompletApplicants}
              className={`${
                includeNotCompletApplicants ? "bg-sky-900" : "bg-gray-300"
              } relative inline-flex h-5 w-9 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
              <span
                aria-hidden="true"
                className={`${
                  includeNotCompletApplicants ? "translate-x-4" : "translate-x-0"
                } pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>
      </div>
      <Filters filters={filters} setFilters={setFilters} />

      <div className="w-full overflow-x-auto bg-[#fff] rounded-[10px] mt-3">
        <table className="w-full min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <tr>
              <th className="px-3" />
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Job title</th>
              <th className="px-6 py-3">Status</th>
              <th className="px-6 py-3">Job Location</th>
              <th className="px-6 py-3">Channel</th>
              <th className="px-6 py-3">Followers</th>
              <th className="px-6 py-3">Profiles</th>
              <th className="px-6 py-3">Contract</th>
              <th className="px-6 py-3 whitespace-nowrap">Created At</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 whitespace-nowrap text-sm text-gray-500">
            {users
              .filter((e) => (includeNotCompletApplicants ? true : e.resume))
              .map((applicant) => (
                <tr
                  key={applicant._id}
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={() => {
                    query.set("user_modal_id", applicant._id);
                    query.set("user_modal_tab", "applicant");
                    navigate({ search: query.toString() });
                  }}>
                  <td className="p-2">
                    <div className={`px-4 py-2 text-sm`}>
                      <button
                        data-tip
                        data-for={`applicant-${applicant._id}`}
                        onClick={async () => {
                          const { ok, data } = await api.put(`/user/${applicant._id}`, {
                            applicant_status: "SHORTLIST",
                            user_id: user._id,
                            user_avatar: user.avatar,
                            user_name: user.name,
                          });
                          if (!ok) return toast.error("Some Error!");
                          toast.success("User shortlisted");
                          getusers();
                        }}
                        className="w-full h-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 text-sm border-[1px] border-blue-500 hover:border-transparent rounded">
                        Shortlist
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-2">
                    <div className="flex items-center">
                      <div className="w-10 h-10 rounded-full overflow-hidden shadow mr-2">
                        <img alt="profile" src={applicant.avatar} className="w-full h-full object-contain" />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <span>
                          {applicant.name}
                          {applicant.country && <span className="ml-1 text-xs font-medium text-dull-black"> - {applicant.country}</span>}
                        </span>
                        <div className="flex items-center space-x-2">
                          <img alt="level" src={EntryLevel} className="w-5 h-5" />
                          <p className="text-xs text-gray-500 capitalize whitespace-nowrap">{applicant.experience || <span className="text-gray-400 text-xs">Empty</span>}</p>
                        </div>
                        <Experience applicant={applicant} />
                        <Education applicant={applicant} />
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-2"> {applicant.job_title}</td>
                  <td className="px-6 py-2">
                    {" "}
                    <div className={`text-[10px] inline-block self-start text-white px-2 rounded-lg ${color(applicant.applicant_status)}`}>{applicant.applicant_status}</div>
                  </td>
                  <td className="px-6 py-2">
                    <div className="flex flex-wrap gap-2">
                      {applicant.job_location.map((location) => (
                        <span className="bg-gray-200 text-gray-500 rounded text-xs px-2 py-1">{location}</span>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-2"> {applicant.channel_name}</td>
                  <td className="px-6 py-2">
                    {applicant.applicant_status !== "NEW" && (
                      <div className="flex items-center">
                        <img src={applicant.user_avatar} className="w-8 h-8 rounded-full" alt={applicant.user_name} />
                        {applicant.followers.map((e) => (
                          <img key={e.user_avatar} className="h-8 w-8 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
                        ))}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-2">
                    <div className="flex space-x-2 items-center" onClick={(e) => e.stopPropagation()}>
                      {applicant.linkedin && (
                        <a href={applicant.linkedin} target="_blank" rel="noreferrer">
                          <img alt="linkedin" src={LinkedinIcon} className="w-6 h-6" />
                        </a>
                      )}
                      {applicant.github && (
                        <a href={applicant.github} target="_blank" rel="noreferrer">
                          <img alt="github" src={GithubIcon} className="w-6 h-6 rounded-full" />
                        </a>
                      )}
                      {applicant.cv && (
                        <a href="">
                          <img alt="cv" src={CvIcon} className="w-6 h-6 border-2 border-gray-300 rounded p-1" />
                        </a>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2">
                    <div className="flex flex-wrap gap-2">
                      <span className="bg-gray-200 text-gray-500 rounded text-xs px-2 py-1">{applicant.contract}</span>
                    </div>
                  </td>
                  <td className="px-6 py-2">{new Date(applicant.created_at).toLocaleString()}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        per_page={filters.per_page}
        total={total}
        onNext={() => setFilters((f) => ({ ...f, page: f.page + 1 }))}
        onPrevious={() => setFilters((f) => ({ ...f, page: f.page - 1 }))}
        currentPage={filters.page}
      />
    </div>
  );
};
3;
const Experience = ({ applicant }) => {
  if (!applicant?.linkedin_raw || !applicant.linkedin_raw?.experience?.length) return <></>;

  const data = applicant?.linkedin_raw?.experience[0];

  return (
    <div className="text-sm mb-2">
      <h3 className="text-base font-semibold">Experience :</h3>
      <div className="flex items-center justify-between">
        <span className="text-dull-black">{data?.title || ""}</span>
        <span className="text-dull-grey whitespace-nowrapw">{data?.duration || ""}</span>
      </div>
      <div className="text-dull-grey">
        {data?.company || ""} · {data?.type || ""}
      </div>
      <div className="text-dull-grey">{data?.location || ""}</div>
    </div>
  );
};

const Education = ({ applicant }) => {
  if (!applicant?.linkedin_raw || !applicant.linkedin_raw?.education?.length) return <></>;

  const data = applicant?.linkedin_raw?.education[0];

  return (
    <div className="text-sm mb-2">
      <h3 className="text-base font-semibold">Education :</h3>
      <div className="flex items-center justify-between">
        <span className="text-dull-black">{data?.degree || ""}</span>
        <span className="text-dull-grey whitespace-nowrap">{data?.duration || ""}</span>
      </div>
      <div className="text-dull-grey">{data?.school || ""}</div>
    </div>
  );
};

function color(status) {
  switch (status) {
    case "NEW":
      return "bg-purple-600";
    case "REJECT":
      return "bg-red-600";
    case "SHORTLIST":
      return "bg-blue-400";
    case "CONTACTED":
      return "bg-green-400";
    case "FINAL_LIST":
      return "bg-yellow-700";
    case "INTERVIEW":
      return "bg-yellow-400";
    case "TECHNICAL_TEST":
      return "bg-blue-700";
    case "HIRE":
      return "bg-green-700";
    default:
      return "bg-gray-300";
  }
}

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const navigate = useNavigate();

  async function onCreate() {
    try {
      if (!values.email) return toast.error("Missing email !");
      if (!values.name) return toast.error("Missing name !");
      values.role = "applicant";
      values.job_id = values.job._id;
      values.job_title = values.job.title;
      const { data, ok } = await api.post("/user", values);
      if (!ok) return toast.error("Wrong request");
      toast.success("Created!");
      setOpen(false);
      navigate(`/pool?user_modal_id=${data._id}&user_modal_tab=about`);
    } catch (error) {
      toast.error("Error: " + error.code);
    }
  }
  return (
    <div>
      <div className="text-right">
        <button className="blue-btn" onClick={() => setOpen(true)}>
          Create an applicant
        </button>
      </div>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="projectsInput" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Email</div>
          <input className="projectsInput" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Job post (*)</div>
          <SelectJob className="projectsInput" value={{ _id: values?.job?._id }} onChange={(e) => setValues({ ...values, job: e })} />
        </div>
        <button className="blue-btn mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

const Export = ({ filters }) => {
  const onClick = async () => {
    const obj = {};
    if (filters.search) obj.search = filters.search;
    if (filters.projects) obj.project_ids = filters.projects.map((e) => e.value);
    if (filters.jobs) obj.job_ids = filters.jobs.map((e) => e.value);
    if (filters.statuses) obj.statuses = filters.statuses.map((e) => e.value);
    if (filters.page) obj.page = filters.page;
    obj.per_page = 1000;
    obj.role = "applicant";
    const { data } = await api.post(`/user/search`, obj);

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Index;Name;Job;Date;Resume;Link\n`;

    for (let i = 0; i < data.users.length; i++) {
      const e = data.users[i];
      const str = `${i};${e.name};${e.job_title};${e.created_at?.substring(0, 10)};${e.resume};https://accounting.selego.co/public/applicant/${e._id};\n`;
      csvContent += str;
    }

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="blue-btn" onClick={onClick}>
      Export
    </button>
  );
};

const Filters = ({ filters, setFilters }) => {
  const arr = [];

  const Tag = ({ children, onClick }) => {
    return (
      <div className="flex items-center gap-2 p-1 text-xs text-[13px] rounded-lg bg-yellow-500 text-white cursor-pointer" onClick={onClick}>
        {children}
        <GrFormClose />
      </div>
    );
  };

  if (filters.projects?.length) {
    filters.projects.forEach((project) => {
      arr.push(
        <Tag onClick={() => setFilters((f) => ({ ...f, projects: f.projects.filter((_project) => _project.value !== project.value) }))}>
          <div>project: {project.label}</div>
        </Tag>,
      );
    });
  }
  if (filters.jobs?.length) {
    filters.jobs.forEach((job) => {
      arr.push(
        <Tag onClick={() => setFilters((f) => ({ ...f, jobs: f.jobs.filter((_job) => _job.value !== job.value) }))}>
          <div>job: {job.label}</div>
        </Tag>,
      );
    });
  }
  if (filters.statuses?.length) {
    filters.statuses.forEach((status) => {
      arr.push(
        <Tag onClick={() => setFilters((f) => ({ ...f, statuses: f.statuses.filter((_status) => _status.value !== status.value) }))}>
          <div>status: {status.label}</div>
        </Tag>,
      );
    });
  }

  return (
    <div className="flex gap-2 p-2">
      <div className="flex gap-2 flex-wrap">
        {arr.map((e) => (
          <div className="text-sm px-2 text-[13px] rounded-lg bg-yellow-500 text-white">{e}</div>
        ))}
      </div>
    </div>
  );
};

export default ApplicantList;
