import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "../../components/modal";
import Paginator from "../../components/Paginator";
import API from "../../services/api";
import Loader from "../../components/loader";

export default () => {
  const [subventions, setSubventions] = useState([]);
  const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = { ...filters };
    newFilters.skip = (filters.page - 1) * 100;
    const { data, total, ok } = await API.post("/subvention/search", newFilters);
    if (!ok) return toast.error("Error fetching subventions");
    setSubventions(data);
    setTotal(total);
    setLoading(false);
  }
  if (loading) {
    return (
      <div className="w-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-white h-full p-2 mb-2">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
      </div>
      <div>{total} items</div>
      {subventions.length === 0 && !loading && <div className="flex-1 bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}
      <div className="grid grid-cols-3 gap-3">
        {subventions.map((e) => (
          <Card key={e._id} data={e} setSelectedItem={setSelectedItem} setShowDetailsModal={setShowDetailsModal} />
        ))}
      </div>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
      {showDetailsModal && (
        <HelpModal
          data={selectedItem}
          onClose={() => {
            setShowDetailsModal(false);
            setSelectedItem(null);
          }}
          setSelectedItem={setSelectedItem}
          setShowDetailsModal={setShowDetailsModal}
        />
      )}
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);
  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};

const Card = ({ data, setSelectedItem, setShowDetailsModal }) => {
  return (
    <div
      className="p-4 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow bg-white hover:cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem(data);
        setShowDetailsModal(true);
      }}>
      <div className="flex-none w-full sm:w-1/3 h-1/2 sm:h-auto p-3">
        <img src={data.image} alt={data.name} className="w-full h-auto object-cover rounded-lg" />
      </div>
      <div className="flex-grow sm:w-2/3 p-3">
        <h4 className="text-xl font-semibold mb-2">{data.name}</h4>
        <div className="mb-4">
          <p className="text-gray-700">{data.type}</p>
        </div>
      </div>
    </div>
  );
};

const HelpModal = ({ data, onClose }) => {
  return (
    <Modal isOpen={true} onClose={onClose} className="p-4">
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
        <div className="flex items-center gap-2 p-4">
          <div className="flex justify-between flex-1">
            <div className="flex items-center gap-2">
              <div className="flex flex-row items-center gap-3">
                <img src={data?.image} alt={data?.name} className="w-auto h-32 rounded-sm" />
                <div className="text-lg font-bold">{data?.name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 mt-2 p-2">
        <div className="my-2" />
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Présentation du dispositif :</h3>
          <p>{data.description}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Type:</h3>
          <p>{data.type}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Montant de l’aide:</h3>
          <p>{data.amount}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Conditions d’attribution :</h3>
          <p>{data.condition}</p>
        </div>
        {data.website && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Website:</h3>
            <a href={data.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              {data.website}
            </a>
          </div>
        )}
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Coordonnées de l’organisme :</h3>
          <p>{data.address}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Thematic:</h3>
          <p>{data.thematic?.join(", ")}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Additional Information:</h3>
          <p>{data.infos}</p>
        </div>
      </div>
    </Modal>
  );
};
