import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillDislike, AiFillHeart } from "react-icons/ai";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import Modal from "../../../components/modal";
import Comments from "../../../components/Comments";
import FileInputStyled from "../../../components/FileInputStyled";
import SelectUser from "../../../components/selectUser";

import api from "../../../services/api";
import { classNames, copyToClipboard } from "../../../utils";

import { useSelector } from "react-redux";
import FeatureInput from "../components/FeatureInput";

export default ({ component, setComponent, refetch }) => {
  const [tab, setTab] = useState("details");

  const render = () => {
    if (tab === "details") return <Details component={component} setComponent={setComponent} />;
    if (tab === "edit") return <Edit component={component} setComponent={setComponent} refetch={refetch} />;
    if (tab === "raw") return <Raw component={component} />;
  };

  if (!component) return <div />;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        setComponent(null);
      }}
      className="max-w-6xl w-full p-4  max-h-[90vh]">
      <div className="flex-shrink-0 w-full overflow-x-scroll">
        <TabItem tab="details" title="Details" active={tab === "details"} setTab={setTab} />
        <TabItem tab="edit" title="Edit" active={tab === "edit"} setTab={setTab} />
        <TabItem tab="raw" title="Raw" active={tab === "raw"} setTab={setTab} />
      </div>
      <div className="flex-1 overflow-y-auto">{render()}</div>
    </Modal>
  );
};

const Details = ({ component, setComponent }) => {
  return (
    <div className="flex flex-col md:flex-row p-4">
      <div className="flex-auto md:w-2/3 h-3/4">
        <Carousel images={component.images} />
        {component.drive_id && (
          <div className="flex justify-center w-full aspect-video">
            <iframe width="100%" height={"100%"} className="rounded-lg object-cover" src={`https://drive.google.com/file/d/${component.drive_id}/preview`} allowFullScreen />
          </div>
        )}
        <FeedbackZone component={component} setComponent={setComponent} />
      </div>

      <div className="md:w-1/3 p-4  mt-4 md:mt-0 md:ml-4">
        <div>
          <h1 className="text-2xl font-bold mb-2">{component.name}</h1>
          <p className="text-gray-700 mb-4">{component.description}</p>

          <h2 className="text-xl font-semibold mb-2">Features and links</h2>
          <ul className="list-disc  mb-4">
            {component.features.map((feature, idx) => {
              return (
                <li key={idx} className="text-gray-700">
                  <div className="text-gray-500 mt-2 flex items-center gap-3">
                    {feature.includes("<a") && !feature.includes("<script") ? (
                      <div className="w-full" dangerouslySetInnerHTML={{ __html: feature }} />
                    ) : (
                      <span className="break-all">{feature}</span>
                    )}
                  </div>{" "}
                </li>
              );
            })}
          </ul>

          <span className="text-xs text-gray-500">Previously implemented by:</span>
          <div className="flex flex-row gap-3 p-2 flex-wrap">
            {(component.users || []).map((user, index) => {
              return (
                <div key={index}>
                  <img src={user.user_avatar} alt="User Avatar" className="w-8 h-8 rounded-full" />
                  <p className="text-xs font-semibold">{user.user_name}</p>
                </div>
              );
            })}
          </div>

          <button
            onClick={() => {
              copyToClipboard(window.location.href + `?component_id=${component._id}`);
              toast.success("Link copied!");
            }}
            className="btn btn-primary mt-4">
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

const Raw = ({ component }) => {
  return (
    <div className="p-4">
      <pre className="text-sm text-gray-500">{JSON.stringify(component, null, 2)}</pre>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

const Edit = ({ component, setComponent, refetch }) => {
  const [values, setValues] = useState(component || { features: [""] });

  useEffect(() => {
    if (!values.description && component) setValues(component);
  }, [component]);

  useEffect(() => {
    if (values?.features?.length === 0) setValues((prev) => ({ ...prev, features: [""] }));
  }, [values]);

  const onSave = async (id) => {
    const formatted = { ...values, features: values.features.filter((val) => val) };
    const { ok, data } = await api.put(`/uxui/${id}`, formatted);
    if (!ok) return toast.error("Failed to update component!");

    setValues(data);
    setComponent(data);
    refetch();
    toast.success("Component updated!");
  };

  return (
    <div className="space-y-4 p-4 ">
      <input
        type="text"
        className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
        placeholder="Component name"
        value={values.name || ""}
        onChange={(e) => setValues({ ...values, name: e.target.value })}
      />

      <h2>Description </h2>
      <textarea
        className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
        placeholder="Component description"
        lines={4}
        value={values.description || ""}
        onChange={(e) => setValues({ ...values, description: e.target.value })}
      />

      <h2>Features and links</h2>

      {values.features.map((feature, index) => (
        <FeatureInput key={index} feature={feature} index={index} values={values} setValues={setValues} />
      ))}

      <h2>Link of the video presentation</h2>
      <p className="text-red-700">Ensure the video is publicly accessible</p>
      <p className="text-gray-600">Exemple : video share link - https://drive.google.com/file/d/1y_TgGPdCcg6JZU2MVpSbuEujPKZuLFa3/view?usp=sharing</p>
      <p className="text-gray-600">Drive id : 1y_TgGPdCcg6JZU2MVpSbuEujPKZuLFa3</p>
      <input
        type="text"
        className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
        placeholder="Add the drive id..."
        value={values.drive_id}
        onChange={(e) => setValues({ ...values, drive_id: e.target.value })}
      />

      <div className="my-1">
        <p>Upload images of the components in the project</p>
        <div className="flex gap-1 overflow-x-auto mt-5">
          {values.images.map((image) => (
            <div className="relative max-h-40 flex-shrink-0" key={image}>
              <img src={image} className="h-full max-h-40 object-contain" />
              <div
                onClick={() => setValues((prev) => ({ ...prev, images: prev.images.filter((img) => img !== image) }))}
                className="h-4 w-4 rounded-full border absolute top-0 right-0 bg-gray-200 z-50 flex items-center justify-center hover:cursor-pointer ">
                &times;
              </div>
            </div>
          ))}
        </div>

        <div className="flex mt-5 items-center flex-col">
          <FileInputStyled title={"Upload an image"} onChange={(e) => setValues({ ...values, images: [...values.images, e.target.value[0]] })} name="image" folder="/uxui" />
        </div>
      </div>

      <h2 className="text-xl font-semibold my-2">Have already done it:</h2>

      <SelectUser
        userRoles={["admin", "normal", "applicant"]}
        onChange={(user) => {
          if (!user?._id) return;
          const newUsers = values.users || [];
          newUsers.push({
            user_id: user._id,
            user_name: user.name,
            user_avatar: user.avatar,
          });
          setValues({ ...values, users: newUsers });
        }}
      />

      <div className="flex flex-row gap-3 p-2 flex-wrap">
        {(values.users || []).map((user, index) => {
          return (
            <div key={index}>
              <img src={user.user_avatar} alt="User Avatar" className="w-20 h-20 rounded-full" />
              <p className="text-xs font-semibold">{user.user_name}</p>
              <button
                className="btn btn-error"
                onClick={() => {
                  const newUsers = values.users.filter((u) => u.user_id !== user.user_id);
                  setValues({ ...values, users: newUsers });
                }}>
                Delete
              </button>
            </div>
          );
        })}
      </div>

      <button
        onClick={async () => {
          const { ok } = await api.put(`/uxui/${component._id}`, values);
          if (!ok) {
            toast.error("Failed to save");
            return;
          }
          setComponent({ ...component, ...values });
          toast.success("Saved!");
          onSave(component._id);
          setIsOpen(false);
        }}
        className="btn btn-primary w-full p-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md">
        Save
      </button>

      <button
        className="btn btn-primary mt-4 bg-red-500 hover:bg-red-600"
        onClick={async (e) => {
          e.stopPropagation();
          if (!window.confirm("Are you sure you want to delete this component?")) return;
          const { ok } = await api.remove(`/uxui/${component._id}`);
          if (!ok) return toast.error("Failed to delete component");
          toast.success("Component deletetd!");
          setComponent(null);
          refetch();
        }}>
        Delete
      </button>
    </div>
  );
};

const Carousel = ({ images }) => {
  const [index, setIndex] = useState(0);

  if (!images || images.length === 0) return null;

  const handlePrev = () => {
    if (index > 0) setIndex(index - 1);
  };

  const handleNext = () => {
    if (index < images.length - 1) setIndex(index + 1);
  };

  return (
    <div className="relative w-full max-w-screen-lg mx-auto">
      <div className="relative h-0 pb-[75%] overflow-hidden">
        <img src={images[index]} alt="component" className="absolute top-0 left-0 w-full h-full object-contain rounded-md" />
      </div>

      {images.length > 1 && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-between px-4">
          <button className="bg-[#00000066] text-white p-2 rounded-md" onClick={handlePrev}>
            <BiChevronLeft />
          </button>
          <button className="bg-[#00000066] text-white p-2 rounded-md" onClick={handleNext}>
            <BiChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

const FeedbackZone = ({ component, setComponent }) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    addView();
    if (component.likes && component.likes.find((l) => l.user_id === user._id)) setLiked(true);
    if (component.dislikes && component.dislikes.find((l) => l.user_id === user._id)) setDisliked(true);
  }, [component]);

  const addView = async () => {
    const { data } = await api.post(`/uxui/${component._id}/view`);
  };

  const handleLikeToggle = async () => {
    const { data } = await api.post(`/uxui/${component._id}/like`);
    setComponent(data);
    setLiked(!liked);
  };
  const handleDislikeToggle = async () => {
    const { data } = await api.post(`/uxui/${component._id}/dislike`);
    setComponent(data);
    setDisliked(!disliked);
  };

  return (
    <div>
      <div className="flex items-center gap-2 mt-4">
        <button className="flex items-center justify-center bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full p-2 transition-colors duration-300" onClick={handleLikeToggle}>
          {liked ? <AiFillHeart className="text-red-500" /> : <AiFillHeart />}
        </button>
        <button
          className="flex items-center justify-center bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full p-2 transition-colors duration-300"
          onClick={handleDislikeToggle}>
          {disliked ? <AiFillDislike className="text-blue-500" /> : <AiFillDislike />}
        </button>
      </div>
      <Comments
        value={component.comments}
        onAdd={async (e) => {
          const { data } = await api.post(`/uxui/${component._id}/comment`, { text: e });
          setComponent({ ...component, comments: data.comments });
          toast.success("Comment added!");
        }}
        onDelete={async (id) => {
          const { data } = await api.put(`/uxui/${component._id}/delete-comment/${id}`);
          setComponent({ ...component, comments: data.comments });
          toast.success("Comment deleted!");
        }}
      />
    </div>
  );
};
