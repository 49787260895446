import React, { useEffect, useState } from "react";

import Modal from "./modal";

export default ({ value = [], onChange, tags }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    let arr = [...value];
    const tag = e.target.name;
    if (arr.includes(tag)) {
      arr = arr.filter((t) => t !== tag);
    } else {
      arr = [...arr, tag];
    }
    onChange(arr);
  };

  console.log("valuevalue", value);

  return (
    <div>
      <div className="flex items-center" onClick={() => setOpen(true)}>
        {(value || []).map((e) => (
          <span className="text-xs bg-gray-200 rounded-md cursor-pointer mr-2 p-2">{e}</span>
        ))}
        {value.length === 0 && <span className="text-xs bg-gray-200 rounded-md cursor-pointer mr-2 p-2">Add tags</span>}
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className="space-y-2 w-full col-span-4 m-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="tags">
            Tags
          </label>
          {tags.map((tag) => (
            <div className="flex items-center mb-4" key={tag}>
              <input type="checkbox" id={tag} name={tag} checked={value.includes(tag)} onChange={handleChange} className="rounded" />
              <label className="ml-2 text-sm font-medium text-gray-700" htmlFor={tag}>
                {tag}
              </label>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
