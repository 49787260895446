import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import SearchSelect from "../../../components/SearchSelect";

export default ({ value = {}, onChange }) => {
  const [workshops, setWorkshops] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/workshop");
      setWorkshops(
        data
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((j) => ({ value: j._id, label: j.name })),
      );
    })();
  }, []);

  console.log(value);

  return <SearchSelect id="select-workshop" options={workshops || []} value={workshops.find((w) => w.value === value)} onSelect={onChange} placeholder="Select a Workshop" />;
};
