import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FaSync } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import DebounceInput from "../../../../components/DebounceInput";

import api from "../../../../services/api";
import NotionTable from "./components/notionTable";

import Kanban from "./components/kanban";
import Budget from "./components/Budget";

import ModalCreation from "./components/ModaleCreation";
import BulkActionModal from "./components/BulkActionsModal";
import MultiSelect from "../../../../components/MultiSelect";
import SelectUser from "../../../../components/selectUser";
import NotionStats from "./components/Stats";

// TODO: rename all the 'notion' to 'task'

export default ({ project, setProject }) => {
  const [notions, setNotions] = useState([]);
  const [filters, setFilters] = useState({ page: 1, sort: { created_at: -1 } });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [view, setView] = useState();
  const [budgets, setBudgets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalMultiSelect, setOpenModalMultiSelect] = useState(false);
  const [showMyTasks, setShowMyTasks] = useState(false);
  const [selectedNotions, setSelectedNotions] = useState({});

  const navigate = useNavigate();

  const { id, tab } = useParams();

  useEffect(() => {
    if (tab === "kanban") return setView("KANBAN");
    if (tab === "table") return setView("TABLE");
    if (tab === "stats") return setView("STATS");
    setView("KANBAN");
  }, [tab]);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetch();
  }, [filters]);

  useEffect(() => {
    getBudgets();
  }, []);

  const getBudgets = async () => {
    try {
      const { data } = await api.post("/budget/search", { projectId: project._id, status: "active" });
      setBudgets(data);
    } catch (e) {
      console.log("e", e);
    }
  };

  async function fetch() {
    setLoading(true);
    const newFilters = { ...filters, project_id: project._id };
    const { data, total, ok } = await api.post("/notion/search", newFilters);
    if (!ok) return toast.error("Error fetching users");

    setNotions(data);
    setTotal(total);
    setLoading(false);
    setSelectedNotions(data.reduce((acc, notion) => ({ ...acc, [notion._id]: false }), {}));
  }

  const handleMyTasks = () => {
    setShowMyTasks(true);
    setFilters({ ...filters, user_id: user._id });
  };

  async function onExport() {
    const newFilters = { ...filters, project_id: project._id };
    const { data, ok } = await api.post("/notion/search", newFilters);
    if (!ok) return toast.error("Error fetching users");

    if (!data.length) return;

    let arr = data.map((notion) => {
      return {
        name: notion.name,
        priority: (notion.priority || "").toUpperCase(),
        category: notion.category,
        status: notion.status,
        budget: budgets.find((b) => b._id === notion.budget_id)?.name,
        time: notion.estimated_hours,
        price: notion.estimated_price,
        link: `https://accounting.selego.co/project/662e7b0607f5bf070d91916e/backlog?task_modal_id=${notion._id}`,
        created_at: notion.created_at,
      };
    });

    const date = new Date();
    const filename = `${project.name} - ${date.toLocaleDateString("fr")}.csv`;
    const header = Object.keys(arr[0]);
    const csv = [header.join(";"), ...arr.map((row) => header.map((field) => `"${row[field]}"`).join(";"))].join("\n");

    const blob = new Blob([csv]);
    if (window.navigator.msSaveOrOpenBlob)
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, filename);
    else {
      const encodedUrl = window.URL.createObjectURL(blob, { type: "data:text/csv;charset=utf-8" });
      const link = document.createElement("a");
      link.setAttribute("href", encodedUrl);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <div className="bg-white border border-gray-300 rounded-md overflow-hidden px-4 py-2">
      <div className="bg-white h-full">
        <Budget project={project} />
        <div className="flex-1 flex justify-between items-center gap-2 mb-2">
          <DebounceInput
            debounce={300}
            className="input w-1/3"
            placeholder="Search..."
            value={filters.search}
            onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
          />
          <MultiSelect
            id="select-budget"
            options={budgets.map((budget) => ({ value: budget._id, label: budget.name }))}
            onSelectedChange={(e) => setFilters((f) => ({ ...f, budget_ids: e.map((e) => e.value) }))}
            placeholder="Budget"
          />
          <MultiSelect
            id="select-status"
            options={["TODO", "READY_TO_DEV", "IN_PROGRESS", "TESTING", "DONE", "ARCHIVED"].map((status) => ({ value: status, label: status }))}
            onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
            placeholder="Status"
          />
          <MultiSelect
            id="select-category"
            options={["BUG", "FEATURE"].map((category) => ({ value: category, label: category }))}
            onSelectedChange={(e) => setFilters((f) => ({ ...f, categories: e.map((e) => e.value) }))}
            placeholder="Category"
          />
          <MultiSelect
            id="select-priority"
            options={["P0", "P1", "P2", "P3"].map((priority) => ({ value: priority, label: priority }))}
            onSelectedChange={(e) => setFilters((f) => ({ ...f, priorities: e.map((e) => e.value) }))}
            placeholder="Priority"
          />
          <div className="flex flex-row items-center gap-4">
            <button onClick={handleMyTasks} className="transparent-btn">
              My tasks
            </button>
            <SelectUser
              name="people"
              className="projectsInput"
              placeholder="User"
              onChange={(e) => setFilters({ ...filters, user_id: e._id })}
              userName={showMyTasks ? user.name : ""}
            />
          </div>
        </div>

        <div className="flex justify-between">
          <div className="flex items-end ml-2 ">
            <div onClick={() => navigate(`/project/${id}/backlog/kanban`)} className="flex items-center cursor-pointer">
              <div className={`${view === "KANBAN" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Kanban</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/backlog/table`)} className="flex items-center cursor-pointer">
              <div className={`${view === "TABLE" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Table</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/backlog/stats`)} className="flex items-center cursor-pointer">
              <div className={`${view === "STATS" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Stats</div>
            </div>
            <div className="mx-5 mb-1 text-gray-600 text-xs py-1">{total} items</div>
            <button onClick={fetch} className="transparent-btn">
              <FaSync className={`${loading && "animate-spin"}`} />
            </button>
          </div>
          <div className="flex items-end">
            {selectedNotions && Object.values(selectedNotions).some((item) => item) && (
              <button className="blue-btn mr-2" onClick={() => setOpenModalMultiSelect(true)}>
                Edit
              </button>
            )}
            <button className="blue-btn mr-2" onClick={() => setOpenModal(true)}>
              New ticket
            </button>
            <button className="blue-btn" onClick={onExport}>
              Export
            </button>
          </div>
        </div>
        {view === "TABLE" && (
          <NotionTable
            total={total}
            loading={loading}
            notions={notions}
            filters={filters}
            setFilters={setFilters}
            selectedNotions={selectedNotions}
            setSelectedNotions={setSelectedNotions}
          />
        )}
        {view === "KANBAN" && <Kanban loading={loading} notions={notions} setNotions={setNotions} filters={filters} />}
        {view === "STATS" && <NotionStats data={notions} />}
      </div>
      <ModalCreation setNotions={setNotions} isOpen={openModal} onClose={() => setOpenModal(false)} projectId={project._id} />
      <BulkActionModal selectedNotions={selectedNotions} isOpen={openModalMultiSelect} onClose={() => setOpenModalMultiSelect(false)} projectId={project._id} />
    </div>
  );
};
