import React, { useEffect, useState } from "react";

import api from "../services/api";

export default ({ value, onChange }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const res = await api.post("/project/search", { per_page: 1000 });
    setProjects(res.data.projects);
  }

  return (
    <div>
      <select
        className="w-[180px] border projectsInput bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] rounded-[5px] border-r-[16px] cursor-pointer px-3"
        name="project"
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          const f = projects.find((f) => e.target.value === f._id);
          onChange(f);
        }}>
        <option disabled>Project</option>
        <option value={""}>All Project</option>
        {projects
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((e) => {
            return (
              <option key={e._id} value={e._id}>
                {e.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};
