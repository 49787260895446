import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";
import ActionModal from "../../../../components/ActionModal";

export default ({ contact, setContact }) => {
  const user = useSelector((state) => state.Auth.user);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="">
        <p>{contact.linkedin_raw?.description}</p>
        <Experiences contact={contact} />
      </div>
      <div className="">
        <Comments contact={contact} setContact={setContact} />
        <Activities contact={contact} />
        <Actions contact={contact} />
      </div>
    </div>
  );
};

const Actions = ({ contact }) => {
  const [loading, setLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    getActions();
  }, [contact]);

  async function getActions() {
    try {
      setLoading(true);
      const { ok, data, total } = await api.post(`/s_action/search`, { contact_id: contact?._id });
      if (!ok) return toast.error("Erreur lors de la récupération des actions");
      setActions(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function createAction() {
    const { data } = await api.post("/s_action", { name: "Mon action22", contact_id: contact._id, contact_name: contact.first_name + " " + contact.last_name });
    toast.success("Action created!");
    await getActions();
    setSelectedAction(data);
  }

  return (
    <div>
      <button className="cursor-pointer bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={createAction}>
        Add action
      </button>
      <div>
        {(actions || []).map((action) => {
          return (
            <div key={action._id} className="border p-4 rounded ">
              <div className="font-semibold">{action.name}</div>
              <div className="text-sm text-gray-500">{new Date(action.createdAt).toLocaleString()}</div>
              <div className="text-sm text-gray-500">{action.description}</div>
            </div>
          );
        })}
      </div>
      <ActionModal action={selectedAction} setAction={setSelectedAction} refetch={getActions} />
    </div>
  );
};

const Experiences = ({ contact }) => {
  const [showAll, setShowAll] = useState(false);
  if (!contact?.linkedin_raw?.experiences?.length) return <div>No experiences</div>;

  //isActive

  const actives = contact.linkedin_raw.experiences.filter((exp) => exp.isActive);
  const inactives = contact.linkedin_raw.experiences.filter((exp) => !exp.isActive);

  return (
    <div className="flex flex-col space-y-1">
      {actives.map((exp, index) => (
        <CompanyItem key={index} exp={exp} />
      ))}
      <div>
        <h2 className="text-lg font-bold border border-gray-400 rounded-md cursor-pointer" onClick={() => setShowAll(!showAll)}>
          {inactives.length} others
        </h2>
        {showAll && (
          <div>
            {inactives.map((exp, index) => (
              <CompanyItem key={index} exp={exp} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CompanyItem = ({ exp }) => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompany();
  }, [exp]);

  async function getCompany() {
    setLoading(true);
    const name = exp.company;
    if (!name) return;
    const { data } = await api.post(`/s_company/search`, { linkedin_id: exp.linkedin_id });
    if (data.length === 0) return setLoading(false);
    setCompany(data[0]);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-2 border border-gray-400 rounded-md">
      <a href={exp.link} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-3">
        {exp.logo && <img src={exp.logo} alt={`${exp.company} logo`} className="w-10 h-10 rounded-full" />}
        <div>
          <h2 className="text-sm m-0 p-0">{exp.title}</h2>
          <p className="text-sm">
            {exp.company} {exp.company_contract ? `(${exp.company_contract})` : ""}
          </p>
          <p className="text-xs">{exp.isActive ? `Since ${exp.duration_startDate}` : `${exp.duration_startDate} - ${exp.duration_endDate}`}</p>
        </div>
        <div>
          {company?.linkedin_raw?.about && <div className="text-xs text-gray-500">{company.linkedin_raw.about}</div>}
          {company?.linkedin_raw?.headcount && <div className="text-xs text-gray-500">{company.linkedin_raw.headcount}</div>}
          {company?.linkedin_raw?.mediumTenure && <div className="text-xs text-gray-500">{company.linkedin_raw.mediumTenure}</div>}
          {company?.linkedin_raw?.followers && <div className="text-xs text-gray-500">{company.linkedin_raw.followers}</div>}
          {company?.linkedin_raw?.employees && <div className="text-xs text-gray-500">{company.linkedin_raw.employees}</div>}
          {company?.linkedin_raw?.industry && <div className="text-xs text-gray-500">{company.linkedin_raw.industry}</div>}
        </div>
      </a>
      {exp.description && <p className="mt-2 text-gray-600 text-sm">{exp.description}</p>}
      {!company && (
        <div>
          <a href={exp.link} className="text-xs text-blue-500 bg-blue-100 p-1 rounded-md " target="_blank" rel="noopener noreferrer">
            {`Click to fill the company ${exp.linkedin_id}`}
          </a>
          <button onClick={getCompany}>Refresh</button>
        </div>
      )}
    </div>
  );
};

const Activities = ({ contact }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    get();
  }, [contact]);

  async function get() {
    const { data } = await api.post(`/s_activity/search`, { contact_id: contact._id });
    setActivities(data);
  }

  return (
    <div className="p-6">
      <div className="space-y-4">
        {activities.map((activity) => (
          <div key={activity._id} className="border p-4 rounded flex justify-between items-center">
            <div className="font-semibold">{activity.type}</div>
            <div className="text-sm text-gray-500">{new Date(activity.created_at).toLocaleString()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Comments = ({ contact, setContact }) => {
  const [comment, setComment] = useState("");

  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;
    setComment("");
    const comments = contact.comments || [];
    comments.push({ text: comment, user_name: user.name, user_id: user._id, user_avatar: user.avatar });
    const { data } = await api.put(`/s_contact/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment added!");
  };

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this comment?") === false) return;
    const comments = contact.comments.filter((c) => c._id !== id);
    const { data } = await api.put(`/s_contact/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment deleted!");
  }

  return (
    <div className=" w-full p-4">
      <div className="w-full flex flex-col ">
        <span className="mb-2 font-bold">Comments</span>
        <div className="flex flex-col justify-end">
          {(contact.comments || []).map((comment, index) => (
            <div className="border-b-2 pb-2 mb-2">
              <div className="flex flex-row">
                <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <div className="bg-white  p-2 break-words">{comment.text}</div>
                    <div className="text-xs text-gray-500">
                      {comment.user_name} - {!!comment?.created_at && new Date(comment.created_at).toLocaleDateString()}
                    </div>
                    {onDelete && (
                      <div className="text-xs text-gray-500 text-right w-full cursor-pointer hover:text-red-500" onClick={() => onDelete(comment._id)}>
                        DELETE
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="flex items-center">
            <img src={user.avatar} alt="userlogo" className="rounded-full w-9 h-9 mr-2" />
            <textarea
              className="rounded-md border border-gray-100 shadow-sm block w-full"
              placeholder="Add a comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-2">
            <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
