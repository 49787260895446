import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import People from "./people";
import Tutorials from "./tutorials";
import Activities from "./Activities";
import Availability from "./Availability";
import MyProfile from "./MyProfile";
import MySkills from "./MySkills";

const Home = () => {
  const user = useSelector((state) => state.Auth.user);

  if (user.role === "applicant") return <Navigate to="/applicant" />;
  if (user.role === "client") return <Navigate to="/project" />;

  return (
    <div className="p-3 flex flex-col w-full h-full">
      <div className="flex gap-4 mb-3">
        <MyProfile />
        <div className="flex-1">
          <Activities />
        </div>
        <div className="flex-1">
          {/* <Tutorials /> */}
          <MySkills />
        </div>
        <div className="flex-1">
          <Availability />
        </div>
      </div>
      <div>
        <Tutorials />
      </div>
      <div className="my-2" />
      <div className="w-full">
        <People />
      </div>
    </div>
  );
};

export default Home;
