import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import Invite from "./invite";
import Editor from "./editor";
import Applicants from "./applicants";
import Tracking from "./tracking";
import Note from "./notes";
import toast from "react-hot-toast";
import Raw from "../../../components/Raw";

const TABS = [
  { title: "Info", href: "info" },
  { title: "Invite", href: "invite" },
  { title: "Editor", href: "editor" },
  { title: "Applicants", href: "" },
  { title: "Tracking", href: "tracking" },
  { title: "Raw", href: "raw" },
  { title: "Note", href: "note" },
];

const JobView = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok, data } = await api.get(`/job/${id}`);
        if (!ok) throw new Error("Error fetching job");
        setData(data);
      } catch (error) {
        console.error(error);
        toast.error("Error fetching job");
      }
    };
    fetchData();
  }, [id]);

  if (!data) return <Loader />;

  return (
    <div className="p-2">
      <div className="flex justify-between">
        <div>{`${data.title} (#${data.index})`}</div>
      </div>
      <TabBar tabs={TABS} />
      <Routes>
        <Route path="/info" element={<Info job={data} setJob={setData} />} />
        <Route path="/invite" element={<Invite job={data} setJob={setData} />} />
        <Route path="/editor" element={<Editor job={data} setJob={setData} />} />
        <Route path="/" element={<Applicants job={data} setJob={setData} />} />
        <Route path="/tracking" element={<Tracking job={data} setJob={setData} />} />
        <Route path="/raw" element={<Raw data={data} />} />
        <Route path="/note" element={<Note job={data} setJob={setData} />} />
      </Routes>
    </div>
  );
};

const TabBar = ({ tabs }) => (
  <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
    {tabs.map((tab, i) => (
      <TabItem key={i} href={tab.href} title={tab.title} />
    ))}
  </nav>
);

const TabItem = ({ href, title }) => {
  const { id } = useParams();
  const active = href === "" ? window.location.pathname === `/job/${id}` || window.location.pathname === `/job/${id}/` : window.location.pathname === `/job/${id}/${href}`;

  return (
    <Link to={`/job/${id}/${href}`} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </Link>
  );
};

export default JobView;
