import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoInformationCircleOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import Tags from "../../../../components/Tags";
import Modal from "../../../../components/modal";
import api from "../../../../services/api";

import About from "./about";
import Edit from "./edit";
import Raw from "./raw";
import Send from "./send";
import Activities from "./activities";

import { classNames } from "../../../../utils";
export default ({ contact, setContact }) => {
  const [tab, setTab] = useState("about");

  // console.log("contact", contact);

  if (!contact) return <></>;

  return (
    <Modal isOpen={true} onClose={() => setContact(null)}>
      <div className="w-full h-[90vh] bg-[#FBFBF9] p-2 overflow-y-auto">
        <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
          <div className="flex items-center gap-2 p-2">
            <div className="flex justify-between flex-1">
              <div className="flex items-center ">
                <img src={contact.picture} alt="Profile" className="w-12 h-12 rounded-full mr-4" />
                <div>
                  <div className="text-lg font-bold">{`Contact : ${contact.first_name} ${contact.last_name}`}</div>
                  <div className="text-sm text-gray-500">
                    {contact.job_title} at {contact.company_name}
                  </div>
                  <div className="text-sm text-gray-500">{contact.location}</div>
                </div>
                <div className="flex space-x-2 ml-8">
                  {contact.linkedin && (
                    <a href={contact.linkedin} target="_blank" rel="noopener noreferrer">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-5" />
                    </a>
                  )}
                  {contact.email && (
                    <a href={`mailto:${contact.email}`}>
                      <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-5" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="grid grid-cols-2 gap-x-4">
                <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
                <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(contact.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
                <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
                <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(contact.created_at).format("DD/MM/YYYY, HH:mm")}</span>
                <Tags
                  tags={[
                    "domain-fintech",
                    "domain-govtech",
                    "domain-healthtech",
                    "domain-edtech",
                    "domain-agritech",
                    "domain-sportech",
                    "domain-proptech",
                    "domain-heritagetech",
                    "domain-legaltech",
                    "domain-martech",
                    "domain-hrtech",
                    "personae-corpo",
                    "personae-gouv",
                    "personae-startup-early-stage",
                    "personae-scale-up",
                    "personae-incubateurs",
                    "personae-broker",
                    "newsletter",
                    "later",
                  ]}
                  value={contact.tags}
                  onChange={async (tags) => {
                    const { data } = await api.put(`/s_contact/${contact._id}`, { tags });
                    console.log(`data`, data);
                    setContact(data);
                    toast.success("Tags updated!");
                  }}
                />
              </div>
            </div>
          </div>
          <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
            <TabItem title="Contact" tab="send" setTab={setTab} active={tab === "send"} />
            <TabItem title="Activities" tab="activities" setTab={setTab} active={tab === "activities"} />
            <TabItem title="Edit" tab="edit" setTab={setTab} active={tab === "edit"} />
            <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
          </nav>
        </div>
        <div className="my-2" />
        {tab === "about" ? <About contact={contact} setContact={setContact} /> : null}
        {tab === "raw" ? <Raw contact={contact} /> : null}
        {tab === "send" ? <Send contact={contact} /> : null}
        {tab === "activities" ? <Activities contact={contact} /> : null}
        {tab === "edit" ? <Edit contact={contact} setContact={setContact} /> : null}
      </div>
    </Modal>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
