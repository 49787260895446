import RichTextEditorSun from "../../../../components/RichTextEditor";
import { applyFontClassToHtml } from "../utils";

const Text = ({ values, editing, onChange, lang }) => {
  if (editing) return <TextForm values={values} onChange={onChange} lang={lang} />;
  return <div className="text-sm font-poppins rich-text" dangerouslySetInnerHTML={{ __html: applyFontClassToHtml(values.description[lang]) }}></div>;
};

const TextForm = ({ values, onChange, lang }) => {
  return (
    <div className="mt-6">
      <RichTextEditorSun
        buttonList={[
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor"],
          ["align", "list", "lineHeight"],
          ["outdent", "indent"],
          ["table", "horizontalRule", "link", "image", "video"],
          ["removeFormat"],
        ]}
        values={values.description[lang]}
        onChange={(e) => onChange({ ...values, description: { ...values.description, [lang]: e } })}
      />
    </div>
  );
};

export default Text;
