import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ source, setSource }) => {
  const [values, setValues] = useState(source);

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    try {
      await api.remove(`/s_queue/${source._id}`);
      toast.success("successfully removed!");
      setSource(null);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log("values,", values);
      const { data } = await api.put(`/s_queue/${source._id}`, values);
      setSource(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <input className="input" type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="space-y-2 w-full col-span-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="url">
            URL
          </label>
          <input className="input" type="text" name="url" value={values.url} onChange={(e) => setValues({ ...values, url: e.target.value })} />
        </div>
        <div className="space-y-2 w-full col-span-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="status">
            Status
          </label>
          <select className="input" name="status" value={values.status} onChange={(e) => setValues({ ...values, status: e.target.value })}>
            <option value="TODO">Todo</option>
            <option value="DONE">Done</option>
          </select>
        </div>
        <div>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
          <button type="button" onClick={handleDelete} className="btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </form>
  );
};
