import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";
import ActionModal from "../../../components/ActionModal";
import dayjs from "dayjs";

export default ({ contact }) => {
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState();

  useEffect(() => {
    loadActions();
  }, []);

  async function loadActions() {
    const { data } = await api.post(`/action/search`, { folkId: contact._id });
    setActions(data);
  }

  return (
    <div className="w-full p-4">
      <div className="w-full h-full flex flex-col">
        <div>
          <span className=" font-bold">Actions</span>
          <button
            className="bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px] mt-5 ml-3"
            onClick={async () => {
              const { data } = await api.post("/s_action", {
                name: "Mon action",
                folkId: contact._id,
                folkName: `${contact.first_name} ${contact.last_name}`,
              });
              toast.success("Action created!");
              loadActions();
              setSelectedAction(data);
            }}>
            Add
          </button>
        </div>

        <table className="w-full mb-1">
          <thead>
            <tr className="border-t border-t-slate-100 uppercase">
              <th className="px-4 py-3 text-xs font-normal text-gray-500"></th>
              <th className="px-4 py-3 text-xs font-normal text-gray-500">Name</th>
              <th className="px-4 py-3 text-xs font-normal text-gray-500">Contact</th>
              <th className="px-4 py-3 text-xs font-normal text-gray-500">Category</th>
              <th className="px-4 py-3 text-xs font-normal text-gray-500">Status</th>
              <th className="px-4 py-3 text-xs font-normal text-gray-500">Deadline</th>
            </tr>
          </thead>
          <tbody>
            {actions.map((action) => (
              <tr
                key={action._id}
                className="border-t hover:cursor-pointer hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedAction(action);
                }}>
                <td className=" px-4 py-3 text-xs">
                  <img src={action.userAvatar} alt="avatar" className="w-8 h-8 rounded-full mr-2" />
                </td>
                <td className="truncate px-4 py-3 text-xs">{action.name}</td>
                <td className="truncate px-4 py-3 text-xs">{action.folkName}</td>
                <td className="truncate px-4 py-3 text-xs">{action.category}</td>
                <td className="truncate px-4 py-3 text-xs">{action.status}</td>
                <td className="truncate px-4 py-3 text-xs">{dayjs(action.deadline).format("YYYY-MM-DD")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedAction && <ActionModal action={selectedAction} setAction={setSelectedAction} refetch={() => loadActions()} />}
    </div>
  );
};
