import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ company, setCompany }) => {
  const user = useSelector((state) => state.Auth.user);

  const [values, setValues] = useState(company);

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    try {
      await api.remove(`/s_company/${company._id}`);
      toast.success("successfully removed!");
      setCompany(null);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { data } = await api.put(`/s_company/${user._id}`, values);
      setCompany(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <input className="input" type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="space-y-2 w-full col-span-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="description">
            Description
          </label>
          <textarea row={4} className="input" type="text" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
        </div>
      </div>
      <div className=" w-full">
        <Comments contact={company} setContact={setCompany} />
      </div>
      <div>
        <button className="btn btn-danger" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </form>
  );
};
const Comments = ({ contact, setContact }) => {
  const [comment, setComment] = useState("");

  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;
    setComment("");
    const comments = contact.comments || [];
    comments.push({ text: comment, user_name: user.name, user_id: user._id, user_avatar: user.avatar });
    const { data } = await api.put(`/s_company/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment added!");
  };

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this comment?") === false) return;
    const comments = contact.comments.filter((c) => c._id !== id);
    const { data } = await api.put(`/s_company/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment deleted!");
  }

  return (
    <div className=" w-full h-full p-4">
      <div className="w-full h-full flex flex-col ">
        <span className="mb-10 font-bold">Comments</span>
        <div className="flex flex-col justify-end">
          {(contact.comments || []).map((comment, index) => (
            <div className="border-b-2 pb-2 mb-2">
              <div className="flex flex-row">
                <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <div className="bg-white  p-2 break-words">{comment.text}</div>
                    <div className="text-xs text-gray-500">
                      {comment.user_name} - {new Date(comment.created_at).toLocaleDateString()}
                    </div>
                    {onDelete && (
                      <div className="text-xs text-gray-500 text-right w-full cursor-pointer hover:text-red-500" onClick={() => onDelete(comment._id)}>
                        DELETE
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="flex items-center">
            <img src={user.avatar} alt="userlogo" className="rounded-full w-9 h-9 mr-2" />
            <textarea
              className="rounded-md border border-gray-100 shadow-sm block w-full"
              placeholder="Add a comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-2">
            <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
